import { toastr } from 'react-redux-toastr'

import { errorsAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'

export const recievedRelatimeNotification = (payload) => (dispatch) => {
  dispatch({
    type: types.RECIEVED_REALTIME_NOTIFICATION,
    payload
  })
}

export const fetchNotificationsSuccess = (payload) => ({
  type: types.FETCH_NOTIFICATIONS_SUCCESS,
  payload
})

export const fetchNotificationswithFilesSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_NOTIFICATIONS_WITH_FILES_SUCCESS,
    payload
  })
}

export const fetchNotificationsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_NOTIFICATIONS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Fetch Notifications Failed!')
}

export const readNotificationSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.READ_A_NOTIFICATION_SUCCESS,
    payload
  })
}

export const readNotificationFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.READ_A_NOTIFICATION_FAILURE,
    payload
  })

  errorsAlert(payload, 'Read A Notification Failed!')
}

export const markAllAsReadSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.MARK_ALL_AS_READ_SUCCESS,
    payload
  })

  toastr.success('Successfully Read All Notifications!')
}

export const markAllAsReadFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.MARK_ALL_AS_READ_FAILURE,
    payload
  })
  errorsAlert(payload, 'Read All Notifications Failed!')
}

export const loadMoreNotificationsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.LOAD_MORE_NOTIFICATIONS_SUCCESS,
    payload
  })
}

export const loadMoreNotificationsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.LOAD_MORE_NOTIFICATIONS_FAILURE,
    payload
  })

  errorsAlert(payload, 'Load More Notifications Failed!')
}

export const setNotificationPageNumber = (payload) => ({
  type: types.SET_NOTIFICATIONS_PAGE_NUMBER,
  payload
})

export const setCallInProgress = (payload) => ({
  type: types.SET_CALL_IN_PROGRESS,
  payload
})

export const fetchUnreadOnlySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_UNREAD_NOTIFICATIONS_SUCCESS,
    payload
  })
}

export const fetchUnreadOnlyFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_UNREAD_NOTIFICATIONS_FAILURE,
    payload
  })

  errorsAlert(payload, 'Fetch Unread Notifications Failed!')
}

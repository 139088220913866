import { EMAIL_FORMAT, MAX_DOCUMENT_SIZE_LIMIT, MAX_IMAGE_SIZE_LIMIT } from 'helpers/constants'

export const isValidCellPhoneNumber = (cellPhoneNumber) => /^\+[1-9](?:[0-9]{9,14})$/.test(cellPhoneNumber)

export const isValidPhoneNumber = (phoneNumber) => /^\d+[-]?\d+$/.test(phoneNumber)

export const isAlphabetical = (string) => /^[a-zA-Z\s]+$/.test(string)

export const isImage = (image) => {
  return image && image.type != null && /(image)([/])(png|jpg|jpeg|gif)/.test(image.type)
}

export const imageSizeIsLessThan = (image, size) => {
  return image && image.size && image.size < size
}
export const docSizeValidator = (file) => !file.type?.includes('image') && file.size > MAX_DOCUMENT_SIZE_LIMIT

export const imageSizeValidator = (file) => file.type.includes('image') && file.size > MAX_IMAGE_SIZE_LIMIT

export const hasCharacters = (value) => value.trim().length > 0

export const isValidCNIC = (cnic) => /^\d{5}[-]?\d{7}[-]?\d$/.test(cnic)

export const isValidEmailDomain = (value, email_domain) => {
  let regex = null
  if (email_domain) {
    regex = `^[a-zA-Z0-9._+]+@${email_domain}$`
  } else {
    regex = EMAIL_FORMAT
  }

  let validEmail = new RegExp(regex)
  return validEmail.test(value)
}

export const populateUserFormFieldErrors = (values, field, errors) => {
  if (values[field]) {
    switch (field) {
      case 'current_address':
      case 'about_me':
      case 'hobbies':
      case 'father_name':
      case 'fav_quote':
        if (values[field].trim().length === 0) {
          errors[field] = `${
            field === 'current_address'
              ? 'Current address is'
              : field === 'about_me'
                ? 'About info is'
                : field === 'hobbies'
                  ? 'Hobbies are'
                  : field === 'father_name'
                    ? 'Father name is'
                    : 'Favourite quotation is'
          } invalid`
        } else if (field === 'about_me' && values[field].trim().length < 15) {
          errors.about_me = 'Must be more than 15 characters'
        } else if (
          (field === 'hobbies' || field === 'fav_quote') &&
          (values[field].trim().length < 3 || values[field].trim().length > 300)
        ) {
          errors[field] = 'Must be between 3 and 300 characters'
        }
        break
      default:
        errors[field] = `${field.humanize().titleize()} has unhandled validation`
    }
  }
  return errors
}

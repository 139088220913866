import reduxFormTouchOnsubmit from 'hocs/reduxFormTouchOnsubmit'
import { Box, styled, Typography } from 'maple-storybook'
import { Field, reduxForm, submit } from 'redux-form'

import { disableFormSubmission } from 'actions/formSubmission'
import CustomRadioGroup from 'components/CustomRadioGroup/CustomRadioGroup'

import { validateDayFeedbackForm as validate } from './validateDayFeedbackForm'

const OPTIONS = [
  { label: 'Awesome', value: 'awesome' },
  { label: 'Good', value: 'good' },
  { label: 'Fine', value: 'fine' },
  { label: 'Bad', value: 'bad' },
  { label: 'Awful', value: 'awful' }
]

const StyledBox = styled(Box)(() => ({
  '&.day-feedback-box': {
    color: 'white',
    display: 'flex',
    flexDirection: 'column'
  },
  '&. heading': {
    color: 'white !important'
  },
  '&. radio-btn': {
    '&.Mui-checked': {
      color: '#1eae63'
    }
  }
}))

let DayFeedbackForm = ({ submitFormAndTouchAll, submission }) => {
  return (
    <form onSubmit={submitFormAndTouchAll}>
      <StyledBox className="day-feedback-box flex-column">
        <Typography variant="h5" className="heading" color="white">
          How are you feeling today?
        </Typography>
        <Field
          name="rating"
          component={CustomRadioGroup}
          options={OPTIONS}
          radioClass="radio-btn"
          labelClass="label-text"
          disabled={submission}
          labelColor="#fff"
        />
      </StyledBox>
    </form>
  )
}

DayFeedbackForm = reduxForm({
  form: 'day_feedback_form',
  onChange: (values, dispatch) => {
    setTimeout(() => {
      dispatch(disableFormSubmission)
      dispatch(submit('day_feedback_form'))
    }, 100)
  },
  validate
})(reduxFormTouchOnsubmit(DayFeedbackForm))

export default DayFeedbackForm

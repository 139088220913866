import * as qs from 'qs'

import request from './request'

// Attendance

export const fetchAttendance = (params, successAction, failureAction) => {
  return request(
    `attendances/1?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true,
    null,
    null,
    false,
    true
  )
}

export const createAttendance = (successAction, failureAction) => {
  return request(
    'attendances',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: null,
      submission: true
    },
    successAction,
    failureAction,
    true,
    null,
    null,
    false,
    true
  )
}

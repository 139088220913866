import * as types from 'actions/actionTypes'
import { totalItems } from 'helpers/applicationHelper'

import initialState from './initialState'

export const leavePolicy = (state = initialState.leavePolicies, action) => {
  let policyIndex,
    policies,
    rules,
    ruleIndex = null

  switch (action.type) {
    case types.CREATE_LEAVE_POLICY_SUCCESS:
      return { ...state, leavePolicyByYear: action.payload }

    case types.CREATE_LEAVE_POLICY_FAILURE:
      return state

    case types.FETCH_LEAVE_POLICY_BY_YEAR_SUCCESS:
      return { ...state, leavePolicyByYear: action.payload, autoLeaveLoader: false }

    case types.FETCH_LEAVE_POLICY_BY_YEAR_FAILURE:
      return state

    case types.FETCH_AUTO_LEAVE_APPLY_RULE_SUCCESS:
      return { ...state, autoLeaveApplyRules: action.payload }

    case types.FETCH_AUTO_LEAVE_APPLY_RULE_FAILURE:
      return state

    case types.FETCH_AUTO_LEAVE_APPLY_SUCCESS:
      return { ...state, autoLeaveApplyRule: action.payload }

    case types.CREATE_AUTO_LEAVE_APPLY_RULE_FAILURE:
      return state

    case types.CREATE_AUTO_LEAVE_APPLY_RULE_SUCCESS:
      return { ...state, autoLeaveApplyRules: [...state.autoLeaveApplyRules, action.payload] }

    case types.UPDATE_AUTO_LEAVE_APPLY_RULE_SUCCESS:
      ruleIndex = state.autoLeaveApplyRules.findIndex((rule) => rule.id === action.payload.id)

      rules = [...state.autoLeaveApplyRules]
      rules[ruleIndex] = action.payload

      return { ...state, autoLeaveApplyRules: rules }

    case types.UPDATE_AUTO_LEAVE_APPLY_RULE_FAILURE:
      return state

    case types.DELETE_AUTO_LEAVE_APPLY_RULE_SUCCESS: {
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
      return {
        ...state,
        autoLeaveApplyRules: state.autoLeaveApplyRules.filter((rule) => rule.id !== action.payload.id)
      }
    }
    case types.DELETE_AUTO_LEAVE_APPLY_RULE_FAILURE:
      return state

    case types.FETCH_LEAVE_POLICIES_SUCCESS:
      return { ...state, leavePolicies: action.payload, autoLeaveLoader: false }

    case types.FETCH_LEAVE_POLICIES_FAILURE:
      return state

    case types.UPDATE_LEAVE_POLICY_SUCCESS:
      return { ...state, leavePolicyByYear: action.payload }

    case types.UPDATE_LEAVE_POLICY_FAILURE:
      return state

    case types.FETCH_LEAVE_POLICIES_QUOTA_SUCCESS:
      return {
        ...state,
        currentUserLeavePoliciesQuota: action.payload
      }

    case types.FETCH_LEAVE_POLICIES_QUOTA_FAILURE:
      return state

    case types.CLEAR_LEAVE_POLICIES_QUOTA:
      return {
        ...state,
        currentUserLeavePoliciesQuota: null
      }

    case types.FETCH_SANDWICH_POLICY_SUCCESS:
      return { ...state, sandwichPolicies: action.payload }

    case types.FETCH_SANDWICH_POLICY_FAILURE:
      return state

    case types.CREATE_SANDWICH_POLICY_SUCCESS:
      return { ...state, sandwichPolicies: [...state.sandwichPolicies, action.payload] }

    case types.CREATE_SANDWICH_POLICY_FAILURE:
      return state

    case types.DELETE_SANDWICH_POLICY_SUCCESS:
      return {
        ...state,
        sandwichPolicies: state.sandwichPolicies.filter((sp) => sp.id !== action.payload.id)
      }

    case types.DELETE_SANDWICH_POLICY_FAILURE:
      return state

    case types.UPDATE_SANDWICH_POLICY_SUCCESS:
      policyIndex = state.sandwichPolicies.findIndex((policy) => policy.id === action.payload.id)

      policies = [...state.sandwichPolicies]
      policies[policyIndex] = action.payload

      return { ...state, sandwichPolicies: policies }

    case types.UPDATE_SANDWICH_POLICY_FAILURE:
      return state

    default:
      return state
  }
}

import { toastr } from 'react-redux-toastr'

import { errorsAlert, warningAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'
import { HIDE_MODAL } from './modal'

export const setLeavesApprovalPageSize = (payload) => ({
  type: types.SET_LEAVE_APPROVALS_PAGE_SIZE,
  payload
})

export const createLeaveSuccess = (payload) => (dispatch) => {
  if (payload.processing_data) {
    toastr.error('System is processing your quotas, please try again in some time.')
  } else {
    dispatch({
      type: types.CREATE_LEAVE_SUCCESS,
      payload
    })
    dispatch(HIDE_MODAL)

    if (payload.leave_type === 'ewd') {
      toastr.success('Request Created Successfully!')
    } else {
      toastr.success('Leave Applied Successfully!')
    }
  }
}

export const createLeaveFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_LEAVE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to create Leave!')
}

export const updateLeaveSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_LEAVE_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Leave Updated Successfully')
}

export const updateLeaveFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_LEAVE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed To Update Leave!')
}

export const fetchLeavesSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_LEAVES_SUCCESS,
    payload
  })
}

export const fetchLeavesFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_LEAVES_FAILURE,
    payload
  })
}

export const fetchLeaveApprovalsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_LEAVE_APPROVALS_SUCCESS,
    payload
  })
}

export const fetchLeaveApprovalsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_LEAVE_APPROVALS_FAILURE,
    payload
  })
}

export const updateLeaveStatusSuccess = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: extraParams?.flag ? types.UPDATE_LEAVES_CLEARANCE_DATA_SUCCESS : types.CREATE_LEAVE_STATUS_SUCCESS,
    userId: extraParams?.userId,
    payload
  })
  dispatch(HIDE_MODAL)
  toastr.success('Status Changed Successfully!')
}

export const updateLeaveStatusFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_LEAVE_STATUS_FAILURE,
    payload
  })

  const leave_count = payload?.processed_leave_count

  if (leave_count) {
    toastr.success(`${leave_count} Leaves Statuses updated Successfully!`)
    toastr.error('Failed to update Leave Status!')
  } else if (payload.leave_type === 'ewd') {
    toastr.error('Failed to update Status!')
  } else {
    toastr.error('Failed to update Leave Status!')
  }
}

export const createHrLeaveStatusSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_HR_LEAVE_STATUS_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  if (payload.leave_type === 'ewd') {
    toastr.success('Request Status changed Successfully!')
  } else {
    toastr.success('Leave Status changed Successfully!')
  }
}

export const createHrLeaveStatusFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_HR_LEAVE_STATUS_FAILURE,
    payload
  })

  toastr.error('Failed to revert Leave!')
}

export const createLeaveStatusForApplicantSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_LEAVE_STATUS_FOR_APPLICANT_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)
  toastr.success('Status changed Successfully!')
}

export const createLeaveStatusForApplicantFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_LEAVE_STATUS_FOR_APPLICANT_FAILURE,
    payload
  })

  if (payload.leave_type === 'ewd') {
    toastr.error('Failed to update Status!')
  } else {
    toastr.error('Failed to update Leave Status!')
  }
}

export const fetchUserLeaveHistorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_LEAVE_HISTORY_SUCCESS,
    payload
  })
}

export const fetchUserLeaveHistoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_LEAVE_HISTORY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch User leave history!')
}

export const fetchUserLeavesHistorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_LEAVES_HISTORY_SUCCESS,
    payload
  })
  warningAlert(payload.warnings)
}

export const fetchUserLeavesHistoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_LEAVES_HISTORY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch leave data!')
}

export const clearUserLeavesHistory = (payload) => (dispatch) => {
  dispatch({
    type: types.CLEAR_USER_LEAVES_HISTORY,
    payload
  })
}

export const fetchLeaveLifeCycleSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_LEAVE_LIFECYCLE_SUCCESS,
    payload
  })
}

export const fetchLeaveLifeCycleFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_LEAVE_LIFECYCLE_FAILURE,
    payload
  })
}

export const fetchAllLeavesSummarySuccess = (payload) => ({
  type: types.FETCH_ALL_LEAVES_SUMMARY_SUCCESS,
  payload
})

export const fetchAllLeavesSummaryFailure = (payload) => ({
  type: types.FETCH_ALL_LEAVES_SUMMARY_FAILURE,
  payload
})

export const fetchAllUsersLeaveHistorySuccess = (payload) => ({
  type: types.FETCH_ALL_USERS_LEAVES_HISTORY_SUCCESS,
  payload
})

export const fetchAllUsersLeaveHistoryFailure = (payload) => ({
  type: types.FETCH_ALL_USERS_LEAVES_HISTORY_FAILURE,
  payload
})

export const deleteLeaveSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_LEAVE_SUCCESS,
    payload
  })

  if (payload.leave_type === 'ewd') {
    toastr.success('Request Deleted Successfully!')
  } else {
    toastr.success('Leave Deleted Successfully!')
  }
}

export const deleteLeaveFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_LEAVE_FAILURE,
    payload
  })

  if (payload.leave_type === 'ewd') {
    errorsAlert(payload, 'Failed to delete request!')
  } else {
    errorsAlert(payload, 'Failed to delete leave application!')
  }
}

export const fetchManuallyAdjustedLeavesSuccess = (payload) => ({
  type: types.FETCH_MANUALLY_ADJUSTED_LEAVES_SUCCESS,
  payload
})

export const fetchManuallyAdjustedLeavesFailure = (payload) => ({
  type: types.FETCH_MANUALLY_ADJUSTED_LEAVES_FAILURE,
  payload
})

export const createHolidaySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_HOLIDAY_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Holiday Created Successfully!')
}

export const createHolidayFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_HOLIDAY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to create Holiday!')
}

export const fetchHolidaysSuccess = (payload) => ({
  type: types.FETCH_HOLIDAYS_SUCCESS,
  payload
})

export const fetchHolidaysFailure = (payload) => ({
  type: types.FETCH_HOLIDAYS_FAILURE,
  payload
})

export const deleteHolidaySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_HOLIDAY_SUCCESS,
    payload
  })

  toastr.success('Holiday Deleted Successfully!')
}

export const deleteHolidayFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_HOLIDAY_FAILURE,
    payload
  })

  toastr.error('Failed to delete holiday!')
}

export const updateHolidaySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HOLIDAY_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Holiday Updated Successfully!')
}

export const updateHolidayFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HOLIDAY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update Holiday!')
}

export const fetchUpcomingHolidaysSuccess = (payload) => ({
  type: types.FETCH_UPCOMING_HOLIDAYS_SUCCESS,
  payload
})

export const fetchUpcomingHolidaysFailure = (payload) => ({
  type: types.FETCH_UPCOMING_HOLIDAYS_FAILURE,
  payload
})

export const fetchRangeLeavesSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_RANGE_LEAVES_SUCCESS,
    payload
  })

  toastr.success(payload.message)
}

export const showLeaveLoader = () => (dispatch) => dispatch({ type: types.SHOW_LEAVE_LOADER })

export const hideLeaveLoader = {
  type: types.HIDE_LEAVE_LOADER
}

export const fetchRangeLeavesFailure = (payload) => ({
  type: types.FETCH_RANGE_LEAVES_FAILURE,
  payload
})

export const clearRangeLeaves = () => ({
  type: types.CLEAR_RANGE_LEAVES
})

export const createManualLeaveSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_MANUAL_LEAVE_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  payload.errors ? errorsAlert(payload) : toastr.success('Leave added successfully!')
}

export const updateLeaveStatusesSuccess = (_payload) => (dispatch) => {
  dispatch(HIDE_MODAL)

  toastr.success('Leave Statuses updated Successfully!')
}

export const createManualLeaveFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_MANUAL_LEAVE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to add Leave!')
}

export const applyLeaveEncashmentRolloverSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.APPLY_LEAVE_ENCASHMENT_ROLLOVER_SUCCESS,
    payload
  })
  toastr.success(`Leaves ${payload.encashed ? 'Encashed' : 'Roll-Overed'} Successfully!`)
  dispatch(HIDE_MODAL)
}

export const applyLeaveEncashmentRolloverFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.APPLY_LEAVE_ENCASHMENT_ROLLOVER_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to apply leave encash/rollover!')
}

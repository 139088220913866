import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { fetchDepartmentsListFailure, fetchDepartmentsListSuccess } from 'actions/department'
import { createQuestionFailure, createQuestionSuccess } from 'actions/question'
import { fetchDepartmentsList } from 'api/department'
import { newQuestion } from 'api/question'
import { RegularCard } from 'components'
import { collectionIntoSelectOptions } from 'helpers/settingsHelper'

import QuestionForm from './QuestionForm'

const CreateQuestion = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    props.fetchDepartmentsList()
  }, [])

  const handleSubmit = (question) => {
    props.newQuestion(question)
    navigate('/faqs')
  }

  return (
    <div className="my-regular-card">
      <RegularCard
        cardTitle={<b>Add FAQs</b>}
        cardSubtitle="Submit your FAQs for convenience of the clients."
        content={<QuestionForm isNew={true} departments={props.departments} onSubmit={handleSubmit} />}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  newQuestion: (params) => {
    dispatch(newQuestion(params, createQuestionSuccess, createQuestionFailure))
  },
  fetchDepartmentsList: (_) => {
    dispatch(fetchDepartmentsList(fetchDepartmentsListSuccess, fetchDepartmentsListFailure))
  }
})

const mapStateToProps = (state) => ({
  departments: collectionIntoSelectOptions(state.departments.departmentsList, 'id', 'name'),
  submission: state.formSubmission.submission
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestion)

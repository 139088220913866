import { stringify } from 'qs'

import request from './request'

export const createDepartment = (params, successAction, failureAction) => {
  return request(
    'admin/departments',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ department: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchDepartments = (params = {}, successAction, failureAction, moderator_departments = false) => {
  return request(
    `admin/departments?moderator_departments=${moderator_departments}&${stringify(params, {
      arrayFormat: 'brackets'
    })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const deleteDepartment = (params, successAction, failureAction) => {
  return request(
    `admin/departments/${params}`,
    {
      method: 'DELETE',
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const getDepartment = (id, successAction, failureAction) => {
  return request(`admin/departments/${id}`, {}, successAction, failureAction, true)
}

export const updateDepartment = (params, id, successAction, failureAction, flag = false, userId) => {
  return request(
    `admin/departments/${id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params),
      submission: true
    },
    successAction,
    failureAction,
    true,
    {
      userId,
      flag
    }
  )
}

export const fetchSelectDepartments = (
  successAction,
  failureAction,
  isRestricted = false,
  moderator_departments = false,
  user_department = false,
  non_support_staff = false,
  ticket_reports = false
) => {
  const urlString = `admin/departments/select_departments/?is_restricted=${isRestricted}&moderator_departments=${moderator_departments}&user_department=${user_department}&non_support_staff=${non_support_staff}&ticket_reports=${ticket_reports}`
  return request(urlString, {}, successAction, failureAction, true)
}

export const fetchDepartmentsList = (successAction, failureAction, category_moderator = false) => {
  return request(
    `admin/departments/select_departments?include_moderator=true&category_moderator=${category_moderator}`,
    {},
    successAction,
    failureAction,
    true
  )
}

export const fetchTicketActionDepartments = (successAction, failureAction, ticket_action_form = false) => {
  return request(
    `admin/departments/select_departments?ticket_action_form=${ticket_action_form}`,
    {},
    successAction,
    failureAction,
    true
  )
}

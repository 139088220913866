import { memo, useMemo } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { debounce } from 'lodash'
import hash from 'object-hash'
import MagicUrl from 'quill-magic-url'
// eslint-disable-next-line no-unused-vars
import mention from 'quill-mention'

import { fetchSearchUsersFailure, fetchSearchUsersSuccess } from 'actions/user'
import { fetchUsers } from 'api/user'
import { Danger } from 'components'
import useSelectUser from 'components/SelectWrapper/useSelectUser'

import MentionBlot from './CustomBlot'
import CustomInputFile from './CustomInputFile'
import CustomToolbar from './CustomToolbar'

import 'react-quill/dist/quill.snow.css'
import 'quill-mention/dist/quill.mention.css'
import './style/customEditorStyle.scss'

Quill.register(MentionBlot)

Quill.register('modules/magicUrl', MagicUrl)
const filtersHash = hash({})

let icons = Quill.import('ui/icons')
icons['image'] =
  '<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#444"><path d="M696-312q0 89.86-63.07 152.93Q569.86-96 480-96q-91 0-153.5-65.5T264-319v-389q0-65 45.5-110.5T420-864q66 0 111 48t45 115v365q0 40.15-27.93 68.07Q520.15-240 480-240q-41 0-68.5-29.09T384-340v-380h72v384q0 10.4 6.8 17.2 6.8 6.8 17.2 6.8 10.4 0 17.2-6.8 6.8-6.8 6.8-17.2v-372q0-35-24.5-59.5T419.8-792q-35.19 0-59.5 25.5Q336-741 336-706v394q0 60 42 101.5T480-168q60 1 102-43t42-106v-403h72v408Z"/></svg>'

const CustomMentionEditor = ({
  placeholder,
  onFileChange,
  deltaContent,
  showFileIcon,
  quillContainerStyle,
  customToolbar,
  customContent,
  input,
  meta: { error, touched },
  ...props
}) => {
  const [results] = useSelectUser()
  const dispatch = useDispatch()
  const debouncedFetchUsers = useMemo(
    () =>
      debounce(
        (params, filtersHash, callback) => dispatch(fetchUsers(params, callback, fetchSearchUsersFailure, filtersHash)),
        800
      ),
    [fetchUsers]
  )

  const mentions = useMemo(
    () => ({
      magicUrl: {
        urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
        globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g
      },
      toolbar: {
        container: customToolbar
          ? '#custom-toolbar'
          : [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ align: [] }],
              ['link', showFileIcon && 'image'],
              [{ color: [] }]
            ],
        handlers: {
          image: () => showFileIcon && CustomInputFile(onFileChange)
        }
      },
      mention: deltaContent
        ? {
            allowedChars: /^[A-Za-z\s]*$/,
            defaultMenuOrientation: 'top',
            mentionDenotationChars: ['@'],
            source: async (searchTerm, renderList) => {
              if (searchTerm.length === 0) {
                renderList([], searchTerm)
              } else {
                const mentionMatch = []
                let found = false
                results[filtersHash]?.forEach((user) => {
                  if (user.label.toLowerCase().includes(searchTerm.toLowerCase())) {
                    found = true
                    mentionMatch.push({
                      avatar: user.avatar,
                      value: user.label,
                      id: user.value,
                      link: `${window.origin}/people/${user.value}`
                    })
                  }
                  renderList(mentionMatch, searchTerm)
                })
                if (!found)
                  debouncedFetchUsers(
                    { search_params: { name: searchTerm, compact: true } },
                    filtersHash,
                    (payload) => {
                      if (payload) {
                        payload.forEach((user) => {
                          if (user.label.toLowerCase().includes(searchTerm.toLowerCase())) {
                            mentionMatch.push({
                              avatar: user.avatar,
                              value: user.label,
                              id: user.value,
                              link: `${window.origin}/people/${user.value}`
                            })
                          }
                        })
                        renderList(mentionMatch, searchTerm)
                        return fetchSearchUsersSuccess(payload)
                      }
                    }
                  )
              }
            },
            onSelect(item, insertItem) {
              insertItem(item)
            },
            renderItem(item) {
              return `
                <div class="custom-editor-container">
                  <img src="${item.avatar}" class="user-avatar-style">
                  <span class="detail-container">
                     <span class="user-name">${item.value}</span>
                     <span class="user-id">ID-${item.id}</span>
                  </span>
                </div>
              `
            }
          }
        : {}
    }),
    []
  )

  const handleComment = (values, editor) => {
    const delta = editor.getContents()

    if (delta.ops.some((op) => op.insert && op.insert.image)) {
      toastr.error('Image insertion not allowed, please upload image through Attachments')
    } else input.onChange(values)

    deltaContent && deltaContent(delta)
  }

  return (
    <div className={quillContainerStyle}>
      <ReactQuill
        theme="snow"
        value={input.value || ''}
        defaultValue=""
        onChange={(values, delta, source, editor) => {
          if (source === 'user') handleComment(values, editor)
        }}
        onBlur={(range, source, quill) => {
          input.onBlur(quill.getHTML())
        }}
        modules={mentions}
        placeholder={placeholder}
        {...props}
      />
      {customContent && customContent}
      {customToolbar && <CustomToolbar />}
      {touched && error && <Danger>{error}</Danger>}
    </div>
  )
}

export default memo(CustomMentionEditor)

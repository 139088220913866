import { Grid, styled } from 'maple-storybook'

const StyledGrid = styled(Grid)(() => ({
  '&.grid': {
    paddingInline: '10px'
  }
}))

function ItemGrid({ ...props }) {
  const { itemGridClasses, children, ...rest } = props
  return (
    <StyledGrid item {...rest} className={`grid ${itemGridClasses}`}>
      {children}
    </StyledGrid>
  )
}

export default ItemGrid

import { stringify } from 'qs'

import request from './request'

export const fetchNotifications = (page, perPage, unRead, withFilesOnly, successAction, failureAction) => {
  let queryParams = {
    page,
    page_size: perPage,
    only_unread: unRead,
    with_files_only: withFilesOnly
  }
  return request(
    `notifications?${stringify(queryParams, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const readNotification = (params, successAction, failureAction) => {
  return request(
    `notifications/mark_read`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ notification: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchNotificationPreferences = (successAction, failureAction) => {
  return request(
    `users/notification_preferences`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const updateNotificationPreferences = (params, successAction, failureAction) => {
  return request(
    `users/notification_preferences`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

import { styled, Typography } from 'maple-storybook'

import { StyledTypography } from 'variables/styles/typographyStyle.jsx'

const EmptyRecordText = styled(({ children, className, ...rest }) => (
  <Typography type="semibold" color="gray-500" variant="subtitle1" {...rest} className={className}>
    {children}
  </Typography>
))(() => ({
  ...StyledTypography.emptyRecordText
}))

export default EmptyRecordText

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const notifications = (state = initialState.notifications, action) => {
  let copyState, index
  switch (action.type) {
    case types.RECIEVED_REALTIME_NOTIFICATION:
      copyState = { ...state.allNotifications }
      if (Object.keys(action.payload).includes('inprogress_notifcation_id')) {
        index = copyState.notifications.findIndex(
          (notificaiton) => notificaiton.id === action.payload.inprogress_notifcation_id
        )
        if (index !== -1) {
          copyState.notifications.splice(index, 1)
        }
        copyState.notifications = [action.payload.notification, ...copyState.notifications]
      } else {
        copyState.notifications = [action.payload, ...copyState.notifications]
      }
      copyState.unread_count += 1
      return { ...state, allNotifications: copyState }

    case types.FETCH_NOTIFICATIONS_SUCCESS:
      return { ...state, allNotifications: action.payload, callInProgress: '' }

    case types.FETCH_NOTIFICATIONS_WITH_FILES_SUCCESS:
      return { ...state, notificationsWithFiles: action.payload, callInProgress: '' }

    case types.FETCH_NOTIFICATIONS_FAILURE:
      return state

    case types.READ_A_NOTIFICATION_SUCCESS:
      copyState = state.allNotifications.notifications
      copyState.forEach((notification) => {
        if (notification.id === action.payload.notification.ids[0]) {
          notification.read = true
          return
        }
      })
      return {
        ...state,
        allNotifications: { notifications: copyState, unread_count: state.allNotifications.unread_count - 1 }
      }

    case types.READ_A_NOTIFICATION_FAILURE:
      return state

    case types.MARK_ALL_AS_READ_SUCCESS:
      copyState = state.allNotifications.notifications
      copyState.map((notification) => (notification.read = true))
      return {
        ...state,
        allNotifications: { notifications: copyState, unread_count: 0 }
      }

    case types.MARK_ALL_AS_READ_FAILURE:
      return state

    case types.LOAD_MORE_NOTIFICATIONS_SUCCESS:
      copyState = state.allNotifications.notifications.concat(action.payload.notifications)
      return {
        ...state,
        allNotifications: { notifications: copyState, unread_count: action.payload.unread_count },
        callInProgress: ''
      }

    case types.LOAD_MORE_NOTIFICATIONS_FAILURE:
      return state

    case types.SET_NOTIFICATIONS_PAGE_NUMBER:
      return { ...state, pageNumber: action.payload }

    case types.FETCH_UNREAD_NOTIFICATIONS_SUCCESS:
      return { ...state, unreadNotifications: action.payload, callInProgress: '' }

    case types.FETCH_UNREAD_NOTIFICATIONS_FAILURE:
      return state

    case types.SET_CALL_IN_PROGRESS:
      if (action.payload === 'loadMoreNotifications') {
        return { ...state, callInProgress: action.payload }
      } else {
        return { ...state, callInProgress: action.payload, allNotifications: { notifications: [], unread_count: 0 } }
      }

    default:
      return state
  }
}

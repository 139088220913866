import { forwardRef } from 'react'
import { Button } from 'maple-storybook'
import PropTypes from 'prop-types'

const RegularButton = forwardRef(function MUIButton(
  { children, fullWidth, floatRight, variant, disabled, ...rest },
  ref
) {
  return (
    <Button
      ref={ref}
      fullWidth={fullWidth}
      floatRight={floatRight}
      tabIndex={disabled ? -1 : undefined}
      variant={variant ? variant : 'primary'}
      label={children}
      disabled={disabled}
      {...rest}
    />
  )
})
RegularButton.propTypes = {
  fullWidth: PropTypes.bool,
  floatRight: PropTypes.bool
}

export default RegularButton

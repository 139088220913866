import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const itemAllocation = (state = initialState.itemAllocations, action) => {
  switch (action.type) {
    case types.FETCH_ITEM_ALLOCATION_SUCCESS:
      return { ...state, allocations: action.payload }

    case types.FETCH_ITEM_ALLOCATION_FAILURE:
      return state

    case types.CLEAR_ITEM_ALLOCATIONS:
      return { ...state, allocations: [] }

    case types.FETCH_ITEM_ALLOCATION_STATS_SUCCESS:
      return { ...state, itemAllocationStats: action.payload }

    default:
      return state
  }
}

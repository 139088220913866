import { toastr } from 'react-redux-toastr'

import { enableFormSubmission } from 'actions/formSubmission'
import { errorsAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'
import { HIDE_MODAL } from './modal'

export const fetchTicketsSuccess = (payload, flag) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKETS_SUCCESS,
    payload: payload,
    flag: flag
  })
  dispatch(hideTicketLoader)
}

export const fetchTicketsFailure = (payload) => ({
  type: types.FETCH_TICKETS_FAILURE,
  payload
})

export const fetchTicketSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_SUCCESS,
    payload: payload
  })
  dispatch(hideTicketLoader)
}

export const fetchTicketFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_FAILURE,
    payload: payload
  })
  errorsAlert(payload)
}

export const fetchAssignedTicketsSuccess = (payload, flag) => (dispatch) => {
  dispatch({
    type: types.FETCH_ASSIGNED_TICKETS_SUCCESS,
    payload: payload,
    flag: flag
  })
  dispatch(hideTicketLoader)
}

export const fetchTicketCsvAttributeSuccess = (payload) => ({
  type: types.FETCH_TICKET_CSV_ATTRIBUTES_SUCCESS,
  payload
})

export const fetchTicketCsvAttributeFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_CSV_ATTRIBUTES_FAILURE,
    payload
  })
  toastr.error('Failed to fetch csv attributes!')
}

export const fetchAssignedTicketsFailure = (payload) => ({
  type: types.FETCH_ASSIGNED_TICKETS_FAILURE,
  payload
})

export const createTicketSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_TICKET_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)
  dispatch(enableFormSubmission)

  toastr.success('Ticket Created Successfully!')
}

export const createTicketFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_TICKET_FAILURE,
    payload
  })
  dispatch(enableFormSubmission)
  errorsAlert(payload, 'Failed to create Ticket!')
}

export const updateTicketStatusSuccess = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: extraParams?.flag ? types.UPDATE_TICKETS_CLEARANCE_DATA_SUCCESS : types.UPDATE_TICKET_STATUS_SUCCESS,
    userId: extraParams?.userId,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Ticket Status Updated Successfully!')
}

export const updateTicketSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_TICKET_SUCCESS,
    payload: {
      ticket: payload
    }
  })

  toastr.success('Ticket Updated Successfully!')
}

export const updateTicketFailure = (payload) => ({
  type: types.UPDATE_TICKET_FAILURE,
  payload
})

export const updateShowTicketStatusSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_SHOW_TICKET_STATUS_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Ticket Status Updated Successfully!')
}

export const updateTicketStatusFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_TICKET_STATUS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to update ticket status')
}

export const fetchTicketCommentsSuccess = (payload) => ({
  type: types.FETCH_TICKET_COMMENTS_SUCCESS,
  payload
})

export const fetchTicketCommentsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_COMMENTS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to fetch ticket comments')
}

export const createTicketCommentSuccess = (payload) => ({
  type: types.CREATE_TICKET_COMMENT_SUCCESS,
  payload
})

export const createTicketCommentFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_TICKET_COMMENT_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to add comment')
}

export const fetchSearchedTicketsSuccess = (payload, flag) => (dispatch) => {
  dispatch({
    type: types.FETCH_SEARCHED_TICKETS_SUCCESS,
    payload: payload,
    flag: flag
  })
  dispatch(hideTicketLoader)
}

export const fetchSearchedTicketsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_SEARCHED_TICKETS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to search tickets')
}

export const changeTicketParticipantsSuccess = (payload) => (dispatch, getState) => {
  const userId = getState().reduxTokenAuth.currentUser.attributes.id

  dispatch({
    type: types.CHANGE_TICKET_PARTICIPANTS_SUCCESS,
    payload: {
      ticket: payload,
      userId: userId
    }
  })
  dispatch(HIDE_MODAL)

  toastr.success('Ticket Participants Updated Successfully!')
}

export const changeTicketParticipantsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CHANGE_TICKET_PARTICIPANTS_FAILURE,
    payload
  })

  errorsAlert(payload)
}

export const fetchTicketCategoriesSuccess = (payload) => ({
  type: types.FETCH_TICKET_CATEGORIES_SUCCESS,
  payload
})

export const fetchTicketCategoriesFailure = (payload) => ({
  type: types.FETCH_TICKET_CATEGORIES_FAILURE,
  payload
})

export const createTicketCategorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_TICKET_CATEGORY_SUCCESS,
    payload
  })

  toastr.success('Policy Created Successfully!')
}

export const createTicketCategoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_TICKET_CATEGORY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to create policy!')
}

export const updateTicketCategorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_TICKET_CATEGORY_SUCCESS,
    payload
  })

  toastr.success('Ticket Category Updated Successfully!')
}

export const updateTicketCategoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_TICKET_CATEGORY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update category!')
}

export const fetchTicketCategorySuccess = (payload) => ({
  type: types.FETCH_TICKET_CATEGORY_SUCCESS,
  payload
})

export const fetchTicketCategoryFailure = (payload) => ({
  type: types.FETCH_TICKET_CATEGORY_FAILURE,
  payload
})

export const fetchTicketCategoryChangeLogsSuccess = (payload) => ({
  type: types.FETCH_TICKET_CATEGORY_CHANGE_LOGS_SUCCESS,
  payload
})

export const fetchTicketCategoryChangeLogsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_CATEGORY_CHANGE_LOGS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to update category!')
}

export const deleteTicketCategorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_TICKET_CATEGORY_SUCCESS,
    payload
  })
  toastr.success('Ticket Category deleted successfully!')
}

export const deleteTicketCategoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_TICKET_CATEGORY_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to delete Ticket Category!')
}

export const fetchAssignableUsersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ASSIGNABLE_USERS_SUCCESS,
    payload
  })
}

export const fetchAssignableUsersFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ASSIGNABLE_USERS_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch assignable users')
}

export const clearTicket = () => ({
  type: types.CLEAR_TICKET
})

export const clearTicketFetched = () => ({
  type: types.CLEAR_TICKET_FETCHED
})

export const showTicketLoader = {
  type: types.SHOW_TICKET_LOADER
}

export const hideTicketLoader = {
  type: types.HIDE_TICKET_LOADER
}

export const handleTicketSearchChange = (payload) => ({
  type: types.HANDLE_TICKET_SEARCH_CHANGE,
  payload
})

export const fetchTicketStatsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_STATS_SUCCESS,
    payload
  })
}

export const fetchTicketStatsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_STATS_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch ticket stats')
}

export const fetchTicketsCsvDataSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKETS_CSV_DATA_SUCCESS,
    payload
  })

  dispatch(hideTicketLoader)
  toastr.success(payload.message)
}

export const fetchTicketsCsvDataFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKETS_CSV_DATA_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to fetch csv data!')
}

export const fetchTicketActionsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TICKET_ACTIONS_SUCCESS,
    payload
  })
}

export const createTicketActionsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_TICKET_ACTIONS_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)
  toastr.success('Action Created Successfully!')
}

export const deleteTicketActionsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_TICKET_ACTIONS_SUCCESS,
    payload
  })
  toastr.success('Action Deleted Successfully!')
}

export const fetchTicketActionsFailure = (payload) => errorsAlert(payload, 'Failed to Fetch Ticket Actions!')

export const createTicketActionsFailure = (payload) => errorsAlert(payload, 'Failed to create Ticket Actions!')

export const deleteTicketActionsFailure = (payload) => errorsAlert(payload, 'Failed to delete Ticket Actions!')

export const createScheduleEmailSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_SCHEDULE_EMAIL_SUCCESS,
    payload
  })
  toastr.success('Email Scheduled Successfully!')
}

export const createScheduleEmailFailure = (payload) => errorsAlert(payload, 'Failed to schedule email!')

export const deleteScheduleEmailSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_SCHEDULE_EMAIL_SUCCESS,
    payload
  })
  toastr.success('Email Schedule Deleted Successfully!')
}

export const deleteScheduleEmailFailure = (payload) => errorsAlert(payload, 'Failed to delete email schedule!')

export const fetchScheduleEmailSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ALL_SCHEDULES_EMAILS_SUCCESS,
    payload
  })
}

export const fetchScheduleEmailFailure = (payload) => errorsAlert(payload, 'Failed to fetch schedule email!')

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const experiencePositions = (state = initialState.positions, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYMENT_POSITIONS_SUCCESS:
      return { ...state, experiencePositions: action.payload }

    case types.FETCH_EMPLOYMENT_POSITIONS_FAILURE:
      return state

    case types.CREATE_EMPLOYMENT_POSITIONS_SUCCESS:
      return { ...state, experiencePositions: [...state.experiencePositions, action.payload] }

    case types.CREATE_EMPLOYMENT_POSITIONS_FAILURE:
      return state

    default:
      return state
  }
}

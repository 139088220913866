import * as types from 'actions/actionTypes'
import * as PermissionsHelper from 'helpers/permissionsHelper'

import initialState from './initialState'

export const permission = (state = initialState.permissions, action) => {
  switch (action.type) {
    case types.FETCH_PERMISSION_SUCCESS:
      return {
        ...state,
        allPermissions: action.payload.allPermissions,
        defaultPermissions: PermissionsHelper.extractDefaultPermissions(action.payload.allPermissions),
        userPermissions: action.payload.permissions ?? state.userPermissions
      }
    case types.FETCH_PERMISSIONS_OBJECT_SUCCESS:
      return { ...state, permissionsObj: action.payload }

    case types.FETCH_PERMISSIONS_OBJECT_FAILURE:
      return state
    default:
      return state
  }
}

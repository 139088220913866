import { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import FileSaver from 'file-saver'
import { Grid } from 'maple-storybook'
import {
  ErrorOutline,
  FileDownloadOutlined,
  InsertDriveFileOutlined,
  NotificationsOutlined,
  WarningAmber
} from 'maple-storybook/assets'

import { Loader } from 'components'
import { extractPath, formatLongText, timesAgo } from 'helpers/applicationHelper'

import './styles.scss'

const NotificationRow = ({ readSingleNotification, notification, handleDrawerClose }) => {
  let downloadButtonClicked = false
  const [downloadLoader, setDownloadLoader] = useState(false)

  const goToNotification = () => {
    !notification.read && readSingleNotification({ ids: [notification.id] })
    !downloadButtonClicked && handleDrawerClose(false)
  }

  const saveCSVFile = (e) => {
    e.preventDefault()
    downloadButtonClicked = true
    setDownloadLoader(true)
    if (notification.action_url.includes('spreadsheet_data')) {
      FileSaver.saveAs(notification.action_url, 'spreadsheet_data.xls')
      setDownloadLoader(false)
    } else {
      const csvName = notification.csv_name
        ? `${notification.csv_name}.csv`
        : notification.action_url
            .split('/')
            .pop()
            .split('?')[0]
            .replace(/.{11}(?=\.csv$)/, '')
            .replaceAll('_', ' ')
      fetch(notification.action_url)
        .then((response) => {
          return response.text()
        })
        .then((data) => {
          const blobCSV = new Blob([data], { type: 'csv' })
          FileSaver.saveAs(blobCSV, csvName)
          setDownloadLoader(false)
        })
        .catch(() => {
          toastr.error('An error occurred while download CSV')
          setDownloadLoader(false)
        })
    }
  }

  return (
    <Grid className="notification-container">
      <Link
        to={
          notification.type === 'CsvInprogressNotification'
            ? null
            : !notification.base_url && !notification.action_url
              ? null
              : notification.type === 'CsvDownloadNotification'
                ? `${notification.base_url}`
                : `/${extractPath(notification.action_url)}`
        }>
        <Grid
          className={`notification-row d-flex cursor-pointer ${
            notification.read ? 'read-notification' : 'unread-notificaiton'
          } `}
          onClick={goToNotification}>
          {notification.type === 'CsvDownloadNotification' ? (
            <>
              {!notification.action_url ? (
                <ErrorOutline className="expired-icon" />
              ) : (
                <InsertDriveFileOutlined className="unread-icon" />
              )}
              <Grid className="flex-column notification-message mr-half">
                <span className="notification-text">
                  {!notification.action_url ? `Your CSV Link has expired.` : formatLongText(notification.message, 180)}
                </span>
                <span className="times-ago-text">{timesAgo(notification.created_at)}</span>
              </Grid>
              {notification.action_url &&
                (!downloadLoader ? (
                  <FileDownloadOutlined className="margin-left-auto download-icon" onClick={(e) => saveCSVFile(e)} />
                ) : (
                  <Grid className="d-flex align-items-center margin-left-auto">
                    <Loader />
                  </Grid>
                ))}
            </>
          ) : (
            <>
              {notification.type === 'CsvInprogressNotification' ? (
                <WarningAmber className="file-icon" />
              ) : (
                <NotificationsOutlined className={notification.read ? 'read-icon' : 'unread-icon'} />
              )}
              <Grid className="flex-column notification-message">
                <span className="notification-text">{formatLongText(notification.message, 180)}</span>
                <span className="times-ago-text">{timesAgo(notification.created_at)}</span>
              </Grid>
            </>
          )}
        </Grid>
      </Link>
    </Grid>
  )
}

export default NotificationRow

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const userSetting = (state = initialState.userSettings, action) => {
  switch (action.type) {
    case types.SET_USER_SETTINGS_TAB:
      return { ...state, tab: action.payload.tab }

    case types.FETCH_NOTIFICATION_PREFERENCES_SUCCESS: {
      return { ...state, preferences: action.payload }
    }
    case types.FETCH_NOTIFICATION_PREFERENCES_FAILURE:
      return state

    case types.UPDATE_NOTIFICATION_PREFERENCES_SUCCESS:
      return { ...state, preferences: action.payload }

    case types.UPDATE_NOTIFICATION_PREFERENCES_FAILURE:
      return state

    default:
      return state
  }
}

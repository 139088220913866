import { showClaimTypeLoader } from 'actions/claim_type'

import request from './request'

export const createClaimType = (params, successAction, failureAction) => {
  return request(
    'claim_types',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        claim_type: params
      }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchClaimTypes = (successAction, failureAction) => {
  return request('claim_types', {}, successAction, failureAction, true)
}

export const fetchClaimType = (id, successAction, failureAction) => (dispatch) => {
  dispatch(request(`claim_types/${id}`, {}, successAction, failureAction, true))
  dispatch(showClaimTypeLoader)
}

export const updateClaimType =
  (id, params, close_effected_claims, successAction, failureAction) => (dispatch, getState) => {
    getState().claimTypes.updatedClaimType = []

    dispatch(
      request(
        `claim_types/${id}/?close_effected_claims=${close_effected_claims}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            claim_type: params
          }),
          submission: true
        },
        successAction,
        failureAction,
        true
      )
    )
  }

export const fetchClaimTypesDetails =
  (id, date, successAction, failureAction, thread_id = null) =>
  (dispatch) => {
    dispatch(
      request(
        `claim_types/${id}/?date=${date}&thread_id=${thread_id}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        },
        successAction,
        failureAction,
        true
      )
    )

    dispatch(showClaimTypeLoader)
  }

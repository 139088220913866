import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const technology = (state = initialState.technologies, action) => {
  switch (action.type) {
    case types.FETCH_TECHNOLOGIES_SUCCESS:
      return { ...state, technologies: action.payload }

    case types.FETCH_TECHNOLOGIES_FAILURE:
      return state

    default:
      return state
  }
}

import { Hidden } from 'maple-storybook'

import HeaderLinks from 'components/Header/HeaderLinks'

import 'components/Header/styles.scss'

const Header = () => (
  <header className="header-appbar">
    <Hidden implementation="css">
      <HeaderLinks />
    </Hidden>
  </header>
)

export default Header

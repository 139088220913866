import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const subscriptionReports = (state = initialState.subscriptionsReports, action) => {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTIONS_REPORT_SUCCESS:
      return { ...state, reports: action.payload }

    case types.FETCH_SUBSCRIPTIONS_REPORT_FAILURE:
      return state

    case types.FETCH_SUBSCRIPTIONS_REDEEMED_REPORT_SUCCESS:
      return { ...state, redeemSubscriptionData: action.payload }

    case types.FETCH_SUBSCRIPTIONS_REDEEMED_REPORT_FAILURE:
      return state

    case types.FETCH_PEAK_HOURS_REPORTS_SUCCESS:
      return { ...state, peakHoursData: action.payload }

    case types.FETCH_PEAK_HOURS_REPORTS_FAILURE:
      return state

    case types.FETCH_SUBSCRIPTION_BUFFER_SUCCESS:
      return { ...state, buffer: action.payload }

    case types.FETCH_SUBSCRIPTION_BUFFER_FAILURE:
      return state

    case types.UPDATE_SUBSCRIPTION_BUFFER_SUCCESS:
      return { ...state, buffer: action.payload }

    case types.UPDATE_SUBSCRIPTION_BUFFER_FAILURE:
      return state

    default:
      return state
  }
}

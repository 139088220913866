import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const sidebar = (state = initialState.sidebar, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR:
      return { ...state, open: action.payload || !state.open }

    default:
      return state
  }
}

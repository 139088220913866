import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const modalReducer = (state = initialState.modals, action) => {
  let filteredModals, modals

  switch (action.type) {
    case types.SHOW_MODAL:
      if (state.modals.length && state.modals.filter((modal) => modal.modalType === action.modalType).length)
        return state

      modals = [...state.modals]
      modals.push({
        index: modals.length,
        modalType: action.modalType,
        modalProps: { open: true, ...action.modalProps }
      })

      return { ...state, modals: modals }

    case types.HIDE_MODAL:
      if (!state.modals.length) return state

      filteredModals = [...state.modals]
      filteredModals = filteredModals.filter((modal) => modal.index !== filteredModals.length - 1)

      return { ...state, modals: filteredModals }

    default:
      return state
  }
}

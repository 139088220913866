import { Component } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useRollbar } from '@rollbar/react'
import { cloneDeep } from 'lodash'
import { Page404 } from 'maple-storybook'

import image404 from 'assets/img/image404.png'

class MyErrorBoundary extends Component {
  state = { hasError: false, error: null }

  static getDerivedStateFromError(error) {
    if (error.message.includes('dynamically imported module')) return window.location.reload()
    else return { hasError: true, error }
  }

  componentDidCatch(error, info) {
    if (
      error.message.includes('TypeError') &&
      !['received', 'openLiveChat', 'closeLiveChat', 'Unexpected token <', 'maplehr.io/static/'].some(
        (whiteListError) => error.message.includes(whiteListError)
      )
    ) {
      const { message, stack } = cloneDeep(error)
      const modifyError = (error) => `For User ${this.props.userName} with (ID-${this.props.userId}) ${error}`
      this.props.rollbar.error({ message: modifyError(message), stack: modifyError(stack) }, info)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Page404
          errorCode={this.state.error.toString()}
          navigateBack={() => {
            this.props.navigate(-1, { replace: true })
            window.location.reload()
          }}
          navigateHome={() => {
            this.props.navigate('/', { replace: true })
            window.location.reload()
          }}
          notFoundImg={<img height="500" src={image404} alt="notfound" className="no-found-image" />}
        />
      )
    }

    return this.props.children
  }
}

function ErrorBoundaryWrapper(props) {
  const rollbar = useRollbar()
  const navigate = useNavigate()

  return <MyErrorBoundary rollbar={rollbar} navigate={navigate} {...props} />
}

const mapStateToProps = (state) => {
  let userId = state.reduxTokenAuth.currentUser.attributes.id
  return {
    userId: userId,
    userName: state.users.allUserProfiles[userId]?.name
  }
}

export default connect(mapStateToProps, null)(ErrorBoundaryWrapper)

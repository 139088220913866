import { Component } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from 'maple-storybook'
import { Close } from 'maple-storybook/assets'

import { HIDE_MODAL } from 'actions/actionTypes'

class FormModal extends Component {
  render() {
    const {
      closeIconClass,
      disableCloseOnEscapeKeyDown,
      form,
      fullscreen,
      handleClose,
      open,
      subtitle,
      title,
      width,
      hideCancelButton = false,
      divider
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-main-div modal-dialog-style"
        fullWidth={true}
        maxWidth={width || 'md'}
        disableEscapeKeyDown={disableCloseOnEscapeKeyDown || false}
        fullScreen={fullscreen ? true : false}>
        <DialogTitle id="form-dialog-title">
          {title ? title : 'Title'}
          {!hideCancelButton && (
            <Close onClick={handleClose} className={`${closeIconClass} cursor-pointer float-right`} />
          )}
          {divider && <hr />}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{subtitle}</DialogContentText>
          {form}
        </DialogContent>
      </Dialog>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: (_event, reason) => {
    if (ownProps.preventBackDropClose && reason && reason === 'backdropClick') return

    dispatch({ type: HIDE_MODAL })
  }
})

export default connect(null, mapDispatchToProps)(FormModal)

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const university = (state = initialState.universities, action) => {
  switch (action.type) {
    case types.FETCH_UNIVERSITIES_SUCCESS:
      return { ...state, universities: action.payload }

    case types.FETCH_UNIVERSITIES_FAILURE:
      return state

    case types.CREATE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        universities: [...state.universities, action.payload]
      }

    default:
      return state
  }
}

import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const error = (state = initialState.errors, action) => {
  switch (action.type) {
    case types.APP_ERROR_OCCURED:
      return { ...state, appError: true }
    default:
      return state
  }
}

import { Component } from 'react'
import { connect } from 'react-redux'

import { checkPermission } from 'helpers/permissionsHelper'

class Permissible extends Component {
  checkModuleEnabled = (group, entity, action) => {
    if (
      (group === 'projects' || (group === 'policies' && entity === 'general' && action === 'project_settings')) &&
      this.props.currentCompany.modules.project === false
    )
      return false
    else if (
      (group === 'dnars' || (group === 'policies' && entity === 'general' && action === 'dnars_settings')) &&
      this.props.currentCompany.modules.dnar === false
    )
      return false
    else if (group === 'workflows' && this.props.currentCompany.modules.workflow === false) return false
    else if (group === 'subscriptions' && this.props.currentCompany.modules.subscription === false) return false
    else if (group === 'slack_announcements' && this.props.currentCompany.modules?.slack_announcement === false)
      return false
    else if (
      (group === 'claims' || (group === 'policies' && entity === 'general' && action === 'claim_settings')) &&
      this.props.currentCompany.modules.claim === false
    )
      return false
    else if (
      group === 'policies' &&
      entity === 'general' &&
      action === 'dynamic_forms' &&
      this.props.currentCompany.modules.workflow === false
    )
      return false
    else if (group === 'ams' && this.props.currentCompany.modules.ams === false) return false

    return true
  }

  render() {
    const {
      userPermissions = {},
      group,
      entity = null,
      action = null,
      ignoreAdmin,
      children = null,
      alternateComponent = null,
      currentCompany
    } = this.props

    return ((entity === null && action === null) ||
      checkPermission(userPermissions, group, entity, action, !!ignoreAdmin).isFull()) &&
      currentCompany?.modules &&
      this.checkModuleEnabled(group, entity, action)
      ? children
      : alternateComponent
  }
}

const mapStateToProps = (state) => ({
  userPermissions: state.permissions.userPermissions,
  currentCompany: state.currentCompany
})

export default connect(mapStateToProps)(Permissible)

import { connect } from 'react-redux'

import { createDayFeedbackFailure, createDayFeedbackSuccess } from 'actions/user'
import { createDayFeedback } from 'api/user'
import { CustomSnackbar } from 'components'

import DayFeedbackForm from './DayFeedbackForm'

const DayFeedback = (props) => {
  const { userId, createDayFeedback } = props

  const handleSubmit = (values) => {
    createDayFeedback({
      userId: userId,
      rating: values.rating
    })
  }

  return (
    <CustomSnackbar open vertical="bottom" horizontal="right">
      <DayFeedbackForm onSubmit={handleSubmit} />
    </CustomSnackbar>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: state.reduxTokenAuth.currentUser.attributes.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createDayFeedback: (params) => {
      dispatch(createDayFeedback(params, createDayFeedbackSuccess, createDayFeedbackFailure))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DayFeedback)

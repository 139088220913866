import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const formSubmission = (state = initialState.formSubmission, action) => {
  switch (action.type) {
    case types.ENABLE_FORM_SUBMISSION:
      return { ...state, submission: false }

    case types.DISABLE_FORM_SUBMISSION:
      return { ...state, submission: true }

    default:
      return state
  }
}

import React from 'react'

import { CustomInput, Danger } from 'components'

import './style.scss'

const CustomInputWrapper = ({
  input,
  meta,
  maxLength,
  helpText,
  helpTextColor,
  formControlProps,
  inputContainerStyle,
  inputProps,
  disabled,
  ...rest
}) => (
  <div
    className={`flex-column ${
      meta && input ? 'simple-input-form-styles' : 'simple-input-search-styles'
    } ${inputContainerStyle}`}>
    <CustomInput
      formControlProps={{
        ...formControlProps,
        fullWidth: true
      }}
      inputProps={{
        disabled: disabled,
        error: meta?.touched && meta.error,
        ...inputProps,
        ...input,
        maxLength: maxLength
      }}
      {...rest}
    />

    {meta?.touched && meta?.error && <Danger>{meta.error}</Danger>}
    {helpText ? <small style={{ color: helpTextColor ? helpTextColor : 'blue' }}>{helpText}</small> : null}
  </div>
)

export default CustomInputWrapper

import * as types from 'actions/actionTypes'
import { checkArrayPresence, totalItems } from 'helpers/applicationHelper'
import initialState from 'reducers/initialState'

export const workflow = (state = initialState.workflow, action) => {
  let workflowsCategories
  let categoryIndex, updatedWorkflow, existingTask, index
  let tempArray = []
  let updatedTasks = []

  switch (action.type) {
    case types.FETCH_WORKFLOW_CATEGORIES_SUCCESS:
      return { ...state, workflowsCategories: action.payload }

    case types.FETCH_WORKFLOW_CATEGORIES_FAILURE:
      return state

    case types.FETCH_WORKFLOW_CATEGORY_SUCCESS:
      return {
        ...state,
        workflowsCategory: {
          ...action.payload.workflow_category,
          workflows: action.payload.workflows
        }
      }

    case types.FETCH_WORKFLOW_CATEGORY_FAILURE:
      return state

    case types.DELETE_WORKFLOW_CATEGORY_SUCCESS:
      categoryIndex = state.workflowsCategories.findIndex((category) => category.id === action.payload.id)
      workflowsCategories = [...state.workflowsCategories]
      workflowsCategories.splice(categoryIndex, 1)
      return { ...state, workflowsCategories: workflowsCategories }

    case types.DELETE_WORKFLOW_CATEGORY_FAILURE:
      return state

    case types.CREATE_WORKFLOW_CATEGORY_SUCCESS:
      return { ...state, workflowsCategories: [action.payload, ...state.workflowsCategories] }

    case types.CREATE_WORKFLOW_CATEGORY_FAILURE:
      return state

    case types.UPDATE_WORKFLOW_CATEGORY_SUCCESS:
      categoryIndex = state.workflowsCategories.findIndex((category) => category.id === action.payload.id)
      state.workflowsCategories[categoryIndex] = action.payload

      return { ...state, workflowsCategories: [...state.workflowsCategories] }

    case types.UPDATE_WORKFLOW_CATEGORY_FAILURE:
      return state

    case types.FETCH_WORKFLOW_SUCCESS:
      return { ...state, workflow: action.payload }

    case types.FETCH_WORKFLOW_FAILURE:
      return state

    case types.CREATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflowsCategory: {
          ...state.workflowsCategory,
          workflows: [...state.workflowsCategory.workflows, action.payload]
        }
      }

    case types.CREATE_WORKFLOW_FAILURE:
      return state

    case types.DELETE_WORKFLOW_SUCCESS:
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
      return {
        ...state,
        workflowsCategory: {
          ...state.workflowsCategory,
          workflows: state.workflowsCategory.workflows.filter((workflow) => workflow.id !== action.payload.id)
        }
      }

    case types.DELETE_WORKFLOW_FAILURE:
      return state

    case types.FETCH_WORKFLOW_TASK_SUCCESS:
      return { ...state, task: action.payload }

    case types.FETCH_WORKFLOW_TASK_FAILURE:
      return state

    // tasks
    case types.CREATE_WORKFLOW_TASKS_SUCCESS:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          tasks: [...state.workflow.tasks, ...action.payload]
        }
      }

    case types.CREATE_WORKFLOW_TASKS_FAILURE:
      return state

    case types.UPDATE_WORKFLOW_TASK_ASSIGNEES_SUCCESS:
      if (action.payload.userTasks?.length > 0) {
        return { ...state, userTasks: action.payload.userTasks }
      } else {
        updatedTasks = state.workflow.tasks.map((task) => {
          return action.payload.find((updated) => updated.id === task.id) || task
        })
        return { ...state, workflow: { ...state.workflow, tasks: updatedTasks } }
      }

    case types.UPDATE_WORKFLOW_TASK_SUCCESS:
      existingTask = state.workflow.tasks.find((task) => task.id === action.payload.id)
      if (existingTask) {
        updatedTasks = state.workflow.tasks.map((task) => (task.id === action.payload.id ? action.payload : task))
      } else {
        updatedTasks = [...state.workflow.tasks, action.payload]
      }
      updatedWorkflow = { ...state.workflow, tasks: updatedTasks }
      return { ...state, workflow: updatedWorkflow }

    case types.UPDATE_WORKFLOW_TASK_FAILURE:
      return state

    case types.DELETE_WORKFLOW_TASK_SUCCESS:
      updatedTasks = state.workflow.tasks.filter((task) => task.id !== action.payload.id)
      updatedWorkflow = { ...state.workflow, tasks: updatedTasks }
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
      return { ...state, workflow: updatedWorkflow }

    case types.DELETE_WORKFLOW_TASK_FAILURE:
      return state

    case types.FETCH_USER_TASKS_SUCCESS:
      if (action.view) {
        return { ...state, userTasks: [...action.payload] }
      } else {
        tempArray = [...state.userTasks, ...action.payload]
        return { ...state, userTasks: action.render ? [] : tempArray }
      }

    case types.FETCH_USER_TASKS_FAILURE:
      return state

    case types.FETCH_USER_TASK_SUCCESS:
      return { ...state, userTask: action.payload }

    case types.FETCH_USER_TASK_FAILURE:
      return state

    case types.UPDATE_USER_TASK_SUCCESS: {
      let userTasks = [...state.userTasks]
      index = state.userTasks?.findIndex((task) => task.id === action.payload.id)
      if (action.payload.userTasks !== undefined) {
        if (action.payload.field_change === 'status') {
          action.payload.userTasks.map((task) => {
            index = userTasks.findIndex((prevTask) => prevTask.id === task.id)
            userTasks[index] = task
          })
          return { ...state, userTasks: userTasks }
        } else {
          let taskIds = action.payload.userTasks.map((task) => task.id)
          let updatedTask = userTasks.filter((task) => !taskIds.includes(task.id))
          sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - taskIds.length)
          return { ...state, userTasks: updatedTask }
        }
      } else if (!action.rowUpdate) {
        if (action.payload.completed && checkArrayPresence(userTasks)) {
          let updatedTask = userTasks.find((task) => task.subtasks.map((sub) => sub.id).includes(action.payload.id))
          index = state.userTasks?.findIndex((task) => task.id === updatedTask.id)
          let updatedSubtaskIndex = userTasks[index].subtasks.findIndex((subtask) => subtask.id === action.payload.id)
          userTasks[index].subtasks[updatedSubtaskIndex] = {
            ...userTasks[index].subtasks[updatedSubtaskIndex],
            completed: action.payload.completed
          }
        } else if (index !== -1) {
          if (action.payload.assigned_to.id !== state.userTasks[index].assigned_to.id) {
            userTasks.splice(index, 1)
            sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
          } else {
            userTasks[index] = {
              ...userTasks[index],
              complete_date: action.payload.timeline['Completed Date'],
              status: action.payload.status
            }
          }
        }
        existingTask = { ...state.userTask }
        if (existingTask.id) {
          updatedTasks = [...state.userTask.subtasks]
        }
        if (action.payload.description) {
          index = state.userTask?.subtasks?.findIndex((subtask) => subtask.id === action.payload.id)
          if (index !== -1) {
            updatedTasks[index] = { ...updatedTasks[index], completed: action.payload.completed }
          }
        }
        if (action.payload.response_attachments) {
          existingTask = action.payload
        }
        return { ...state, userTasks: [...userTasks], userTask: { ...existingTask, subtasks: [...updatedTasks] } }
      } else {
        if (index !== -1) {
          userTasks[index] = {
            ...action.payload,
            start_date: action.payload.timeline['Start date'],
            due_date: action.payload.timeline['Due Date']
          }
        }
        return { ...state, userTasks: [...userTasks] }
      }
    }

    case types.UPDATE_USER_TASK_FAILURE:
      return state

    case types.CREATE_TASK_DYNAMIC_FORM_SUCCESS: {
      let formResponsesCount = state.userTask.form_responses_count
      formResponsesCount += 1
      let userTasks = [...state.userTasks]
      let index = userTasks.findIndex((task) => task.id === state.userTask.id)
      userTasks[index] = { ...userTasks[index], form_response_required: false }
      return {
        ...state,
        userTask: { ...state.userTask, form_responses_count: formResponsesCount },
        userTasks: [...userTasks]
      }
    }

    case types.CREATE_TASK_DYNAMIC_FORM_FAILURE:
      return state

    case types.FETCH_WORKFLOW_ORGANO_CHART_SUCCESS: {
      return { ...state, sequentialTasksJson: action.payload }
    }
    case types.FETCH_WORKFLOW_ORGANO_CHART_FAILURE:
      return state

    case types.SHOW_WORKFLOW_ORGANO_CHART_LOADER:
      return { ...state, sequentialTaskLoader: true }

    case types.HIDE_WORKFLOW_ORGANO_CHART_LOADER:
      return { ...state, sequentialTaskLoader: false }

    case types.FETCH_SELECT_WORKFLOW_SUCCESS:
      return { ...state, workflowOptions: action.payload }

    case types.FETCH_SELECT_TASKS_OPTIONS_SUCCESS:
      return { ...state, tasksOptions: action.payload }

    default:
      return state
  }
}

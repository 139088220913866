import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const role = (state = initialState.roles, action) => {
  let roleCreated
  switch (action.type) {
    case types.CREATE_ROLE_SUCCESS:
      roleCreated = [...state.roles, action.payload]

      return { ...state, roles: roleCreated }

    case types.CREATE_ROLE_FAILURE:
      return state

    case types.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        roleLoader: false
      }

    case types.FETCH_ROLES_FAILURE:
      return state

    case types.UPDATE_ROLE_SUCCESS:
      return { ...state, roleUpdated: true }

    case types.UPDATE_ROLE_FAILURE:
      return state

    case types.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.filter((role) => role.id !== action.payload.id)
      }

    case types.DELETE_ROLE_FAILURE:
      return state

    case types.GET_ROLE_SUCCESS:
      return { ...state, role: action.payload, roleLoader: false }

    case types.GET_ROLE_FAILURE:
      return state

    case types.LOADING_ROLE:
      return { ...state, roleLoader: true }

    case types.CLEAR_ROLE_DATA:
      return { ...state, role: {} }

    case types.RESET_ROLE_UPDATED:
      return { ...state, roleUpdated: false }

    default:
      return state
  }
}

import * as types from 'actions/actionTypes'
import { checkArrayEmpty } from 'helpers/applicationHelper'

import initialState from './initialState'

export const item = (state = initialState.items, action) => {
  let itemIndex, items, reducedItem, itemObj, newAllItems, userItems, archivedItems, allItems
  const reducedItems = []
  let itemLogs = []
  let filterItemLogs = []
  let capitalizedItemsHash = {}
  let bulkArchivedItems = []

  switch (action.type) {
    case types.SET_ITEMS_PAGE_SIZE:
      return { ...state, pageSize: action.payload }

    case types.CREATE_ITEM_SUCCESS:
      itemObj = action.payload[0]
      reducedItem = {
        id: itemObj.id,
        barcode: itemObj.barcode,
        name: itemObj.name,
        purchase_date: itemObj.purchase_date,
        price: itemObj.price,
        status: itemObj.status,
        assigned_to: itemObj.assigned_to,
        creator: itemObj.creator,
        created_at: itemObj.created_at,
        category: itemObj.category
      }

      return {
        ...state,
        allItems: [reducedItem, ...state.allItems],
        allItemProfiles: { ...state.allItemProfiles, [itemObj.id]: itemObj },
        itemCreated: false
      }

    case types.CREATE_ITEM_FAILURE:
      return state

    case types.CREATE_REPAIR_ITEM_SUCCESS:
      itemIndex = state.allItems.findIndex((item) => item.id === action.payload.id)
      if (itemIndex !== -1) {
        state.allItems[itemIndex] = action.payload
      }

      return {
        ...state,
        allItems: [...state.allItems]
      }

    case types.ITEM_CAPITALIZATION_SUCCESS:
      capitalizedItemsHash = state.allItemProfiles
      action.payload.forEach((item) => {
        if (capitalizedItemsHash.hasOwnProperty(item.id)) capitalizedItemsHash[item.id] = item
        reducedItems.push({
          id: item.id,
          barcode: item.barcode,
          name: item.name,
          purchase_date: item.purchase_date,
          price: item.price,
          status: item.status,
          assigned_to: item.assigned_to,
          creator: item.creator,
          created_at: item.created_at,
          category: item.category
        })
      })

      return {
        ...state,
        allItems: [...reducedItems, ...state.allItems],
        allItemProfiles: capitalizedItemsHash,
        itemCreated: false
      }

    case types.FETCH_VENDOR_ITEM_SUCCESS:
      return { ...state, vendorItems: action.payload }

    case types.CREATE_RESELL_ITEM_SUCCESS:
      itemIndex = state.allItems.findIndex((item) => item.id === action.payload.id)
      if (itemIndex !== -1) {
        state.allItems[itemIndex] = action.payload
      }

      return {
        ...state,
        allItems: [...state.allItems]
      }

    case types.FETCH_ITEM_SUCCESS:
      return { ...state, allItemProfiles: { ...state.allItemProfiles, [action.payload[0].id]: action.payload[0] } }

    case types.FETCH_ITEM_FAILURE:
      return state

    case types.FETCH_ITEMS_SUCCESS:
      return { ...state, allItems: action.payload, loadingItems: false }

    case types.FETCH_ITEMS_FAILURE:
      return { ...state, loadingItems: false }

    case types.FETCH_ARCHIVED_ITEMS_SUCCESS:
      return { ...state, archivedItems: Object.values({ ...action.payload }) }

    case types.FETCH_ARCHIVED_ITEMS_FAILURE:
      return state

    case types.ASSIGN_ITEM_SUCCESS:
      items = []
      userItems = [...state.userItems]
      archivedItems = [...state.archivedItems]
      allItems = [...state.allItems]

      itemIndex = state.archivedItems.findIndex((item) => item.id === action.payload.item_id)
      if (itemIndex !== -1) {
        if (archivedItems[itemIndex]) {
          archivedItems[itemIndex].status = action.payload.status
          archivedItems[itemIndex].assigned_to = action.payload.assigned_to
        }
      }

      itemIndex = state.allItems.findIndex((item) => item.id === action.payload.item_id)
      if (itemIndex !== -1) {
        allItems[itemIndex].status = action.payload.status
        allItems[itemIndex].assigned_to = action.payload.assigned_to
        allItems[itemIndex].status_with_quantity = action.payload.status_with_quantity
      }

      itemIndex = state.userItems.findIndex((item) => item.item_id === action.payload.item_id)
      if (itemIndex !== -1) {
        userItems[itemIndex].status = action.payload.status
      }
      return {
        ...state,
        allItems: [...allItems],
        archivedItems: [...archivedItems],
        userItems: userItems
      }

    case types.ASSIGN_ITEM_FAILURE:
      return state

    case types.SET_CLONED_ITEM_ID:
      return { ...state, clonedItemId: action.payload }

    case types.SET_CLONE_ITEM:
      return { ...state, clonedItem: action.payload }

    case types.CLEAR_CLONED_ITEM:
      return { ...state, clonedItem: [], clonedItemId: 0 }

    case types.FETCH_ITEMS_LOGS_SUCCESS:
      return { ...state, logs: action.payload }

    case types.FETCH_ITEMS_LOGS_FAILURE:
      return state

    case types.ARCHIVE_ITEM_SUCCESS:
      itemIndex = state.allItems.findIndex((item) => item.id === action.payload.id)

      items = [...state.allItems]
      items.splice(itemIndex, 1)

      return { ...state, allItems: [...items] }

    case types.ARCHIVE_ITEM_FAILURE:
      return state

    case types.ITEM_BULK_UPDATE_SUCCESS:
      bulkArchivedItems = action.payload.bulk_archived_items.map((archivedItem) => archivedItem.id)
      return { ...state, allItems: state.allItems.filter((item) => !bulkArchivedItems.includes(item.id)) }

    case types.UNARCHIVE_ITEM_SUCCESS:
      itemIndex = state.archivedItems.findIndex((item) => item.id === action.payload.id)

      items = [...state.archivedItems]
      items.splice(itemIndex, 1)

      return { ...state, archivedItems: [...items] }

    case types.UNARCHIVE_ITEM_FAILURE:
      return state

    case types.RESET_CLONED_ITEM:
      return { ...state, clonedItem: [] }

    case types.FETCH_USER_ITEMS_SUCCESS:
      return {
        ...state,
        userItems: action.payload.items,
        pendingAcceptanceAllowed: action.payload.pending_acceptance_allowed,
        loadingItems: false
      }

    case types.FETCH_USER_ITEMS_FAILURE:
      return state

    case types.FETCH_ITEM_LOGS_SUCCESS:
      itemLogs = [...action.payload]
      if (itemLogs.length > 0 && itemLogs[0].hasOwnProperty('log')) {
        filterItemLogs = itemLogs.concat(itemLogs[itemLogs.length - 1]['log'])

        filterItemLogs.splice([itemLogs.length - 1], 1)

        filterItemLogs.sort((a, b) =>
          a['created_at'] < b['created_at'] ? 1 : b['created_at'] < a['created_at'] ? -1 : 0
        )

        return { ...state, itemLogs: [...filterItemLogs] }
      } else {
        return { ...state, itemLogs: [...itemLogs] }
      }

    case types.FETCH_ITEM_LOGS_FAILURE:
      return state

    case types.UPDATE_ITEM_SUCCESS:
      itemObj = action.payload[0]

      reducedItem = {
        id: itemObj.id,
        barcode: itemObj.barcode,
        name: itemObj.name,
        purchase_date: itemObj.purchase_date,
        price: itemObj.price,
        status: itemObj.status,
        assigned_to: itemObj.assigned_to,
        creator: itemObj.creator,
        created_at: itemObj.created_at
      }

      itemIndex = state.allItems.findIndex((item) => item.id === itemObj.id)

      newAllItems = [...state.allItems]
      newAllItems[itemIndex] = itemObj

      return {
        ...state,
        allItems: newAllItems,
        allItemProfiles: { ...state.allItemProfiles, [itemObj.id]: itemObj },
        itemUpdated: true
      }

    case types.UPDATE_ITEM_FAILURE:
      return state

    case types.RESET_ITEM_UPDATED:
      return { ...state, itemUpdated: false }

    case types.RESET_ITEM_CREATED:
      return { ...state, itemCreated: false }

    case types.CLEAR_ALL_ITEMS:
      return { ...state, allItems: [] }

    case types.FETCH_SEARCHED_ITEM_LOGS_SUCCESS:
      return { ...state, searchedItemLogs: action.payload }

    case types.FETCH_SEARCHED_ITEM_LOGS_FAILURE:
      return state

    case types.CLEAR_SEARCHED_ITEM_LOGS:
      return { ...state, searchedItemLogs: [] }

    case types.FETCH_PENDING_ITEMS_COUNT_SUCCESS:
      return { ...state, pendingItemsCount: action.payload }

    case types.FETCH_PENDING_ITEMS_COUNT_FAILURE:
      return state

    case types.FETCH_FREE_ITEMS_SUCCESS:
      return { ...state, freeItems: action.payload }

    case types.FETCH_FREE_ITEMS_FAILURE:
      return state

    case types.UPDATE_SUB_ITEMS_SUCCESS:
      return { ...state, allItemProfiles: { ...state.allItemProfiles, [action.payload[0].id]: action.payload[0] } }

    case types.UPDATE_SUB_ITEMS_FAILURE:
      return state

    case types.REMOVE_SUB_ITEM_SUCCESS:
      return { ...state, allItemProfiles: { ...state.allItemProfiles, [action.payload[0].id]: action.payload[0] } }

    case types.REMOVE_SUB_ITEM_FAILURE:
      return state

    case types.FETCH_ITEMS_NAMES_SUCCESS:
      if (action.payload.option) {
        return { ...state, ItemsNames: [...state.ItemsNames, action.payload.option] }
      } else {
        return { ...state, ItemsNames: action.payload }
      }

    case types.FETCH_ITEMS_NAMES_FAILURE:
      return state

    case types.LOADING_ITEMS:
      return { ...state, loadingItems: true }

    case types.FETCH_REPAIR_ITEMS_SUCCESS:
      return { ...state, repairItems: action.payload }

    case types.FETCH_ITEM_REPAIR_HISTORY_SUCCESS:
      return { ...state, itemRepairHistory: action.payload }
    case types.FETCH_RESELL_ITEMS_SUCCESS:
      return { ...state, resellItems: action.payload }

    case types.REMOVE_ITEM_PURCHASES_SUCCESS:
      return { ...state, allItemProfiles: { ...state.allItemProfiles, [action.payload[0].id]: action.payload[0] } }

    case types.UPDATE_ITEM_PURCHASES_SUCCESS:
      return { ...state, allItemProfiles: { ...state.allItemProfiles, [action.payload[0].id]: action.payload[0] } }

    case types.UPDATE_ITEM_STATS_SUCCESS:
      return { ...state, statsLastUpdatedAt: action.payload.updated_at }

    case types.FETCH_BULK_ITEM_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributesForBulkItem: action.payload, csvErrors: [], csvUploadStatus: false }

    case types.UPLOAD_BULK_ITEM_CSV_SUCCESS:
      return { ...state, csvUploadStatus: checkArrayEmpty(state.csvErrors) }

    case types.UPLOAD_BULK_ITEM_CSV_FAILURE:
      return { ...state, csvErrors: [...state.csvErrors, ...action.payload.errors], csvUploadStatus: false }

    case types.UPDATE_BULK_ITEM_CSV_ERRORS: {
      let errors = state.csvErrors.filter((str) => !str.includes(`row #${action.payload}:`))

      return { ...state, csvErrors: errors }
    }

    case types.FETCH_INVENTORY_GRAPH_DATA_SUCCESS:
      return action.flag === 'subcategory_stats'
        ? !state.overviewGraphFilters.subCategoriesGraph
          ? {
              ...state,
              graphData: { ...state.graphData, subCategoriesGraph: action.payload },
              overviewGraphFilters: { ...state.overviewGraphFilters, subCategoriesGraph: action.payload }
            }
          : { ...state, graphData: { ...state.graphData, subCategoriesGraph: action.payload } }
        : action.flag === 'category_location_stats'
          ? !state.overviewGraphFilters.statsGraph
            ? {
                ...state,
                graphData: { ...state.graphData, statsGraph: action.payload },
                overviewGraphFilters: { ...state.overviewGraphFilters, statsGraph: action.payload }
              }
            : { ...state, graphData: { ...state.graphData, statsGraph: action.payload } }
          : action.flag === 'category_value_stats'
            ? !state.overviewGraphFilters.assetsGraph
              ? {
                  ...state,
                  graphData: { ...state.graphData, assetsGraph: action.payload },
                  overviewGraphFilters: { ...state.overviewGraphFilters, assetsGraph: action.payload }
                }
              : { ...state, graphData: { ...state.graphData, assetsGraph: action.payload } }
            : !state.overviewGraphFilters.categoryGraph
              ? {
                  ...state,
                  graphData: { ...state.graphData, categoryGraph: action.payload },
                  overviewGraphFilters: { ...state.overviewGraphFilters, categoryGraph: action.payload }
                }
              : { ...state, graphData: { ...state.graphData, categoryGraph: action.payload } }

    default:
      return state
  }
}

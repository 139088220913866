import { toastr } from 'react-redux-toastr'

import { errorsAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'
import { HIDE_MODAL } from './modal'

export const createDepartmentSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_DEPARTMENT_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Department Created Successfully!')
}

export const createDepartmentFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_DEPARTMENT_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Create Department!')
}

export const fetchDepartmentsSuccess = (payload) => ({
  type: types.FETCH_DEPARTMENT_SUCCESS,
  payload
})

export const fetchDepartmentsFailure = (payload) => ({
  type: types.FETCH_DEPARTMENT_FAILURE,
  payload
})

export const fetchDepartmentsListSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_DEPARTMENTS_LIST_SUCCESS,
    payload
  })
}

export const fetchDepartmentsListFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_DEPARTMENTS_LIST_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch departments lists')
}

export const deleteDepartmentSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_DEPARTMENT_SUCCESS,
    payload
  })

  toastr.success('Department Deleted Successfully!')
}

export const deleteDepartmentFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_DEPARTMENT_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Delete Department!')
}

export const updateDepartmentSuccess = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: extraParams?.flag ? types.UPDATE_DEPARTMENT_CLEARANCE_DATA_SUCCESS : types.UPDATE_DEPARTMENT_SUCCESS,
    userId: extraParams?.userId,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Department Updated Successfully!')
}

export const updateDepartmentFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_DEPARTMENT_FAILURE,
    payload
  })
  dispatch(HIDE_MODAL)

  errorsAlert(payload, 'Failed to Update Department!')
}

export const fetchSelectDepartmentsSuccess = (payload) => ({
  type: types.FETCH_SELECT_DEPARTMENTS_SUCCESS,
  payload
})

export const fetchSelectDepartmentsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_SELECT_DEPARTMENTS_FAILURE,
    payload
  })

  toastr.error('Failed to fetch Departments!')
}

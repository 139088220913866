import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const ams = (state = initialState.ams, action) => {
  switch (action.type) {
    case types.FETCH_ATTENDANCE_SUCCESS:
      return { ...state, attendance: { ...action.payload } }

    case types.FETCH_ATTENDANCE_FAILURE:
      return state

    case types.MARK_ATTENDANCE_SUCCESS:
      return { ...state, attendance: { ...action.payload } }

    case types.MARK_ATTENDANCE_FAILURE:
      return state

    default:
      return state
  }
}

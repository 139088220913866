import { createTextMask } from 'redux-form-input-masks'

import { CNIC_LENGTH, CONTACT_NUMBER_LENGTH } from 'helpers/constants'

export const preserveCursorPosition = (values, event) => {
  if (event.length === CNIC_LENGTH && values.currentTarget.attributes.name.nodeValue === 'cnic') return
  else if (
    event.length === CONTACT_NUMBER_LENGTH &&
    values.currentTarget.attributes.name.nodeValue === 'contact_number'
  ) {
    window.requestAnimationFrame(() => {
      values.target.selectionStart = values.target.selectionEnd = event.length + 1
    })
  } else {
    let caret = values.target?.selectionStart
    const element = values.target

    if (values.target?.value[caret] === '-' && values.nativeEvent.inputType === 'insertText') caret += 1

    window.requestAnimationFrame(() => {
      element.selectionStart = element.selectionEnd = caret
    })
  }
}

export const cnicMask = createTextMask({
  pattern: '99999-9999999-9',
  placeholder: '_',
  guide: false
})

export const phoneMask = createTextMask({
  pattern: '9999-9999999',
  placeholder: '_',
  guide: false
})

export const timeMask = createTextMask({
  pattern: '99:99',
  placeholder: '_',
  guide: false,
  stripMask: false
})

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const yupSchemaValidator = (schema) => (formValues) => {
  try {
    schema.validateSync(formValues, { abortEarly: false })
    return {}
  } catch (errors) {
    let ret = errors.inner?.reduce(
      (errors, err) => ({
        ...errors,
        [err.path]: err.message
      }),
      {}
    )
    return ret
  }
}

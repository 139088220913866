//This file contains all configuration related to Application

//Specify the endpoint of auth for redux-token-auth
const BASE_PATH = `${import.meta.env.REACT_APP_HTTP_PROTOCOL_OVERRIDE || 'https'}://${import.meta.env.REACT_APP_BASE_API_PATH}`
const BASE_API_URL = `${BASE_PATH}/api/${import.meta.env.REACT_APP_API_VERSION_OVERRIDE || 'v1'}`
export const AUTH_URL = `${BASE_API_URL}/auth`
export const BASE_URL = `${BASE_API_URL}/`
export const GOOGLE_CALENDAR_API_KEY = `${import.meta.env.REACT_APP_GOOGLE_CALENDAR_API}`
export const BASE_AMS_URL = `${BASE_PATH}/ams/`
export const AMS_PORT = import.meta.env.REACT_APP_AMS_PORT

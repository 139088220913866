import * as types from 'actions/actionTypes'
import { totalItems } from 'helpers/applicationHelper'

import initialState from './initialState'

export const claim = (state = initialState.claims, action) => {
  let createdOrAttendedClaims, assignedClaims, allClaims, updatedClaim, index, createdClaim, claims, childClaims

  switch (action.type) {
    case types.SET_CLAIMS_PAGE_SIZE:
      return { ...state, pageSize: action.payload }

    case types.CREATE_CLAIM_SUCCESS:
      createdClaim = action.payload.claim ? action.payload.claim : undefined
      claims = createdClaim ? [...state.createdOrAttendedClaims, createdClaim] : [...state.createdOrAttendedClaims]

      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) + 1)

      return { ...state, createdOrAttendedClaims: claims }

    case types.CREATE_CLAIM_FAILURE:
      return state

    case types.FETCH_CLAIMS_SUCCESS:
      return { ...state, createdOrAttendedClaims: action.payload }

    case types.FETCH_CLAIMS_FAILURE:
      return state

    case types.FETCH_CLAIM_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributes: action.payload }

    case types.FETCH_CLAIM_CSV_ATTRIBUTES_FAILURE:
      return state

    case types.DELETE_CLAIM_SUCCESS:
      if (action.payload) {
        index = state.createdOrAttendedClaims.findIndex((claim) => claim.id === action.payload)
      }

      claims = [...state.createdOrAttendedClaims]
      claims.splice(index, 1)

      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)

      return { ...state, createdOrAttendedClaims: [...claims] }

    case types.DELETE_CLAIM_FAILURE:
      return state

    case types.SHOW_CLAIM_LOADER:
      return { ...state, claimLoader: true }

    case types.HIDE_CLAIM_LOADER:
      return { ...state, claimLoader: false }

    case types.SHOW_CLAIM_LIST_LOADER:
      return { ...state, claimListLoader: true }

    case types.HIDE_CLAIM_LIST_LOADER:
      return { ...state, claimListLoader: false }

    case types.FETCH_CLAIM_APPROVALS_SUCCESS:
      return { ...state, assignedClaims: action.payload }

    case types.FETCH_CLAIM_APPROVALS_FAILURE:
      return state

    case types.CLOSE_CLAIM_SUCCESS:
      createdOrAttendedClaims = [...state.createdOrAttendedClaims]
      allClaims = [...state.allClaims]

      updatedClaim = action.payload ? action.payload : action.payload ? action.payload : null
      index = state.createdOrAttendedClaims.findIndex((claim) => claim.id === updatedClaim.id)

      if (index !== -1) {
        createdOrAttendedClaims[index] = updatedClaim
      }
      index = state.allClaims.findIndex((claim) => claim.id === updatedClaim.id)

      if (index !== -1) {
        allClaims[index] = updatedClaim
      }
      return {
        ...state,
        createdOrAttendedClaims: createdOrAttendedClaims,
        allClaims: allClaims
      }

    case types.CANCEL_CLAIM_SUCCESS:
      createdOrAttendedClaims = [...state.createdOrAttendedClaims]
      allClaims = [...state.allClaims]

      index = state.createdOrAttendedClaims.findIndex((claim) => claim.id === action.payload.id)

      if (index !== -1) {
        createdOrAttendedClaims[index] = action.payload
      }
      index = state.allClaims.findIndex((claim) => claim.id === action.payload.id)

      if (index !== -1) {
        allClaims[index] = action.payload
      }
      return {
        ...state,
        createdOrAttendedClaims: createdOrAttendedClaims,
        allClaims: allClaims
      }

    case types.CANCEL_CLAIM_FAILURE:
      return state

    case types.UPDATE_CLAIM_SUCCESS:
      createdOrAttendedClaims = [...state.createdOrAttendedClaims]
      assignedClaims = [...state.assignedClaims]
      allClaims = [...state.allClaims]

      updatedClaim = action.payload.claim ? action.payload.claim : action.payload ? action.payload : null
      index = state.createdOrAttendedClaims.findIndex((claim) => claim.id === updatedClaim.id)

      if (index !== -1) {
        createdOrAttendedClaims[index] = updatedClaim
      }

      index = state.assignedClaims.findIndex((claim) => claim.id === updatedClaim.id)

      if (index !== -1) {
        if (updatedClaim.status === 'continued') sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
        assignedClaims[index] = updatedClaim
      }

      index = state.allClaims.findIndex((claim) => claim.id === updatedClaim.id)

      if (index !== -1) {
        allClaims[index] = updatedClaim
      }

      if (updatedClaim.parent_id) {
        childClaims = state.childClaims[updatedClaim.parent_id]
        index = childClaims ? childClaims.findIndex((claim) => claim.id === updatedClaim.id) : -1

        if (index !== -1) {
          childClaims[index] = updatedClaim
          state.childClaims[updatedClaim.parent_id] = [...childClaims]
        }
      }
      return {
        ...state,
        createdOrAttendedClaims: createdOrAttendedClaims,
        assignedClaims: assignedClaims,
        allClaims: allClaims,
        childClaims: updatedClaim.parent_id ? { ...state.childClaims } : state.childClaims
      }

    case types.UPDATE_CLAIM_FAILURE:
      return state

    case types.FETCH_ALL_CLAIMS_SUCCESS:
      return { ...state, allClaims: action.payload }

    case types.FETCH_ALL_CLAIMS_FAILURE:
      return state

    case types.FETCH_DETAILED_CLAIMS_SUCCESS:
      return { ...state, detailedClaims: action.payload }

    case types.FETCH_DETAILED_CLAIMS_FAILURE:
      return state

    case types.FETCH_CLAIMS_STATS_SUCCESS:
      return { ...state, claimsStats: action.payload }

    case types.FETCH_CLAIMS_STATS_FAILURE:
      return state

    case types.UPDATE_CLAIM_STATUS_SUCCESS:
      return state

    case types.UPDATE_CLAIM_STATUS_FAILURE:
      return state

    case types.FETCH_CLAIM_SUCCESS:
      return { ...state, claimDetail: { ...state.claimDetail, ...action.payload } }

    case types.FETCH_CLAIM_FAILURE:
      return state

    case types.FETCH_CHILD_CLAIMS_SUCCESS:
      state.childClaims[action.payload[0].parent_id] = action.payload
      return { ...state, childClaims: { ...state.childClaims } }

    case types.FETCH_CHILD_CLAIMS_FAILURE:
      return state

    case types.FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, redeemableClaims: action.payload }

    case types.FETCH_TIME_SLOTS_FAILURE:
      return state

    case types.FETCH_CLAIM_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload }

    case types.FETCH_CLAIM_CATEGORIES_FAILURE:
      return state

    case types.UPDATE_CLAIM_CATEGORY_SUCCESS:
      return { ...state, categories: action.payload }

    case types.UPDATE_CLAIM_CATEGORY_FAILURE:
      return state

    case types.VERIFY_REDEEMABLE_SLOT_SUCCESS:
      return { ...state, slotStatus: action.payload }

    case types.VERIFY_REDEEMABLE_SLOT_FAILURE:
      return state

    default:
      return state
  }
}

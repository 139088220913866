import { showQuestionLoader } from 'actions/question'

import request from './request'

export const newQuestion = (faq, successAction, failureAction) => {
  return request(
    'faqs',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ faq }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchQuestions =
  (search = '', departments = null, successAction, failureAction) =>
  (dispatch) => {
    dispatch(
      request(`faqs?query=${search}&departments=${JSON.stringify(departments)}`, {}, successAction, failureAction, true)
    )
    dispatch(showQuestionLoader)
  }

export const updateQuestion = (params, successAction, failureAction) => {
  return request(
    `faqs/${params.id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params.faq),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchQuestionById = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `faqs/${params.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const deleteQuestion = (id, successAction, failureAction) => {
  return request(
    `faqs/${id}`,
    {
      method: 'DELETE',
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

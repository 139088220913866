import { stringify } from 'qs'

import request from './request'

export const fetchPermissions = (allPermission = false, successAction, failureAction) => {
  return request(
    `permissions?${stringify({ all_permission: allPermission })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchPermissionsObject = (successAction, failureAction) => {
  return request(
    'permissions/get_permissions_obj',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

// ##############################
// // // Footer styles
// #############################
import { styled } from 'maple-storybook'

import { defaultFont, footerHeight } from 'variables/styles'

const StyledFooter = styled('div')(() => ({
  '&.footer': {
    minHeight: `${footerHeight}px`,
    borderTop: '1px solid #e7e7e7',
    display: 'flex',
    backgroundColor: '#f2f4f7',
    ...defaultFont
  },
  '& .container-style': {
    paddingLeft: '15px',
    paddingRight: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  '& .mapleLogo': {
    minHeight: '22px',
    width: '88px'
  },
  '& .maple-web-link': {
    color: '#667085',
    textDecoration: 'underline'
  },
  '& .globe-icon': {
    verticalAlign: '-webkit-baseline-middle',
    '-webkit-transform': 'translateY(12%)',
    transform: 'translateY(12%)'
  }
}))

export default StyledFooter

import authHeaders from 'api/authHeader'

import { DOMAIN } from './applicationHelper'

const AUTH_HEADERS = ['impersonate', 'access-token', 'client', 'expiry', 'token-type', 'uid', 'Authorization']

export const setAccessTokenFromFetchResponse = (response) => {
  let access_token = response.headers.get('access-token')
  if (access_token && access_token !== '') {
    localStorage.setItem('access-token', access_token)
    localStorage.setItem('client', response.headers.get('client'))
    localStorage.setItem('uid', response.headers.get('uid'))
  }
}

export function setImpersonateData(search_params) {
  AUTH_HEADERS.forEach((key) => {
    search_params.forEach((param) => {
      if (param.includes(key)) {
        let value = param.split('=')[1]
        if (key === 'uid') value = value.replace('%40', '@')
        else if (key === 'Authorization') value = value.replace('+', ' ')
        localStorage.setItem(key, value)
      }
    })
  })
}

export const API_WS_LINK = () => {
  const authData = authHeaders()
  const wsProtocol = import.meta.env.REACT_APP_WS_PROTOCOL_OVERRIDE || 'wss'
  const wsPath = import.meta.env.REACT_APP_WS_HOST_PATH_OVERRIDE || 'cable'
  return `${wsProtocol}://${import.meta.env.REACT_APP_BASE_API_PATH}/${wsPath}/?access-token=${authData['access-token']}&client=${authData.client}&uid=${authData.uid}&domain=${DOMAIN}`
}

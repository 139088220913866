import {
  CREATE_BUSINESS_UNITS_SUCCESS,
  CREATE_EMPLOYMENT_COMPANIES_FAILURE,
  CREATE_EMPLOYMENT_COMPANIES_SUCCESS,
  FETCH_BUSINESS_UNITS_SUCCESS,
  FETCH_CURRENT_COMPANY_FAILURE,
  FETCH_CURRENT_COMPANY_SUCCESS,
  FETCH_EMPLOYMENT_COMPANIES_FAILURE,
  FETCH_EMPLOYMENT_COMPANIES_SUCCESS,
  UPDATE_COMPANY_LOGO_SUCCESS
} from 'actions/actionTypes'

import initialState from './initialState'

export const experienceCompanies = (state = initialState.companies, action) => {
  switch (action.type) {
    case FETCH_EMPLOYMENT_COMPANIES_SUCCESS:
      if (action.payload.option) {
        return { ...state, experienceCompanies: [...state.experienceCompanies, action.payload.option] }
      } else {
        return { ...state, experienceCompanies: action.payload }
      }

    case FETCH_EMPLOYMENT_COMPANIES_FAILURE:
      return state

    case CREATE_EMPLOYMENT_COMPANIES_SUCCESS:
      return { ...state, experienceCompanies: [...state.experienceCompanies, action.payload] }

    case CREATE_EMPLOYMENT_COMPANIES_FAILURE:
      return state

    default:
      return state
  }
}

export const currentCompany = (state = initialState.currentCompany, action) => {
  switch (action.type) {
    case FETCH_CURRENT_COMPANY_SUCCESS:
      return { ...state, ...action.payload }

    case FETCH_CURRENT_COMPANY_FAILURE:
      return state

    case UPDATE_COMPANY_LOGO_SUCCESS:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

export const businessUnits = (state = initialState.businessUnits, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_UNITS_SUCCESS:
      return action.payload

    case CREATE_BUSINESS_UNITS_SUCCESS:
      return [...state, action.payload]

    default:
      return state
  }
}

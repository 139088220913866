import * as qs from 'qs'

import { showClaimListLoader, showClaimLoader } from 'actions/claims'

import { serialize } from './helpers'
import request from './request'

export const createClaim = (params, successAction, failureAction) => {
  return request(
    'claims',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ claim: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchClaims = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `claims?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
  dispatch(showClaimListLoader)
}

export const fetchChildClaims = (params, successAction, failureAction, callback) => (dispatch) => {
  let queryParams = {
    search_params: params
  }
  dispatch(
    request(
      `claims${params.tab === 'claimAdminPanel' ? '/search?' : '?'}${qs.stringify(queryParams, {
        arrayFormat: 'brackets'
      })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true,
      null,
      callback
    )
  )
}

export const fetchAllClaims = (params, successAction, failureAction, dispatch) => {
  if (!params.complete_data || params.csv_data) dispatch(showClaimListLoader)
  return request(
    `claims/search?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchClaimsStats = (params, successAction, failureAction) => {
  return request(
    `claims_stats?${qs.stringify(
      {
        search_params: params
      },
      { arrayFormat: 'brackets' }
    )}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchClaimCsvAttributes = (successAction, failureAction) =>
  request('claims/allowed_attributes', {}, successAction, failureAction, true)

export const fetchClaimCategories = (successAction, failureAction) =>
  request('claim_types/', {}, successAction, failureAction, true)

export const fetchTimeSlots = (successAction, failureAction) => (dispatch) => {
  dispatch(request('claim/redeemed_entries', {}, successAction, failureAction, true))
}

export const verifyRedeemableSlot = (params, successAction, failureAction, callback) => (dispatch) => {
  dispatch(
    request(
      `claim/redeemed_entries`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ slot: params }),
        submission: true
      },
      successAction,
      failureAction,
      true,
      null,
      callback
    )
  )
}

export const updateClaimCategory = (params, successAction, failureAction) =>
  request(
    'claim_types/',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ claim_types: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )

export const fetchClaimApprovals = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `claims/approvals?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )

  dispatch(showClaimListLoader)
}

export const updateClaim = (id, params, successAction, failureAction) => {
  return request(
    `claims/${id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ claim: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const closeClaim = (id, params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `claims/${id}/close_recurring_claim`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json'
        },
        body: serialize({ claim: params }),
        submission: true
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const fetchClaim =
  (id, successAction, failureAction, extraParams = {}) =>
  (dispatch, getState) => {
    getState().claims.claimDetail = {}
    dispatch(
      request(
        `claims/${id}?${qs.stringify(extraParams, { arrayFormat: 'brackets' })}`,
        {},
        successAction,
        failureAction,
        true
      )
    )
    dispatch(showClaimLoader)
  }

export const deleteClaim = (id, successAction, failureAction) =>
  request(
    `claims/${id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )

export const updateClaimStatus = (id, params, successAction, failureAction, flag, userId, extraParams = {}) => {
  return request(
    `claims/${id}/update_status?${qs.stringify(extraParams, { arrayFormat: 'brackets' })}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize(params),
      submission: true
    },
    successAction,
    failureAction,
    true,
    {
      flag,
      userId
    }
  )
}

export const updateClaimStatuses = (ids, params, successAction, failureAction) => {
  return request(
    `claims/update_statuses`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ ids: ids, ...params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const cancelClaim = (id, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `claims/${id}/cancel`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

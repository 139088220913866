import * as types from 'actions/actionTypes'
import { checkArrayEmpty } from 'helpers/applicationHelper'
import initialState from 'reducers/initialState'

export const department = (state = initialState, action) => {
  let departmentIndex, departments

  switch (action.type) {
    case types.CREATE_DEPARTMENT_SUCCESS:
      return { ...state, departments: [...state.departments, action.payload] }

    case types.CREATE_DEPARTMENT_FAILURE:
      return state

    case types.FETCH_DEPARTMENT_SUCCESS: {
      return checkArrayEmpty(state.departmentFilters)
        ? { ...state, departments: action.payload, departmentFilters: action.payload }
        : { ...state, departments: action.payload }
    }

    case types.FETCH_DEPARTMENT_FAILURE:
      return state

    case types.UPDATE_DEPARTMENT_SUCCESS:
      departmentIndex = state.departments.findIndex((dep) => dep.id === action.payload.id)

      departments = state.departments
      departments[departmentIndex] = action.payload

      return { ...state, departments: [...departments] }

    case types.UPDATE_DEPARTMENT_FAILURE:
      return state

    case types.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.filter((dept) => dept.id !== action.payload.id)
      }

    case types.DELETE_DEPARTMENT_FAILURE:
      return state

    case types.FETCH_SELECT_DEPARTMENTS_SUCCESS:
      return { ...state, selectDepartments: action.payload }

    case types.FETCH_SELECT_DEPARTMENTS_FAILURE:
      return state

    case types.FETCH_DEPARTMENTS_LIST_SUCCESS:
      return { ...state, departmentsList: action.payload }

    case types.FETCH_DEPARTMENTS_LIST_FAILURE:
      return state

    case types.BULK_UPDATE_TITLE_RANK_SUCCESS:
      action.payload.forEach((updatedTitle) => {
        state.departments.forEach((dept) => {
          dept.titles.forEach((title, index) => {
            if (title.id === updatedTitle.id) {
              dept.titles[index] = { ...title, title_rank: updatedTitle.title_rank }
            }
          })
        })
      })

      return { ...state, departments: [...state.departments] }

    default:
      return state
  }
}

import { useDispatch, useSelector } from 'react-redux'
import { useSelectUser as useSelectUserHook } from 'maple-storybook/utils'

import { fetchSearchUsersFailure, fetchSearchUsersSuccess } from 'actions/user'
import { fetchUsers } from 'api/user'

export const useSelectUser = () => {
  return useSelectUserHook(useDispatch, useSelector, fetchSearchUsersFailure, fetchSearchUsersSuccess, fetchUsers)
}

export default useSelectUser

import { Component } from 'react'
import { connect } from 'react-redux'
import { getFormSyncErrors } from 'redux-form'

import { disableFormSubmission, enableFormSubmission } from 'actions/formSubmission'
import { extractFormKeys, scrollIntoViewHelper } from 'helpers/applicationHelper'

export default function reduxFormTouchOnsubmit(WrappedComponent) {
  class ReduxFormTouchOnsubmit extends Component {
    submitFormAndTouchAll = (e) => {
      e.preventDefault()
      const { handleSubmit, disableFormSubmission, valid } = this.props
      if (valid) {
        disableFormSubmission()
        return handleSubmit()
      } else {
        const arr = [...extractFormKeys(this.props.formErrors)]
        this.props.touch(...arr)
        scrollIntoViewHelper(arr)
      }
    }

    render() {
      return <WrappedComponent {...this.props} submitFormAndTouchAll={this.submitFormAndTouchAll} />
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    submission: state.formSubmission.submission,
    formErrors: getFormSyncErrors(ownProps.form)(state)
  })

  const mapDispatchToProps = (dispatch) => ({
    disableFormSubmission: (_) => {
      dispatch(disableFormSubmission)
    },
    enableFormSubmission: (_) => {
      dispatch(enableFormSubmission)
    }
  })

  return connect(mapStateToProps, mapDispatchToProps)(ReduxFormTouchOnsubmit)
}

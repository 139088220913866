import * as Yup from 'yup'

export const schema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Question is required!')
    .min(10, 'Must be at least 10 characters')
    .max(250, 'Must be 250 characters or less'),
  answer: Yup.string()
    .required('Answer is required!')
    .test('min-length', 'Must be at least 10 characters', (value) => {
      const strippedValue = value.replace(/(<([^>]+)>|\n)/gi, '')
      return strippedValue.length >= 10
    })
})

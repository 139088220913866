import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const thirdPartyVendors = (state = initialState.thirdPartyVendors, action) => {
  switch (action.type) {
    case types.FETCH_THIRD_PARTY_VENDOR_SUCCESS:
      return { ...state, thirdPartyVendors: action.payload }

    default:
      return state
  }
}

import { toastr } from 'react-redux-toastr'

import { errorsAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'

export const createClaimTypeSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_CLAIM_TYPE_SUCCESS,
    payload: payload
  })

  toastr.success('Claim type created successfully!')
  dispatch(hideClaimTypeForm)
}

export const fetchClaimTypesSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_TYPES_SUCCESS,
    payload: payload
  })

  dispatch(hideClaimTypeLoader)
}

export const showClaimTypeLoader = {
  type: types.SHOW_CLAIM_TYPE_LOADER
}

export const hideClaimTypeLoader = {
  type: types.HIDE_CLAIM_TYPE_LOADER
}

export const showClaimTypeForm = {
  type: types.SHOW_CLAIM_TYPE_FORM
}

export const hideClaimTypeForm = {
  type: types.HIDE_CLAIM_TYPE_FORM
}

export const fetchClaimTypesFailure = (payload) => ({
  type: types.FETCH_CLAIM_TYPES_FAILURE,
  payload
})

export const createClaimTypeFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_CLAIM_TYPE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update Policy')
}

export const fetchClaimTypeSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_TYPE_SUCCESS,
    payload: payload
  })

  dispatch(hideClaimTypeLoader)
}

export const fetchClaimTypeFailure = (payload) => ({
  type: types.FETCH_CLAIM_TYPE_FAILURE,
  payload
})

export const updateClaimTypeSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CLAIM_TYPE_SUCCESS,
    payload: payload
  })

  if (payload.processing_data) {
    toastr.success('Please wait untill rollover job is processing')
  } else if (payload.status !== 'WARNING') {
    toastr.success('Claim type updated successfully!')
    dispatch(hideClaimTypeForm)
  }
}

export const updateClaimTypeFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CLAIM_TYPE_FAILURE,
    payload: payload
  })

  errorsAlert(payload, 'Failed to update Claim type')
}

export const fetchClaimTypesDetailsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_TYPES_DETAILS_SUCCESS,
    payload
  })

  dispatch(hideClaimTypeLoader)
}

export const fetchClaimTypesDetailsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_TYPES_DETAILS_FAILURE,
    payload
  })

  dispatch(hideClaimTypeLoader)
}

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Avatar } from 'maple-storybook'

import { avatarImageProps } from 'helpers/applicationHelper'

const Profile = ({ profile }) => {
  const navigate = useNavigate()

  return (
    <div className="user profile-image-click">
      {profile?.id && (
        <div
          className="profile-info profile-image-click"
          onClick={() => profile.fields_fill && navigate(`/profile/${profile.id}`)}>
          <Avatar
            name={`${profile.first_name} ${profile.last_name ?? ''}`}
            src={profile.thumbnail}
            size={'45'}
            round={'100px'}
            imgProps={avatarImageProps()}
            className="user-avatar profile-image-click"
          />

          <div className="user-info">
            <p className="header-person-name profile-image-click">{profile.first_name}</p>
            <p className="user-short-title profile-image-click">{profile.short_title}</p>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const userId = state.reduxTokenAuth.currentUser.attributes.id

  return {
    profile: state.users.allUserProfiles[userId]
  }
}

export default connect(mapStateToProps)(Profile)

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const feedback = (state = initialState.feedbacks, action) => {
  let feedbackIndex, feedbacks

  switch (action.type) {
    case types.SET_FEEDBACKS_TAB:
      return { ...state, tab: action.payload.tab }

    case types.CREATE_FEEDBACK_SUCCESS:
      return { ...state, allFeedbacks: [...state.allFeedbacks, action.payload] }

    case types.CREATE_FEEDBACK_FAILURE:
      return state

    case types.FETCH_FEEDBACKS_SUCCESS:
      return { ...state, allFeedbacks: action.payload, loader: false }

    case types.FETCH_FEEDBACKS_FAILURE:
      return state

    case types.DELETE_FEEDBACK_SUCCESS:
      feedbackIndex = state.allFeedbacks.findIndex((feedback) => feedback.id === action.payload.id)
      feedbacks = [...state.allFeedbacks]
      feedbacks.splice(feedbackIndex, 1)

      return { ...state, allFeedbacks: [...feedbacks] }

    case types.DELETE_FEEDBACK_FAILURE:
      return state

    default:
      return state
  }
}

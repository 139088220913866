import { isEmpty, last } from 'lodash'

import { checkArrayEmpty, sortValue } from './applicationHelper'
import { attributesIntoSelectOptions } from './settingsHelper'

export const checkPermission = (userPermissions = {}, group, entity, action, ignoreAdmin = false) => {
  if (checkArrayEmpty(userPermissions) || !Object.keys(userPermissions).length || !entity || !action) return 'none'

  if (!ignoreAdmin && userPermissions['admin']) return 'full'

  let permissionKey = `${group}__${entity}__${action}`
  let permissionVal = userPermissions.permissions
    ? userPermissions.permissions[permissionKey]
    : userPermissions[permissionKey]

  if (!permissionVal) return 'none'
  return permissionVal
}

export const formatDescriptionToOptions = (permissions) =>
  isEmpty(permissions)
    ? []
    : sortValue(Object.entries(permissions).map(([option, data]) => ({ value: option, label: data.description })))

export const formatPermissionsIntoOptions = (permissions) => {
  if (permissions) return sortValue(attributesIntoSelectOptions(Object.keys(permissions)))
}

export const getGenericPermissionsByPermissions = (permissions, genericValue) =>
  isEmpty(permissions) ? {} : permissions[genericValue]?.permissions

export const getActionsByPermissions = (permissions, genericPermission, actionValue) =>
  getGenericPermissionsByPermissions(permissions, genericPermission)[actionValue]?.actions

export const getFormattedOptionsByPermissions = (permissions, genericPermission, actionValue, optionValue) =>
  isEmpty(permissions)
    ? []
    : attributesIntoSelectOptions(
        getActionsByPermissions(permissions, genericPermission, actionValue)[optionValue]?.options
      )

export const extractDefaultPermissions = (allPermissions) => {
  let defaultPermissions = {}
  for (let group in allPermissions) {
    for (let entity in allPermissions[group].permissions) {
      for (let action in allPermissions[group].permissions[entity].actions) {
        // Assumes back-end sends sorted array
        const leastPermission =
          action === 'accessible_attributes'
            ? []
            : last(allPermissions[group].permissions[entity].actions[action].options)
        defaultPermissions[`${group}__${entity}__${action}`] = leastPermission
      }
    }
  }
  return defaultPermissions
}

import * as types from 'actions/actionTypes'
import initialState from 'reducers/initialState'

export const documentSettings = (state = initialState.documentSettings, action) => {
  switch (action.type) {
    case types.FETCH_DOCUMENT_SETTINGS_SUCCESS:
      return { ...state, settings: action.payload }

    case types.UPDATE_DOCUMENT_SETTING_SUCCESS:
      return {
        ...state,
        settings: state.settings.map((setting) =>
          setting.id === action.payload.id ? { ...setting, enable: action.payload.enable } : setting
        )
      }

    case types.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        settings: state.settings.map((setting) =>
          setting.country.id === action.payload.id
            ? { ...setting, country: { ...setting.country, enable: action.payload.enable } }
            : setting
        )
      }

    default:
      return state
  }
}

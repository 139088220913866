import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const subscription = (state = initialState.subscriptions, action) => {
  let liveCounterData, index, exists

  switch (action.type) {
    case types.FETCH_REDEEMED_ENTRY_DATA_SUCCESS:
      return {
        ...state,
        redeemedEntryData: action.payload.redeemed_entries,
        activeSubscription: action.payload.active_subscription,
        buffer: action.payload.buffer
      }

    case types.FETCH_REDEEMED_ENTRY_DATA_FAILURE:
      return state

    case types.FETCH_ENTRY_COUNT_SUCCESS:
      return {
        ...state,
        liveCounter: action.payload
      }

    case types.FETCH_ENTRY_COUNT_FAILURE:
      return state

    case types.UPDATE_REDEEMED_ENTRY_SUCCESS:
      exists = state.redeemedEntryData.some((entry) => entry.id === action.payload.id)
      return exists ? state : { ...state, redeemedEntryData: [action.payload, ...state.redeemedEntryData] }

    case types.UPDATE_REDEEMED_ENTRY_FAILURE:
      return state

    case types.QR_SCAN_SUCCESS:
      return { ...state, redeemedEntryData: [action.payload, ...state.redeemedEntryData] }

    case types.FETCH_LIVE_COUNTER_SUCCESS:
      liveCounterData = state.liveCounter
      index = liveCounterData.findIndex((e) => e.claim_type.id === action.payload.live_counter_data.claim_type.id)

      return {
        ...state,
        liveCounter: [
          ...liveCounterData.slice(0, Math.max(index, 0)),
          action.payload.live_counter_data,
          ...liveCounterData.slice(index + 1, liveCounterData.length)
        ]
      }

    case types.GET_REDEEMABLE_TIME_SLOTS_SUCCESS:
      return { ...state, redeemableTimeSlots: action.payload }

    default:
      return state
  }
}

import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import { fetchClaimTypesDetailsFailure, fetchClaimTypesDetailsSuccess } from 'actions/claim_type'
import { createClaimFailure, createClaimSuccess } from 'actions/claims'
import { fetchClaimTypesDetails } from 'api/claim_type'
import { createClaim } from 'api/claims'
import { dateTimeToDate } from 'helpers/datesHelper'

import ClaimForm from './ClaimForm'

/* eslint-disable react/no-string-refs */
class NewClaim extends Component {
  constructor(props) {
    super(props)
    this.newClaimRef = createRef()

    this.state = {
      startDate: '',
      claimTypeId: '',
      threadId: ''
    }
  }

  handleCreateClaimSubmit = (values) => {
    const newValues = cloneDeep(values)
    //TODO: Need code cleanup, will do this soon
    const projectBindable = newValues.project
    const project_binding_attributes = newValues.thread
      ? { project_bindable_id: newValues.thread, project_bindable_type: 'ProjectPseudoProfile' }
      : { project_bindable_id: projectBindable, project_bindable_type: 'Project' }

    const claim = {
      start_date: dateTimeToDate(newValues.start_date),
      end_date: values.is_of_recurring_type ? '' : dateTimeToDate(newValues?.end_date || newValues.start_date),
      recurring: newValues.recurring,
      amount: newValues.amount,
      adjusted_amount: newValues.amount,
      description: newValues.description,
      claim_type_id: newValues?.claim_type,
      claim_users_attributes: [{ user_id: this.props.userId }].concat(
        newValues.attendees?.map((id) => ({ user_id: id })) || []
      )
    }

    if (projectBindable) {
      claim.project_claim_binding_attributes = (projectBindable && project_binding_attributes) || {}
    }

    if (newValues.image) {
      claim.attachments_attributes = []
      newValues.image.forEach((image) => {
        claim.attachments_attributes.push({ file: image })
      })
      delete newValues.image
    }

    this.newClaimRef.current.firstElementChild.style.filter = 'blur(5px)'
    this.props.createClaim(claim, (data) => {
      let ret = createClaimFailure(data)
      this.newClaimRef.current.firstElementChild.style.filter = ''
      return ret
    })
  }

  handleCtChange = (selectedValue) => {
    this.setState({ startDate: selectedValue.date, claimTypeId: selectedValue.id, threadId: selectedValue?.threadId })
    this.props.fetchClaimTypesDetails(selectedValue.id, selectedValue.date, selectedValue?.thread_id)
  }

  render() {
    return (
      <span className="new-expense" ref={this.newClaimRef}>
        <ClaimForm
          onSubmit={this.handleCreateClaimSubmit}
          confirmClaimCreation={this.confirmClaimCreation}
          handleCtChange={this.handleCtChange}
          initialValues={{
            start_date: this.state.startDate,
            claim_type: this.state.claimTypeId
          }}
        />
      </span>
    )
  }
}

/* eslint-enable react/no-string-refs */
const mapStateToProps = (state) => {
  let userId = state.reduxTokenAuth.currentUser.attributes.id

  return {
    userId: userId
  }
}

const mapDispatchToProps = (dispatch) => ({
  createClaim: (params, callback) => {
    dispatch(createClaim(params, createClaimSuccess, callback))
  },
  fetchClaimTypesDetails: (id, date, thread_id) => {
    dispatch(fetchClaimTypesDetails(id, date, fetchClaimTypesDetailsSuccess, fetchClaimTypesDetailsFailure, thread_id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(NewClaim)

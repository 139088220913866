import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const dynamicForms = (state = initialState.settings, action) => {
  let dynamicFormIndex, dynamicForms
  switch (action.type) {
    case types.CREATE_DYNAMIC_FORM_SUCCESS:
      return { ...state, dynamicForms: [...state.dynamicForms, action.payload] }

    case types.CREATE_DYNAMIC_FORM_FAILURE:
      return state

    case types.FETCH_DYNAMIC_FORMS_SUCCESS:
      return { ...state, dynamicForms: action.payload, dynamicFormsLoader: false }

    case types.FETCH_DYNAMIC_FORMS_FAILURE:
      return state

    case types.UPDATE_DYNAMIC_FORM_SUCCESS:
      dynamicFormIndex = state.dynamicForms.findIndex((form) => form.id === action.payload.replaced_by_id)

      dynamicForms = [...state.dynamicForms]
      dynamicForms[dynamicFormIndex] = action.payload

      return { ...state, dynamicForms: [...dynamicForms] }

    case types.UPDATE_DYNAMIC_FORM_FAILURE:
      return state

    case types.DELETE_DYNAMIC_FORM_SUCCESS:
      if (action.payload) {
        dynamicFormIndex = state.dynamicForms.findIndex((form) => form.id === action.payload.id)
      }

      dynamicForms = [...state.dynamicForms]
      dynamicForms.splice(dynamicFormIndex, 1)

      return { ...state, dynamicForms: [...dynamicForms] }

    case types.DELETE_DYNAMIC_FORM_FAILURE:
      return state

    case types.FETCH_DYNAMIC_FORM_SUCCESS:
      return { ...state, dynamicForm: action.payload }

    case types.FETCH_DYNAMIC_FORM_FAILURE:
      return state

    case types.SHOW_DYNAMIC_FORM_LOADER:
      return { ...state, dynamicFormsLoader: true }

    case types.HIDE_DYNAMIC_FORM_LOADER:
      return { ...state, dynamicFormsLoader: false }

    case types.UPDATE_DRAFT_DYNAMIC_FORM:
      return { ...state, dynamicFormDraft: action.payload }

    case types.FETCH_DYNAMIC_FORM_CSV_SUCCESS:
      return { ...state, dynamicFormCSV: action.payload }

    case types.FETCH_DYNAMIC_FORM_RESPONSE_SUCCESS:
      return {
        ...state,
        dynamicFormResponse: {
          ...state.dynamicFormResponse,
          [action.flag === 'respondents' ? 'users' : 'responses']: action.payload
        }
      }

    case types.UPDATE_DYNAMIC_FORM_RESPONSE_SUCCESS:
      return { ...state, dynamicFormStatsUpdatedAt: action.payload.updated_at ?? action.payload.last_updated_at }

    default:
      return state
  }
}

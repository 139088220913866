import * as types from './actionTypes'

export const fetchPermissionSuccess = (payload) => ({
  type: types.FETCH_PERMISSION_SUCCESS,
  payload
})

export const fetchPermissionFailure = (payload) => ({
  type: types.FETCH_PERMISSION_FAILURE,
  payload
})

export const fetchPermissionsObjectSuccess = (payload) => ({
  type: types.FETCH_PERMISSIONS_OBJECT_SUCCESS,
  payload
})

export const fetchPermissionsObjectFailure = (payload) => ({
  type: types.FETCH_PERMISSIONS_OBJECT_FAILURE,
  payload
})

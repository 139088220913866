import { DOMAIN } from 'helpers/applicationHelper'

import { enableFormSubmission } from '../actions/formSubmission'
import { BASE_AMS_URL, BASE_URL } from '../config/apiConfig'
import { setAccessTokenFromFetchResponse } from '../helpers/authHelper'

import authHeaders from './authHeader'

const request = (
  path,
  opts = {},
  successAction,
  failureAction,
  auth = false,
  extraParams = null,
  callback = null,
  customSessionStorage,
  ams = false
) => {
  return (dispatch) => {
    if (auth) {
      opts.headers = {
        ...opts.headers,
        ...authHeaders(),
        'MAPLE-CURRENT-COMPANY': DOMAIN
      }
    }
    const currentUrl = new URL(window.location)
    const loginToken = currentUrl.searchParams.get('login_token')
    const url = new URL(`${ams ? BASE_AMS_URL : BASE_URL}${path}`)
    if (loginToken) {
      url.searchParams.append('login_token', loginToken)
    }
    fetch(url.href, opts)
      .then((res) => {
        setAccessTokenFromFetchResponse(res)
        if (!res.ok) {
          res.json().then((errorResponse) => {
            dispatch(failureAction(errorResponse, extraParams))

            // eslint-disable-next-line
            if (res.status == 401 && window.location.pathname !== '/login') {
              window.location = '/login'
            }
          })
        } else {
          if (path.includes('page=') && res.headers.get('total')) {
            sessionStorage.setItem('totalItems', res.headers.get('total'))
            customSessionStorage && sessionStorage.setItem(customSessionStorage, res.headers.get('total'))
          }
          res.json().then((res) => {
            dispatch(successAction(res, extraParams))
          })
        }
        callback && callback()
        opts.submission && dispatch(enableFormSubmission)
      })
      .catch((error) => {
        opts.submission && dispatch(enableFormSubmission)
        dispatch(failureAction(error, extraParams))
        callback && callback()
      })
  }
}

export default request

import { toastr } from 'react-redux-toastr'
import { reset } from 'redux-form'

import { errorsAlert } from 'helpers/applicationHelper.jsx'
import { getAttributesForTeamManagement } from 'helpers/userHelper'

import * as types from './actionTypes'
import { enableFormSubmission } from './formSubmission'
import { hideLeaveLoader } from './leave'
import { HIDE_MODAL } from './modal'

export const fetchUsersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USERS_SUCCESS,
    payload: payload
  })
  dispatch(hideUserLoader)
}

export const fetchVerificationUsersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_VERIFICATION_USERS_SUCCESS,
    payload: payload
  })
  dispatch(hideUserLoader)
}

export const userBulkVerificationSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.USER_BULK_VERIFICATION_USERS_SUCCESS,
    payload: payload
  })
  toastr.success(payload.message)
}

export const userBulkVerificationFailure = (payload) => {
  errorsAlert(payload)
}

export const fetchOnboardingUsersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ONBOARDING_USERS_SUCCESS,
    payload: payload
  })
  dispatch(hideUserLoader)
}

export const fetchCreationLogsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CREATION_LOGS_SUCCESS,
    payload: payload
  })
}

export const fetchCreationLogsFailure = (payload) => () => {
  errorsAlert(payload, 'Failed to load logs!')
}

export const editOnboardingUsersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.EDIT_ONBOARDING_USERS_SUCCESS,
    payload: payload
  })
}

export const fetchSearchUsersSuccess = (payload, filtersHash) => (dispatch) => {
  dispatch({
    type: types.FETCH_SEARCH_USERS_SUCCESS,
    payload: {
      key: filtersHash,
      users: payload
    }
  })
}

export const fetchUsersFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USERS_FAILURE,
    payload: payload
  })
  errorsAlert(payload, 'Failed to load users!')
}

export const fetchDepartmentUsersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_DEPARTMENT_USERS_SUCCESS,
    payload: payload
  })
  dispatch(hideUserLoader)
}

export const fetchDepartmentUsersFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_DEPARTMENT_USERS_FAILURE,
    payload: payload
  })
  errorsAlert(payload, 'Failed to load users!')
}

export const fetchSearchUsersFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_SEARCH_USERS_FAILURE,
    payload: payload
  })
  errorsAlert(payload, 'Failed to search users!')
}

export const resendInviteFailure = (payload) => {
  errorsAlert(payload, 'Failed to invite user!')
}

export const resendInviteSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.USERS_INVITE_SUCCESS,
    payload: payload
  })
  toastr.success('User(s) Invited Successfully!')
}

export const editUserSuccess =
  (payload, customMessage = null, flag) =>
  (dispatch) => {
    dispatch({
      type: types.EDIT_USER_SUCCESS,
      payload: flag === 'verified' ? { ...payload, flag: flag } : payload
    })
    dispatch(HIDE_MODAL)

    dispatch({
      type: types.UPDATE_TEAM_MEMBERS_SUCCESS,
      payload: getAttributesForTeamManagement(payload)
    })

    if (customMessage) {
      setTimeout(() => {
        toastr.success(customMessage)
      }, 3000)
    } else toastr.success('Changes Saved Successfully!')
  }

export const editVerificationStatusSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.VERIFICATION_STATUS_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)
  toastr.success('Verification Status Changed Successfully!')
}

export const editUserFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.EDIT_USER_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to save changes to User!')
}

export const updateRawUserSuccess = (payload, formType, currentUserId) => (dispatch) => {
  dispatch({
    type: types.UPDATE_RAW_USER_SUCCESS,
    payload: payload,
    formType: formType,
    currentUserId: currentUserId
  })
}

export const deleteUserSuccess = (payload, flag) => {
  return (dispatch) => {
    dispatch({
      type: flag === 'on-boarding-tab' ? types.EDIT_ONBOARDING_USERS_SUCCESS : types.DEACTIVATE_USER_SUCCESS,
      payload: flag ? { ...payload.user, flag: flag, inactiveUser: true } : payload.user
    })
    dispatch(HIDE_MODAL)

    toastr.success(payload.message)
  }
}

export const deleteUserFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DEACTIVATE_USER_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to deactivate user!')
}

export const getProfileSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.GET_PROFILE_SUCCESS,
    payload
  })
  dispatch({
    type: types.RESET_PROFILE_UPDATED,
    payload
  })
}

export const getProfileFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.GET_PROFILE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to retreive user')
}

export const resetProfileUpdated = (payload) => ({
  type: types.RESET_PROFILE_UPDATED,
  payload
})

export const updateProfileSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROFILE_SUCCESS,
    payload
  })

  toastr.success(payload.errors || 'Profile Updated Successfully!')
}

export const updateProfileSilentSuccess = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: extraParams.is_offboarding ? types.UPDATE_OFFBOARDING_USER_SETTINGS_SUCCESS : types.UPDATE_PROFILE_SUCCESS,
    payload
  })
}

export const updateProfileFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROFILE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed To Update Profile!')
}

export const createUserSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_USER_SUCCESS,
    payload
  })
  dispatch(reset('user_form'))

  toastr.success('User Invited Successfully!', { className: 'pt-dialog-box' })
}

export const createUserFailure = (payload, flag) => (dispatch) => {
  dispatch({
    type: types.CREATE_USER_FAILURE,
    payload
  })
  if (flag) {
    toastr.error(payload.errors, {
      timeOut: 0,
      tapToDismiss: false,
      progressBar: false,
      removeOnHoverTimeOut: 999999999
    })
  } else {
    errorsAlert(payload, 'Failed To Create Profile!')
  }
}

export const createSupportUserFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_USER_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed To Update Profile!')
}

export const clearUserCreateForm = () => ({
  type: types.CLEAR_CREATE_USER_FORM_ERRORS
})

export const resetPasswordSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.RESET_PASSWORD_SUCCESS,
    payload
  })
  dispatch(reset('change_password_form'))

  toastr.success(payload.message || 'Password updated successfully')
}

export const resetPasswordFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.RESET_PASSWORD_FAILURE,
    payload
  })

  toastr.error(payload.errors ? payload.errors.full_messages[0] : 'Failed to update password')
}

export const clearResetPasswordStatus = () => ({
  type: types.CLEAR_RESET_PASSWORD_STATUS
})

export const activateUserSuccess = (payload, flag) => (dispatch) => {
  dispatch({
    type: flag === 'on-boarding-tab' ? types.EDIT_ONBOARDING_USERS_SUCCESS : types.ACTIVATE_USER_SUCCESS,
    payload: flag ? { ...payload, flag: flag, activeUser: true } : payload
  })

  toastr.success('User Activated Successfully!')
}

export const activateUserFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.ACTIVATE_USER_FAILURE,
    payload
  })

  errorsAlert(payload?.message ?? 'Failed to activate user!')
}

export const fetchDashboardSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_DASHBOARD_SUCCESS,
    payload
  })

  dispatch(hideLeaveLoader)
  dispatch(hideUserLoader)
}

export const fetchDashboardFailure = (payload) => ({
  type: types.FETCH_DASHBOARD_FAILURE,
  payload
})

export const fetchTeamAnalyticsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TEAM_ANALYTICS_SUCCESS,
    payload
  })

  dispatch(hideLeaveLoader)
  dispatch(hideUserLoader)
}

export const fetchTeamAnalyticsFailure = (payload) => ({
  type: types.FETCH_TEAM_ANALYTICS_FAILURE,
  payload
})

export const fetchUserClearanceDataSuccess = (payload) => ({
  type: types.FETCH_USER_CLEARANCE_DATA_SUCCESS,
  payload: payload
})

export const fetchUserClearanceDataFailure = (payload) => ({
  type: types.FETCH_USER_CLEARANCE_DATA_FAILURE,
  payload
})

export const resetUserClearanceData = (payload) => ({
  type: types.RESET_USER_CLEARANCE_DATA,
  payload
})

export const sendResetPasswordEmailSuccess = (payload) => ({
  type: types.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  payload
})

export const fetchOrganoChartSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ORGANO_CHART_SUCCESS,
    payload
  })

  dispatch(hideOrganoChartLoader)
}

export const sendResetPasswordEmailFailure = (payload) => ({
  type: types.SEND_RESET_PASSWORD_EMAIL_FAILURE,
  payload
})

export const fetchOrganoChartFailure = (payload) => ({
  type: types.FETCH_ORGANO_CHART_FAILURE,
  payload
})

export const resendWelcomeEmailSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.RESEND_WELCOME_EMAIL_SUCCESS,
    payload
  })

  toastr.success('Email Sent Successfully!', { className: 'pt-dialog-box' })
}

export const resendWelcomeEmailFailure = (payload) => ({
  type: types.RESEND_WELCOME_EMAIL_FAILURE,
  payload
})

export const fieldsFilledSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FIELDS_FILLED_SUCCESS,
    payload
  })
  dispatch(HIDE_MODAL)

  toastr.success('Profile Updated Successfully!')
}

export const fieldsFilledFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FIELDS_FILLED_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to save changes to User!')
}

export const fetchSelectUsersSuccess = (payload) => ({
  type: types.FETCH_SELECT_USERS_SUCCESS,
  payload: payload
})

export const fetchSelectUsersFailure = (payload) => ({
  type: types.FETCH_SELECT_USERS_FAILURE,
  payload
})

export const fetchBlockedUsersSuccess = (payload) => ({
  type: types.FETCH_BLOCKED_USERS_SUCCESS,
  payload
})

export const fetchBlockedUsersFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_BLOCKED_USERS_FAILURE,
    payload
  })

  toastr.error('Failed to fetch Blocked Users!')
}

export const fetchOffboardingUsersSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_OFFBOARDING_USERS_SUCCESS,
    payload: payload
  })
  dispatch(hideUserLoader)
}

export const updateOffboardingUserTasksSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_OFFBOARDING_USER_TASKS_SUCCESS,
    payload: payload
  })
}

export const fetchUserSubordinatesSuccess = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_SUBORDINATES_SUCCESS,
    flag: extraParams?.flag,
    payload: payload
  })
}

export const fetchUserSubordinatesFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_USER_SUBORDINATES_FAILURE,
    payload
  })

  toastr.error('Failed to Block User!')
}

export const handleSelectedDates = (payload) => ({
  type: types.HANDLE_DATE_RANGE_SELECTED_DATES,
  payload
})

export const fetchHeaderDataSuccess = (payload) => ({
  type: types.FETCH_HEADER_DATA_SUCCESS,
  payload
})

export const fetchHeaderDataFailure = (payload) => ({
  type: types.FETCH_HEADER_DATA_FAILURE,
  payload
})

export const fetchUsersCsvAttributesSuccess = (payload) => ({
  type: types.FETCH_USERS_CSV_ATTRIBUTES_SUCCESS,
  payload
})

export const fetchUsersCsvAttributesFailure = (payload) => ({
  type: types.FETCH_USERS_CSV_ATTRIBUTES_FAILURE,
  payload
})

export const fetchEmploymentTypesSuccess = (payload) => ({
  type: types.FETCH_EMPLOYMENT_TYPES_SUCCESS,
  payload
})

export const fetchEmploymentTypesFailure = (payload) => ({
  type: types.FETCH_EMPLOYMENT_TYPES_FAILURE,
  payload
})

export const fetchUsersCsvDataSuccess = (payload) => {
  toastr.success(payload.message)
}

export const fetchUsersCsvDataFailure = (payload) => {
  errorsAlert(payload, 'Failed to fetch csv data')
}

export const fetchDayFeedbackSuccess = (payload) => ({
  type: types.FETCH_DAY_FEEDBACK_SUCCESS,
  payload
})

export const fetchDayFeedbackFailure = (payload) => ({
  type: types.FETCH_DAY_FEEDBACK_FAILURE,
  payload
})

export const createDayFeedbackSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_DAY_FEEDBACK_SUCCESS,
    payload
  })
  dispatch(enableFormSubmission)

  toastr.success('Thank you for your feedback!')
}

export const createDayFeedbackFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_DAY_FEEDBACK_FAILURE,
    payload
  })
  dispatch(enableFormSubmission)

  errorsAlert(payload, 'Feedback could not be submitted!')
}

export const uploadBulkUserCsvSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_USER_SUCCESS,
    payload
  })
  dispatch(hideUserLoader)
}

export const updateBulkUserCsvErrors = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_BULK_USER_CSV_ERRORS,
    payload
  })
}

export const uploadBulkUserCsvFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPLOAD_BULK_USER_CSV_FAILURE,
    payload
  })
  dispatch(hideUserLoader)
}

export const showUserLoader = {
  type: types.SHOW_USER_LOADER
}

export const hideUserLoader = {
  type: types.HIDE_USER_LOADER
}

export const showOrganoChartLoader = {
  type: types.SHOW_ORGANO_CHART_LOADER
}

export const hideOrganoChartLoader = {
  type: types.HIDE_ORGANO_CHART_LOADER
}

export const fetchBulkUserCsvAttributesSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_BULK_USERS_CSV_ATTRIBUTES_SUCCESS,
    payload
  })
  dispatch(hideUserLoader)
}

export const fetchBulkUserCsvAttributesFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_BULK_USERS_CSV_ATTRIBUTES_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to fetch User Attributes')
}

export const getOnboardingUserProfileSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.GET_PROFILE_SUCCESS,
    payload
  })
}

export const replaceDiscardTicketApprovalSuccess = (payload, extraParams) => (dispatch) => {
  let discarded_categories = null
  let total_categories_approvals = null

  if (payload.response) {
    discarded_categories = payload.response.discarded_categories
    total_categories_approvals = payload.response.total_categories_approvals
  }

  dispatch({
    type: types.REPLACE_DISCARD_TICKET_APPROVAL_SUCCESS,
    userId: extraParams?.userId,
    payload
  })
  toastr.success(
    discarded_categories > 0
      ? `${discarded_categories}/${total_categories_approvals} tickets have been successfully updated. However, you must replace approver for remaining tickets/categories`
      : 'Tickets updated Successfully'
  )
  dispatch(HIDE_MODAL)
}

export const replaceDiscardTicketApprovalFailure = (payload) => {
  errorsAlert(payload, 'Failed To Update Ticket Approvals!')
}

export const fetchSelectReportingUsersSuccess = (payload) => ({
  type: types.FETCH_SELECT_REPORTING_USERS_SUCCESS,
  payload
})

export const rejectOffboardingUserEncashment = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: types.ENCASH_OFFBOARDING_USER_LEAVES_SUCCESS,
    payload: payload,
    userId: extraParams.userId,
    leaveTypeId: extraParams.leaveTypeId
  })

  toastr.success('User leaves encashment rejected successfully')
}

export const encashOffboardingUserLeaves = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: types.ENCASH_OFFBOARDING_USER_LEAVES_SUCCESS,
    payload: payload,
    userId: extraParams.userId,
    leaveTypeId: extraParams.leaveTypeId
  })

  toastr.success('User leaves encashed successfully')
}

export const fetchExitReasonsSuccess = (payload) => ({
  type: types.FETCH_USER_EXIT_REASONS_SUCCESS,
  payload
})

export const fetchExitReasonsFailure = (payload) => errorsAlert(payload, 'Failed to fetch Exit Reasons!')

export const createExitReasonSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_USER_EXIT_REASON_SUCCESS,
    payload
  })

  toastr.success('Exit Reason Added Successfully!')
}

export const createExitReasonFailure = (payload) => errorsAlert(payload, 'Failed to create Exit Reason')

/* eslint-disable */
String.prototype.titleize = function () {
  return this.charAt(0).toUpperCase() + this.substr(1)
}

String.prototype.humanize = function () {
  return this.replace(/_/g, ' ')
}

String.prototype.isFull = function () {
  return this.valueOf() === 'full'
}

String.prototype.isSubordinate = function () {
  return this.valueOf() === 'subordinates'
}

String.prototype.isViewFull = function () {
  return this.valueOf() === 'view_full'
}

String.prototype.isView = function () {
  return this.valueOf() === 'view'
}

String.prototype.isViewRestricted = function () {
  return this.valueOf() === 'view_restricted'
}

String.prototype.isEditRestricted = function () {
  return this.valueOf() === 'edit_restricted'
}

String.prototype.hasAdminView = function () {
  return this.valueOf() === 'admin'
}

String.prototype.isDepartmentalView = function () {
  return this.valueOf() === 'departmental_view' || this.valueOf() === 'view_department'
}

String.prototype.isFullOrViewFull = function () {
  return this.valueOf() === 'full' || this.valueOf() === 'view_full'
}

String.prototype.isRestrictedDepartment = function () {
  return this.valueOf() === 'restricted_department'
}

String.prototype.isRestrictedLocation = function () {
  return this.valueOf() === 'restricted_location'
}

String.prototype.isRestrictedLocationDepartment = function () {
  return this.valueOf() === 'restricted_location_department'
}

String.prototype.isViewable = function () {
  let val = this.valueOf()
  return (
    val.isViewFull() ||
    val.isViewRestricted() ||
    val.isFull() ||
    val.isView() ||
    val.isDepartmentalView() ||
    val.isRestrictedDepartment() ||
    val.isRestricted() ||
    val.isDepartmentOnly() ||
    val.isViewOnly() ||
    val.isManageFull() ||
    val.isManageRestricted() ||
    val.isTicketModerator()
  )
}

String.prototype.isDepartmentOnly = function () {
  return this.valueOf() === 'department_only'
}

String.prototype.isViewOnly = function () {
  return this.valueOf() === 'view_only'
}

String.prototype.isDynamicApprovalReporting = function () {
  return this.valueOf() === 'view_approval_based_reporting'
}

String.prototype.isNone = function () {
  return this.valueOf() === 'none'
}

String.prototype.isRestricted = function () {
  return this.valueOf() === 'restricted'
}

String.prototype.isManageFull = function () {
  return this.valueOf() === 'manage_full'
}

String.prototype.isManageRestricted = function () {
  return this.valueOf() === 'manage_restricted'
}

String.prototype.isTicketModerator = function () {
  return this.valueOf() === 'ticket_moderator'
}

String.prototype.isMyReports = function () {
  return this.valueOf() === 'my_reports'
}

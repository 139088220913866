export const validateDayFeedbackForm = (values) => {
  const errors = {}

  const requiredFields = ['rating']

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'This field is required!'
    }
  })

  return errors
}

const CustomInputFile = (onFileChange) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*, .pdf, .xls, .docx, .xlsx')
  input.click()

  input.onchange = async () => onFileChange(input.files)
}

export default CustomInputFile

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const category = (state = initialState.categories, action) => {
  let categoryIndex,
    subcategoryIndex,
    categories = null
  let updatedCategories

  switch (action.type) {
    case types.CREATE_CATEGORY_SUCCESS:
      if (action.payload.parent_category_id) {
        const parentCategoryIndex = state.categories.findIndex(
          (category) => category.id === action.payload.parent_category_id
        )
        categories = state.categories
        categories[parentCategoryIndex].sub_categories_count += 1
        return { ...state, categories: [...categories] }
      } else {
        return { ...state, categories: [...state.categories, action.payload] }
      }

    case types.CREATE_CATEGORY_FAILURE:
      return state

    case types.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload }

    case types.FETCH_CATEGORIES_FAILURE:
      return state

    case types.EDIT_CATEGORY_SUCCESS:
      categories = state.categories
      categoryIndex = state.categories.findIndex((category) => category.id === action.payload.id)
      categories[categoryIndex] = action.payload

      return { ...state, categories: [...categories] }

    case types.EDIT_CATEGORY_FAILURE:
      return state

    case types.CATEGORY_BULK_UPDATE_SUCCESS:
      updatedCategories = state.categories.map((category) => {
        if (
          !category.archive &&
          action.payload.bulk_archived_categories.some((payloadItem) => payloadItem.id === category.id)
        ) {
          return { ...category, archive: true }
        }
        return category
      })
      return { ...state, categories: updatedCategories }

    case types.FETCH_CATEGORY_ALLOWED_ATTRIBUTE_SUCCESS:
      return { ...state, csvAttributes: action.payload }

    case types.DELETE_CATEGORY_SUCCESS:
      categories = [...state.categories]
      if (action.payload.parent_category_id) {
        categoryIndex = state.categories.findIndex((category) => category.id === action.payload.parent_category_id)
        subcategoryIndex = state.categories[categoryIndex].sub_categories.findIndex(
          (subcategory) => subcategory.id === action.payload.id
        )
        categories[categoryIndex].sub_categories.splice(subcategoryIndex, 1)
      } else {
        categoryIndex = state.categories.findIndex((category) => category.id === action.payload.id)
        categories.splice(categoryIndex, 1)
      }
      return { ...state, categories: [...categories] }

    case types.DELETE_CATEGORY_FAILURE:
      return state

    default:
      return state
  }
}

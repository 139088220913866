import { Color, styled } from 'maple-storybook'

const StyledDanger = styled('div')(() => ({
  '&.danger-container': {
    fontSize: 11,
    color: Color['error-500'],
    fontFamily: ['Inter', 'sans-serif'].join(','),
    height: '15px',
    lineHeight: '1.4em'
  }
}))
function Danger({ children }) {
  return <StyledDanger className="danger-container">{children}</StyledDanger>
}

export default Danger

import { Fragment } from 'react'
import { Radio } from 'maple-storybook'

import { Danger } from 'components'

const CustomRadioGroup = ({
  meta,
  options,
  errorTextClass,
  heading,
  radioClass,
  onChange,
  labelClass,
  input,
  disabled,
  ...rest
}) => {
  const hasError = rest.isTouched ? meta.error : meta.touched && meta.error

  const handleChange = (event, checked) => {
    input.onChange && input.onChange(checked)
    onChange && onChange(checked)
  }
  return (
    <Fragment>
      <Radio
        size="md"
        radioBtns={options}
        heading={heading}
        variant="primary"
        name={input.name || rest.name}
        id={input.name}
        value={input.value}
        disabled={disabled}
        radioClass={radioClass}
        labelClass={labelClass}
        onChange={(e, checked) => handleChange(e, checked)}
        {...rest}
      />

      {hasError && <Danger classes={{ dangerText: errorTextClass }}>{meta.error}</Danger>}
    </Fragment>
  )
}

export default CustomRadioGroup

import { stringify } from 'qs'
import * as qs from 'qs'

import { showLeaveLoader } from 'actions/leave'
import { showOrganoChartLoader, showUserLoader } from 'actions/user'

import { serialize } from './helpers'
import request from './request'

export const fetchUsers = (params, successAction, failureAction, filtersHash) => (dispatch) => {
  dispatch(
    request(
      `admin/users?${stringify(params, { arrayFormat: 'brackets' })}`,
      {},
      successAction,
      failureAction,
      true,
      filtersHash
    )
  )

  !params.search_params?.compact && dispatch(showUserLoader)
}

export const fetchCreationLogs = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `webhooks/workable_user_creation_logs?${stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const editUser = (params, successAction, failureAction, extraParams) => {
  return request(
    `admin/users/${params.id}?${stringify(extraParams, { arrayFormat: 'brackets' })}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ user: params }),
      submission: true
    },
    successAction,
    failureAction,
    true,
    extraParams && !extraParams.is_offboarding && { userId: params.id, leaveTypeId: params.leave_type_id }
  )
}

export const resendInvite = (params, successAction, failureAction) => {
  return request(
    `users/onboardings/resend_invite?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const deleteUser = (params, successAction, failureAction, flag) => {
  return request(
    `admin/users/${params.userId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: {
          destroy_params: params.deleteParams,
          ending_date: params.ending_date,
          resignation_date: params.resignation_date,
          tentative_ending_date: params.tentative_ending_date,
          resignation_type: params.resignation_type,
          exit_reason_id: params.exit_reason_id
        }
      }),
      submission: true
    },
    successAction,
    failureAction,
    true,
    flag
  )
}

export const getProfile = (id, successAction, failureAction) => {
  return request(`admin/users/${id}`, {}, successAction, failureAction, true)
}

export const getOnboardingUserProfile = (token, successAction, failureAction) => {
  return request(`admin/onboarding?login_token=${token}`, {}, successAction, failureAction, true)
}

export const updateProfile = (params, id, successAction, failureAction, extraParams = {}) =>
  request(
    `admin/users/${id}?${stringify(extraParams, { arrayFormat: 'brackets' })}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ user: params })
    },
    successAction,
    failureAction,
    true,
    extraParams
  )

export const createUser = (
  params,
  successAction,
  failureAction,
  parseWithoutSaving = null,
  operational_staff = null,
  flag = false,
  csv_user = false
) => {
  return request(
    `admin/users?validate_only=${parseWithoutSaving}&operational_staff=${operational_staff}&csv_user=${csv_user}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ user: params }),
      submission: true
    },
    successAction,
    failureAction,
    true,
    flag
  )
}

export const resetPassword = (params, successAction, failureAction) => {
  // https://github.com/lynndylanhurley/devise_token_auth/issues/1397
  // we need to change the below uri address because of the above mentioned issue
  return request(
    `auth${params.current_password ? '' : '/password'}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const activateUser = (userId, successAction, failureAction, flag) => {
  return request(
    `admin/users/${userId}/restore_user`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: userId }),
      submission: true
    },
    successAction,
    failureAction,
    true,
    flag
  )
}

export const resendWelcomeEmail = (userId, successAction, failureAction) => {
  return request(
    `admin/users/${userId}/resend_welcome_email`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: userId })
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchDashboard =
  (successAction, failureAction, params = null) =>
  (dispatch) => {
    dispatch(
      request(
        `admin/users/dashboard?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        },
        successAction,
        failureAction,
        true
      )
    )

    dispatch(showLeaveLoader())
    dispatch(showUserLoader)
  }

export const sendResetPasswordEmail = (params, successAction, failureAction) => {
  return request(
    `admin/users/send_password_reset_email`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user: params })
    },
    successAction,
    failureAction
  )
}

export const fetchOrganoChartJson = (userId, filter, successAction, failureAction) => {
  let url = 'admin/users/organo_chart'
  if (userId) {
    url = `${url}?${qs.stringify({ id: userId, type: 'search', filter: filter })}`
  } else {
    url = `${url}?${qs.stringify({ id: userId, filter: filter })}`
  }

  return (dispatch) => {
    dispatch(
      request(
        url,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        },
        successAction,
        failureAction,
        true
      )
    )

    dispatch(showOrganoChartLoader)
  }
}

export const fetchSelectUsers = (inactive_users_required, successAction, failureAction) => (dispatch, getState) => {
  let queryParam = ''
  if (typeof inactive_users_required === 'boolean') {
    let alreadyFetchedUsers = getState().users.selectUsers
    if (alreadyFetchedUsers.length !== 0) return
    queryParam = `admin/users/select_users?inactive_users_required=${inactive_users_required}`
  } else {
    queryParam = `admin/users?${stringify(inactive_users_required, { arrayFormat: 'brackets' })}`
  }
  dispatch(
    request(
      queryParam,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const fetchBlockedUsers = (successAction, failureAction) => {
  return request(
    'admin/users/blocked',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchUserSubordinates = (userId, successAction, failureAction, params = {}) => {
  return request(
    `admin/users/${userId}/subordinates?${stringify(params, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true,
    {
      flag: params.flag
    }
  )
}

export const fetchUsersExcept = (userId, successAction, failureAction) => {
  return request(
    `admin/users/select_users_except?id=${userId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchUserClearanceData = (userId, successAction, failureAction) =>
  request(
    `admin/users/${userId}/clearance_data`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )

export const fetchUsersCsvAttributes = (successAction, failureAction) =>
  request('admin/users/allowed_attributes', {}, successAction, failureAction, true)

export const fetchUsersCsvData = (params, attributes, successAction, failureAction) => {
  return request(
    `admin/users?${stringify({ ...params, attributes }, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchBulkUserCsvAttributes = (successAction, failureAction, params) => {
  return request(
    `admin/users/allowed_attributes_for_bulk_user?${stringify(params, { arrayFormat: 'brackets' })}`,
    {},
    successAction,
    failureAction,
    true
  )
}

export const fetchDayFeedback = (userId, successAction, failureAction) =>
  request(
    `users/${userId}/day_feedbacks/fetch_today_data`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )

export const createDayFeedback = (params, successAction, failureAction) =>
  request(
    `users/${params.userId}/day_feedbacks`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ day_feedback: { rating: params.rating } })
    },
    successAction,
    failureAction,
    true
  )

export const fetchEmploymentTypes = (successAction, failureAction) =>
  request('admin/users/employment_types', {}, successAction, failureAction, true)

export const bulkVerifyUsers = (ids, successAction, failureAction) => {
  return request(
    `admin/users/bulk_verify`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ ids: ids })
    },
    successAction,
    failureAction,
    true
  )
}

export const replaceDiscardTicketApprovals = (id, params, successAction, failureAction) =>
  request(
    `admin/users/${id}/update_user_ticket_approvals`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ tickets_params: params })
    },
    successAction,
    failureAction,
    true,
    {
      userId: id
    }
  )

export const fetchExitReasons = (successAction, failureAction) =>
  request('exit_reasons', {}, successAction, failureAction, true)

export const createExitReason = (params, successAction, failureAction) =>
  request(
    'exit_reasons',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ exit_reason: { name: params } }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )

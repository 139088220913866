export const minute = 60
export const hour = 60 * minute
export const day = 24 * hour

const convenient = [
  {
    factor: 1,
    unit: 'now'
  },

  {
    threshold: 1,
    threshold_for_now: 30,
    factor: 1,
    unit: 'second'
  },

  {
    unit: 'minute',
    factor: minute,
    threshold: 59.5
  },

  {
    unit: 'hour',
    factor: hour,
    threshold: 59.5 * 60
  },

  {
    threshold: (20.5 / 24) * day,
    factor: day,
    unit: 'day'
  },

  {
    threshold: 5.5 * day,
    format(value) {
      const formatters = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })

      return formatters.format(value)
    }
  }
]

export const timeStyle = { gradation: convenient }

import { ButtonGroup as BtnGroup } from 'maple-storybook'
import PropTypes from 'prop-types'

import FormError from 'components/FormError/FormError'

function ButtonGroup({ input: { onChange, value }, meta, fullWidth, className, buttons, ...custom }) {
  return (
    <div className="flex-column">
      <BtnGroup
        {...custom}
        fullWidth={fullWidth}
        buttonValue={value}
        exclusive
        className={className}
        onChange={onChange}
        touched={meta.touched.toString()}
        buttons={buttons}
        variant="normal"
      />
      {meta.touched && meta.error && <FormError>{meta.error}</FormError>}
    </div>
  )
}

ButtonGroup.propTypes = {
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  floatRight: PropTypes.bool
}

export default ButtonGroup

import { IconButton } from 'maple-storybook'
import PropTypes from 'prop-types'

import { iconButtonStyle } from 'variables/styles/iconButtonStyle'

function IconCustomButton({ ...props }) {
  const { color, children, customClass, ...rest } = props
  return (
    <IconButton
      {...rest}
      sx={{
        ...iconButtonStyle.button,
        ...(color ? iconButtonStyle[color] : ''),
        ...(customClass ? customClass : '')
      }}>
      {children}
    </IconButton>
  )
}

IconCustomButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'rose', 'white', 'simple']),
  customClass: PropTypes.string,
  disabled: PropTypes.bool
}

export default IconCustomButton

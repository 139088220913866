export const required = (value) => (value ? undefined : 'Required')

export const validateDescription = (value) => (!value || value.trim() === '<p><br></p>' ? 'Required' : undefined)

export const validateTicketAssignedForm = (values) => {
  const errors = {}

  if (!values.assignee) {
    errors.assignee = 'Assignee is required'
  }
  if (!values.comment) {
    errors.comment = 'Comment is required'
  }

  return errors
}

export default validateTicketAssignedForm

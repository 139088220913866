import { connect } from 'react-redux'
import { Modal } from 'maple-storybook'

import { HIDE_MODAL } from 'actions/actionTypes'

const BaseModal = ({
  open,
  title,
  content,
  width = 'md',
  fullWidth = false,
  okBtnText,
  closeBtnText,
  handleOkClick,
  handleClose,
  variant = 'primary',
  icon
}) => {
  return (
    <Modal
      open={open}
      title={title}
      content={content}
      width={width}
      fullWidth={fullWidth}
      okBtnText={okBtnText}
      closeBtnText={closeBtnText}
      handleOkClick={handleOkClick}
      handleCloseClick={handleClose}
      variant={variant}
      icon={icon}
    />
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: (_event, reason) => {
    if (ownProps.preventBackDropClose && reason && reason === 'backdropClick') return

    dispatch({ type: HIDE_MODAL })
  }
})

export default connect(null, mapDispatchToProps)(BaseModal)

import * as types from 'actions/actionTypes'
import initialState from 'reducers/initialState'

export const countries = (state = initialState.countries, action) => {
  switch (action.type) {
    case types.FETCH_COUNTRIES_SUCCESS:
      return { ...state, countriesList: action.payload }

    default:
      return state
  }
}

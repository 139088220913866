import { CLEAR_LOGIN_ERRORS, GOOGLE_AUTH_FAILURE, SET_LOGIN_ERRORS } from 'actions/actionTypes'

import initialState from './initialState'

export const auth = (state = initialState.auths, action) => {
  switch (action.type) {
    case GOOGLE_AUTH_FAILURE:
      if (action.payload.response?.data.errors) {
        return { ...state, loginErrors: action.payload.response.data.errors }
      } else {
        if (action.payload) {
          return { ...state, loginErrors: 'Something went wrong. Please try again later' }
        }
      }

      return

    case SET_LOGIN_ERRORS:
      return { ...state, loginErrors: action.payload }

    case CLEAR_LOGIN_ERRORS:
      return { ...state, loginErrors: '' }

    default:
      return state
  }
}

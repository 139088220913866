import { serialize } from './helpers'
import request from './request'

export const fetchCompanies = (successAction, failureAction) => {
  return request('users/experience/companies', {}, successAction, failureAction, true)
}

export const createCompany = (params, successAction, failureAction) => {
  return request(
    'users/experience/companies',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_experience_company: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchCurrentCompanies = (successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `company`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const updateCompanyLogo = (id, params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `company?id=${id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json'
        },
        body: serialize(params),
        submission: true
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const createBusinessUnits = (params, successAction, failureAction) =>
  request(
    'business_units',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ business_unit: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )

export const fetchBusinessUnits = (successAction, failureAction) =>
  request('business_units', {}, successAction, failureAction, true)

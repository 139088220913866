import { docSizeValidator } from 'helpers/validationsHelper'

export const required = (value) => (value ? undefined : 'Required')

export const validateNegativeValue = (value) => (value && value < 0 ? 'Value must be non-negative' : undefined)

export const validateCloseClaimForm = (values) => {
  const errors = {}

  if (!values.end_date) {
    errors.end_date = 'End Date is required'
  }
  return errors
}

export const validateClaimForm = (values) => {
  const errors = {}

  if (!values.start_date) {
    errors.start_date = 'Date is required'
  }

  if (!values.amount) {
    errors.amount = 'Amount is required'
  } else {
    if (isNaN(values.amount) || values.amount <= 0) {
      errors.amount = 'Invalid Amount'
    }
  }

  if (!values.claim_type) {
    errors.claim_type = 'Claim Type is required'
  }

  if (!values.description) {
    errors.description = 'Description is required'
  } else {
    if (values.description.length > 2000) {
      errors.description = 'Description length should be less than 2000 Characters'
    }
  }
  if (values.image) {
    if (values.image.length > 12) {
      errors.image = 'More than 12 image or attachment is not permitted'
    }
    values.image.forEach((file) => {
      if (docSizeValidator(file)) errors.image = 'Doc size exceeding'
    })
  }

  return errors
}

export default validateClaimForm

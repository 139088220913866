import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const vendor = (state = initialState.vendors, action) => {
  let vendorIndex,
    vendors = null

  switch (action.type) {
    case types.CREATE_VENDOR_SUCCESS:
      return { ...state, vendors: [...state.vendors, action.payload] }

    case types.CREATE_VENDOR_FAILURE:
      return state

    case types.FETCH_VENDORS_SUCCESS:
      if (action.payload.length > 0 && action.payload[0].item_count === undefined) {
        return { ...state, selectVendors: action.payload }
      } else return { ...state, vendors: action.payload }

    case types.FETCH_VENDORS_FAILURE:
      return state

    case types.VENDORS_BULK_UPDATE_SUCCESS:
      /* eslint-disable no-case-declarations */
      let updatedVendors = state.vendors
      action.payload.forEach((updatedVendor) => {
        vendorIndex = updatedVendors.findIndex((vendor) => vendor.id === updatedVendor.id)
        updatedVendors[vendorIndex].archive = true
      })
      /* eslint-enable no-case-declarations */
      return { ...state, vendors: updatedVendors }

    case types.FETCH_VENDORS_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, vendorsCsvAttributes: action.payload }

    case types.EDIT_VENDOR_SUCCESS:
      vendorIndex = state.vendors.findIndex((vendor) => vendor.id === action.payload.id)

      vendors = [...state.vendors]
      action.payload.item_count = vendors[vendorIndex].item_count
      vendors[vendorIndex] = action.payload

      return { ...state, vendors: [...vendors] }

    case types.EDIT_VENDOR_FAILURE:
      return state

    default:
      return state
  }
}

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useNavigate } from 'react-router-dom'
import { ActionCableConsumer, ActionCableProvider } from '@thrash-industries/react-actioncable-provider'
import ActionCable from 'actioncable'
import { add, home, Icon, menuOpen, notification, search } from 'maple-storybook/assets'
import NewClaim from 'views/Claims/NewClaim'
import NotificationPanel from 'views/NotificationPanel'
import CreateQuestion from 'views/Questions/CreateQuestion'
import TicketForm from 'views/Tickets/TicketForm'

import { FORM_MODAL, SHOW_MODAL } from 'actions/actionTypes'
import {
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  readNotificationFailure,
  readNotificationSuccess,
  recievedRelatimeNotification,
  setCallInProgress
} from 'actions/notifications'
import { toggleSidebar } from 'actions/sidebar'
import { fetchNotifications, readNotification } from 'api/notifications'
import { extractPath } from 'helpers/applicationHelper'
import { API_WS_LINK } from 'helpers/authHelper'
import { NOTIFICATOINS_PAGE_SIZE } from 'helpers/constants'
import { checkPermission } from 'helpers/permissionsHelper'

import './styles.scss'

export const BottomActionBar = () => {
  const notificationsupported = 'Notification' in window
  const cable = ActionCable.createConsumer(API_WS_LINK())
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState('home')
  const currentUserId = useSelector((state) => state.reduxTokenAuth.currentUser.attributes.id)
  const openModal = (modalType, modalProps = null) => {
    dispatch({ type: SHOW_MODAL, modalType: modalType, modalProps: modalProps })
  }
  const [drawerOpen, setDrawerOpen] = useState(false)
  const notifications = useSelector((state) => state.notifications.allNotifications)
  const sidebarOpen = useSelector((state) => state.sidebar.open)
  const userPermissions = useSelector((state) => state.permissions.userPermissions)

  const handleDrawerToggle = () => dispatch(toggleSidebar(!sidebarOpen))

  const handleAdd = () => {
    if (window.location.pathname === '/claims' || window.location.pathname === `/profile/${currentUserId}/claims`) {
      openModal(FORM_MODAL, {
        fullscreen: true,
        form: <NewClaim />,
        title: 'NEW CLAIM'
      })
    } else if (
      window.location.pathname === '/people' &&
      checkPermission(userPermissions, 'people', 'users', 'invite_people').isFull()
    ) {
      navigate('/people/new')
    } else if (
      window.location.pathname === '/tickets' ||
      window.location.pathname === '/tickets/sent' ||
      window.location.pathname === 'tickets/dashboard'
    ) {
      openModal(FORM_MODAL, {
        fullscreen: true,
        form: <TicketForm />,
        title: 'New Ticket',
        closeIconClass: 'tickets-ticket-form-close-form'
      })
    } else if (window.location.pathname === '/faqs') {
      openModal(FORM_MODAL, {
        fullscreen: true,
        form: <CreateQuestion />,
        title: 'Add FAQs'
      })
    }
  }

  const goToHome = () => {
    if (isOnDashboard) return

    navigate('/dashboard')
  }

  const handleNotificationsClick = () => {
    if (notifications.notifications?.length === 0) {
      dispatch(setCallInProgress('loadMoreNotifications'))
      dispatch(
        fetchNotifications(
          1,
          NOTIFICATOINS_PAGE_SIZE,
          false,
          false,
          fetchNotificationsSuccess,
          fetchNotificationsFailure
        )
      )
    }
    setDrawerOpen(true)
  }

  const handleNotificationClick = (notificationObj) => {
    dispatch(readNotification({ ids: [notificationObj.id] }, readNotificationSuccess, readNotificationFailure))
    navigate(extractPath(notificationObj.action_url))
  }

  const showDesktopNotification = (notificationObj, callback) => {
    const notification = new Notification('New message incoming', { body: notificationObj.message })
    notification.onclick = () => callback(notificationObj)
  }

  const handleReceived = (newNotification) => {
    let notificationObj = newNotification.notification ?? newNotification
    if (Object.keys(newNotification).includes('inprogress_notifcation_id')) {
      notificationObj = newNotification.notification
    }
    dispatch(recievedRelatimeNotification(newNotification))

    if (notificationsupported && Notification.permission === 'granted') {
      showDesktopNotification(notificationObj, handleNotificationClick)
    } else if (notificationsupported && Notification.permission !== 'denied') {
      Notification.requestPermission().then(() => {
        showDesktopNotification(notificationObj, handleNotificationClick)
      })
    } else if (
      notificationObj.type !== 'CsvInprogressNotification' &&
      notificationObj.type !== 'CsvDownloadNotification'
    ) {
      toastr.warning(notificationObj.message)
    }
  }

  useEffect(() => {
    return () => cable.disconnect()
  }, [])

  const isOnDashboard = window.location.pathname === '/dashboard'
  const dashboardSelectedClass = isOnDashboard ? 'div-selected' : ''

  return (
    <ActionCableProvider cable={cable}>
      <ActionCableConsumer
        channel="NotificationsChannel"
        onReceived={handleReceived}
        onDisconnected={() => cable.disconnect()}>
        <div className="actionbar-div">
          <div
            onClick={() => {
              goToHome()
              setCurrentTab('home')
            }}
            className={`mb-half icon-text-div ${dashboardSelectedClass} ${
              currentTab === 'home' && 'ml-half selected-tab-background'
            }`}>
            <Icon
              transform={''}
              paths={home}
              strokeColor={currentTab === 'home' ? 'white' : 'gray-500'}
              fillColor={currentTab === 'home' ? 'white' : 'gray-500'}
              viewBox="0 -960 960 960"
              size={20}
            />
            {currentTab === 'home' && <p className="icon-text">Home</p>}
          </div>

          <div className={`mb-half icon-text-div ${currentTab === 'search' && 'selected-tab-background'}`}>
            <Icon
              transform={''}
              paths={search}
              strokeColor={currentTab === 'search' ? 'white' : 'gray-500'}
              fillColor={currentTab === 'search' ? 'white' : 'gray-500'}
              viewBox="0 -960 960 960"
              size={20}
            />
            {currentTab === 'search' && <p className="icon-text">Search</p>}
          </div>

          <div
            className={`mb-half icon-text-div ${currentTab === 'newItem' && 'selected-tab-background'}`}
            onClick={() => {
              if (
                [
                  '/people',
                  '/claims',
                  '/tickets',
                  '/faqs',
                  '/tickets/sent',
                  '/tickets/dashboard',
                  `/profile/${currentUserId}/claims`
                ].includes(window.location.pathname)
              ) {
                setCurrentTab('newItem')
                handleAdd()
              }
            }}>
            <Icon
              transform={''}
              paths={add}
              strokeColor={currentTab === 'newItem' ? 'white' : 'gray-500'}
              fillColor={currentTab === 'newItem' ? 'white' : 'gray-500'}
              size={20}
              viewBox="0 -960 960 960"
            />
            {currentTab === 'newItem' && <span className="icon-text">New</span>}
          </div>

          <div
            className={`mb-half icon-text-div ${currentTab === 'notification' && 'selected-tab-background'}`}
            onClick={() => {
              setCurrentTab('notification')
              handleNotificationsClick()
            }}>
            <Icon
              transform={''}
              paths={notification}
              strokeColor={currentTab === 'notification' ? 'white' : 'gray-500'}
              fillColor={currentTab === 'notification' ? 'white' : 'gray-500'}
              viewBox="0 -960 960 960"
              size={20}
            />
            {currentTab === 'notification' && <p className="icon-text">Notifications</p>}
          </div>

          <div
            className={`mb-half icon-text-div ${currentTab === 'menu' && 'mr-half selected-tab-background'}`}
            onClick={() => {
              setCurrentTab('menu')
              handleDrawerToggle()
            }}>
            <Icon
              transform={''}
              paths={menuOpen}
              strokeColor={currentTab === 'menu' ? 'white' : 'gray-500'}
              fillColor={currentTab === 'menu' ? 'white' : 'gray-500'}
              viewBox="0 -960 960 960"
              size={20}
            />
            {currentTab === 'menu' && <p className="icon-text">Menu</p>}
          </div>
          <NotificationPanel drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </div>
      </ActionCableConsumer>
    </ActionCableProvider>
  )
}

export default BottomActionBar

import { styled } from 'maple-storybook'

import { StyledTypography } from 'variables/styles/typographyStyle.jsx'

const Info = styled(({ children, ...rest }) => <div {...rest}>{children}</div>)(() => ({
  ...StyledTypography.infoText,
  ...StyledTypography.defaultFontStyle
}))

export default Info

import { createTheme } from 'maple-storybook'

export const appTheme = createTheme({
  overrides: {
    MuiCardContent: {
      root: {
        paddingLeft: '8px',
        paddingRight: '8px',
        '@media (max-width:480px)': {
          padding: 0
        }
      }
    },
    MuiCardHeader: {
      root: {
        marginRight: '0px',
        marginLeft: '0px',
        paddingTop: '20px',
        paddingLeft: '8px'
      }
    },
    MuiListItemText: {
      root: { marginTop: 0, marginBottom: 0 },
      primary: { fontSize: '1rem', lineHeight: 'normal' }
    },
    MuiTableRow: { root: { verticalAlign: 'bottom' } },
    MuiDialogContent: { root: { padding: '0 24px 24px 24px' } },
    MuiDialogContentText: { root: { marginBottom: '0', whiteSpace: 'break-spaces' } },
    MuiTextField: { root: { width: '100%' } },
    MuiInput: {
      underline: {
        '&::before': { borderBottomColor: '#cccccc' },
        '&:hover:before': { borderBottomWidth: '1px !important' }
      }
    },
    MuiAvatar: {
      root: {
        width: '30px',
        height: '30px',
        fontSize: '12px'
      },
      img: { objectFit: 'cover' }
    },
    MuiAvatarGroup: { avatar: { border: 'none' } },
    MuiPaper: {
      elevation4: { boxShadow: 'none', borderBottom: '2px solid #D3D8E6' },
      rounded: { borderRadius: '10px' }
    },
    MuiTimelineDot: {
      defaultPrimary: {
        color: '#fff',
        borderColor: 'transparent',
        backgroundColor: '#1eae63'
      }
    },
    MuiToolbar: {
      regular: {
        fontSize: '25px',
        minHeight: '71px',
        fontWeight: '500',
        color: '#454343',
        backgroundColor: '#ffffff',
        height: '97px'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff',
        fontWeight: 'bold'
      }
    },
    MuiTypography: {
      h6: {
        fontSize: '20px',
        paddingLeft: '20px',
        width: 'auto'
      },
      body1: { fontSize: '0.875rem' }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1.5rem'
      }
    },
    MuiDialogTitle: {
      root: {
        marginTop: '20px',
        padding: '0px 20px 20px 0px'
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '0'
      }
    }
  }
})

import { Card, CardActions, CardContent, CardHeader, Grid, Typography } from 'maple-storybook'
import PropTypes from 'prop-types'

import statsCardStyle from 'variables/styles/statsCardStyle'

function StatsCard({
  id,
  title,
  description,
  statLink,
  small,
  statText,
  statIconColor,
  iconColor,
  iconClass,
  ...props
}) {
  return (
    <Card sx={{ ...statsCardStyle.card, ...statsCardStyle.stateCard }} id={id && `${id}-card`}>
      <CardHeader
        sx={{
          ...statsCardStyle.cardHeader,
          ...statsCardStyle[iconColor + 'CardHeader']
        }}
        avatar={<props.icon className={iconClass ? iconClass : ''} sx={iconClass ? {} : statsCardStyle.cardIcon} />}
        id={id && `${id}-card-header`}
      />
      <CardContent sx={{ ...statsCardStyle.cardContent }} id={id && `${id}-card-content`}>
        <Typography component="p" sx={{ ...statsCardStyle.cardCategory }} id={id && `${id}-card-title`}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          sx={{ ...statsCardStyle.cardTitle }}
          id={id && `${id}-card-description`}>
          {description}
          {small !== undefined ? <small className={{ ...statsCardStyle.cardTitleSmall }}>{small}</small> : null}
        </Typography>
      </CardContent>
      <CardActions sx={{ ...statsCardStyle.cardActions }} id={id && `${id}-card-message`}>
        <Grid item sx={{ ...statsCardStyle.cardStats }}>
          <props.statIcon
            sx={{ ...statsCardStyle.cardStatsIcon, ...statsCardStyle[statIconColor + 'CardStatsIcon'] }}
          />
          {statLink !== undefined ? (
            <a href={statLink.href} className={{ ...statsCardStyle.cardStatsLink }}>
              {statLink.text}
            </a>
          ) : statText !== undefined ? (
            statText
          ) : null}
        </Grid>
      </CardActions>
    </Card>
  )
}

StatsCard.defaultProps = {
  iconColor: 'blue',
  statIconColor: 'gray'
}

StatsCard.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.func.isRequired,
  iconColor: PropTypes.oneOf(['orange', 'green', 'red', 'blue', 'purple', 'dodgerblue', 'white', 'royalblue']),
  title: PropTypes.node,
  description: PropTypes.node,
  small: PropTypes.node,
  statIcon: PropTypes.func.isRequired,
  statIconColor: PropTypes.oneOf(['warning', 'primary', 'danger', 'success', 'info', 'rose', 'gray']),
  statLink: PropTypes.object,
  statText: PropTypes.node
}

export default StatsCard

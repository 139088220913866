import { capitalize } from 'lodash'
import { Badge, Box } from 'maple-storybook'
import {
  aeroplane,
  ChildCare,
  ChildFriendly,
  extraHours,
  FlightTakeoff,
  Home,
  Icon,
  LinkOff,
  LocalHotel,
  plane,
  SentimentDissatisfied,
  sick,
  StrikethroughS,
  swap,
  SwapHoriz,
  Timer,
  umbrella,
  unpaid,
  Work
} from 'maple-storybook/assets'
import moment from 'moment'

import { Muted } from 'components'
import { checkArrayPresence } from 'helpers/applicationHelper'
import { formatDate, getExtendedHoursFromDateTime } from 'helpers/datesHelper'
import { leaveStyle } from 'variables/styles/leaveStyle'

import { checkPermission } from './permissionsHelper'

export const leaveApplicableOptions = [
  { label: 'Working Day', value: 'working_day' },
  { label: 'Weekend', value: 'weekend' },
  { label: 'Holiday', value: 'holiday' },
  { label: 'Weekday (Full Week)', value: 'weekday_full_week' },
  { label: 'Holiday and Weekend', value: 'holiday_and_weekend' },
  { label: 'Full Week', value: 'full_week' }
]

export const sandwichApplicableOptions = [
  { label: 'Pre Off', value: 'pre_off' },
  { label: 'Post Off', value: 'post_off' },
  { label: 'Pre-Post Off', value: 'pre_post_off' },
  { label: 'Half Week Off', value: 'half_week' },
  { label: 'Most Week Off', value: 'most_of_the_week' },
  { label: 'Full Week Off', value: 'full_week' }
]

export const deductionType = [
  { label: 'Deduct sandwiched days', value: 'sandwich' },
  { label: 'Deduct half of sandwiched days', value: 'half_sandwich' },
  { label: 'Other', value: 'other' }
]

export const compensationTypes = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Overtime', value: 'overtime' },
  { label: 'Swappable', value: 'swappable' }
]

export const leaveTypeFormDefaultOptions = [
  { label: 'Optional', value: 'optional' },
  { label: 'Required', value: 'required' },
  { label: 'Not Applicable', value: 'not_applicable' }
]

export const leaveReasonTypeOptions = [
  { label: 'Free Text', value: 'free_text' },
  { label: 'Predefine Text', value: 'predefine_text' }
]

export const timePeriodOptions = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' }
]

export const leaveTypeIcons = [
  { label: <FlightTakeoff />, value: 'FlightTakeoff' },
  { label: <Home />, value: 'Home' },
  { label: <LocalHotel />, value: 'LocalHotel' },
  { label: <SentimentDissatisfied />, value: 'SentimentDissatisfied' },
  { label: <StrikethroughS />, value: 'StrikethroughS' },
  { label: <SwapHoriz />, value: 'SwapHoriz' },
  { label: <Timer />, value: 'Timer' },
  { label: <Work />, value: 'Work' },
  { label: <LinkOff />, value: 'LinkOff' },
  { label: <ChildFriendly />, value: 'ChildFriendly' },
  { label: <ChildCare />, value: 'ChildCare' },
  { label: <Icon paths={plane} strokeColor="gray-500" fillColor="white" />, value: 'Plane' },
  { label: <Icon paths={sick} fillColor="gray-500" />, value: 'Sick' },
  { label: <Icon paths={aeroplane} />, value: 'Aeroplane' },
  { label: <Icon paths={swap} />, value: 'Swap' },
  { label: <Icon paths={unpaid} />, value: 'Unpaid' },
  { label: <Icon paths={umbrella} />, value: 'Umbrella' },
  { label: <Icon paths={extraHours} />, value: 'ExtraHours' }
]

export const leaveAdminTabCSVHeaders = [
  'name',
  'user_id',
  'employment_status',
  'days',
  'start_date',
  'end_date',
  'leave_type',
  'leave_status',
  'approved_date'
]

export const leavePolicyTableHeaders = ['Leave Type', 'Employment Type', 'Min Days', 'Quota']

export const frequencyOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
  { label: 'Fixed', value: 'fixed' }
]

export const leaveApprovalStatusOptions = [
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' }
]

export const myLeaveSubTabs = [
  {
    id: 0,
    label: 'Pending',
    value: 'pending'
  },
  {
    id: 1,
    label: 'Approved',
    value: 'approved'
  },
  {
    id: 2,
    label: 'Cancelled',
    value: 'cancelled'
  },
  {
    id: 3,
    label: 'Rejected',
    value: 'rejected'
  }
]

export const leaveApprovalFilterOptions = [
  { label: 'Pending(Lead)', value: 'pending_lead_approval' },
  { label: 'Pending(PM)', value: 'pending_pm_approval' },
  { label: 'Pending(HR)', value: 'pending_hr_approval' },
  { label: 'Approved', value: 'approved' },
  { label: 'Rejected', value: 'rejected' }
]

export const leavesTabs = [
  {
    value: '/leaves/approvals',
    label: 'Leave Approvals',
    href: '/leaves/approvals'
  },
  {
    value: '/leaves/summary',
    label: 'Leave Summary',
    href: '/leaves/summary'
  },
  {
    value: '/leaves/policy',
    label: 'Leave Policy',
    href: '/leaves/policy'
  },
  {
    value: '/leaves/manual-adjustment',
    label: 'Manual Adjustment',
    href: '/leaves/manual-adjustment'
  },
  {
    value: '/leaves/holidays',
    label: 'Holidays',
    href: '/leaves/holidays'
  }
]

export const getLeaveCardWidgetIcon = (iconName) => {
  switch (iconName) {
    case 'FlightTakeoff':
      return <FlightTakeoff className="iconStyle" />
    case 'Home':
      return <Home className="iconStyle" />
    case 'LocalHotel':
      return <LocalHotel className="iconStyle" />
    case 'SentimentDissatisfied':
      return <SentimentDissatisfied className="iconStyle" />
    case 'StrikethroughS':
      return <StrikethroughS className="iconStyle" />
    case 'SwapHoriz':
      return <SwapHoriz className="iconStyle" />
    case 'Timer':
      return <Timer className="iconStyle" />
    case 'Work':
      return <Work className="iconStyle" />
    case 'LinkOff':
      return <LinkOff className="iconStyle" />
    case 'ChildFriendly':
      return <ChildFriendly className="iconStyle" />
    case 'ChildCare':
      return <ChildCare className="iconStyle" />
    case 'Plane':
      return <Icon paths={plane} strokeColor="gray-500" fillColor="gray-100" className="iconStyle" />
    case 'Sick':
      return <Icon paths={sick} fillColor="gray-500" className="iconStyle" />
    case 'Aeroplane':
      return <Icon paths={aeroplane} className="iconStyle" />
    case 'Swap':
      return <Icon paths={swap} className="iconStyle" />
    case 'Unpaid':
      return <Icon paths={unpaid} className="iconStyle" />
    case 'Umbrella':
      return <Icon paths={umbrella} className="iconStyle" />
    case 'ExtraHours':
      return <Icon paths={extraHours} className="iconStyle" />
    default:
      return ''
  }
}

export const getWeekDayDates = (startDate, endDate) => {
  let dates = []
  let currentDate = new Date(startDate)
  endDate = new Date(endDate)
  while (currentDate <= endDate) {
    dates.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}
export const filterUserLeaves = (userLeaves) =>
  userLeaves.filter((leave) => !leave.manually_adjusted || leave.availed_quota !== 0)

export const leaveYearsOptions = (startYear, endYear) => {
  const years = []

  for (let i = startYear; i < getCurrentYear() + endYear; i++) {
    years.push({ label: i, value: i })
  }

  return years
}
const QUOTA_BASED_MAPPING = {
  ewh: false,
  ewd: false,
  unpaid: false,
  wfh: false,
  swd: false,
  annual: true,
  sick: true,
  compensation: true
}

export const handleLeavePolicyQuota = (quota, leaveType) => {
  if (QUOTA_BASED_MAPPING[leaveType]) return quota
  else return quota === 0 ? 'Not Allowed' : 'Allowed'
}

export const renderLeaveType = (leave) => {
  return leave?.leave_type?.title.titleize()
}

export const employmentTypesOptions = (employmentTypes) => {
  return employmentTypes?.map((employment_status) => {
    return { label: capitalize(employment_status), value: employment_status }
  })
}

export const renderManualChip = () => <Badge label={<b>Manual</b>} className={'manual-chip'} size="md" />

export const renderEnchashmentChip = () => <Badge label={<b>Encashment</b>} className={'encashment-chip'} size="md" />
export const renderSandwichChip = () => <Badge label={<b>Sandwich</b>} className={'manual-chip'} size="md" />
export const renderSystemGeneratedChip = () => (
  <Badge label={<b>System Generated</b>} className={'manual-chip'} size="md" />
)
export const dayPluralize = (days) => (days > 1 ? `${days} days` : `${days} day`)

export const isLeaveApproved = (leaveStatus) => leaveStatus === 'approved'

export const isLeaveApprovalPendingByPM = (leaveStatus) => leaveStatus === 'pending_pm_approval'

export const isLeaveApprovalPendingByHR = (leaveStatus) => leaveStatus === 'pending_hr_approval'

export const canUserProcessLeave = (leaveStatus, canAddFinalApproval) =>
  !isLeaveApprovalPendingByHR(leaveStatus) || canAddFinalApproval

export const isLeaveApprovalPendingByLead = (leaveStatus) => leaveStatus === 'pending_lead_approval'

export const isLeaveRejected = (leaveStatus) => leaveStatus === 'rejected'

export const isLeaveCancelled = (leaveStatus) => leaveStatus === 'cancelled'

export const isLeaveWithdrawalApprovalPendingByLead = (leaveStatus) =>
  leaveStatus === 'pending_lead_withdrawal_approval'

export const isLeaveWithdrawalApprovalPendingByPM = (leaveStatus) => leaveStatus === 'pending_pm_withdrawal_approval'

export const isLeaveWithdrawalApprovalPendingByHR = (leaveStatus) => leaveStatus === 'pending_hr_withdrawal_approval'

export const swapDayHelpText = () => {
  return (
    <div className="padding-inline">
      <Muted> Swap from (The date when employee took off from work) and swap to (The date when employee worked)</Muted>
    </div>
  )
}
export const isLeaveApprovalPendingByPmOrPendingWithdrawal = (leave) =>
  isLeaveApprovalPendingByPM(leave) || isLeaveWithdrawalApprovalPendingByPM(leave)

export const isLeaveApprovalPendingByHrOrPendingWithdrawal = (leave) =>
  isLeaveApprovalPendingByHR(leave) || isLeaveWithdrawalApprovalPendingByHR(leave)

export const isLeaveApprovalPendingByLeadOrPendingWithdrawal = (leave) =>
  isLeaveApprovalPendingByLead(leave) || isLeaveWithdrawalApprovalPendingByLead(leave)

export const existsInApprovalsFilter = (leaveStatus, leaveApprovalsFilter) => {
  return (
    (leaveApprovalsFilter.approved && isLeaveApproved(leaveStatus)) ||
    (leaveApprovalsFilter.pending_lead_approval && isLeaveApprovalPendingByLeadOrPendingWithdrawal(leaveStatus)) ||
    (leaveApprovalsFilter.pending_pm_approval && isLeaveApprovalPendingByPmOrPendingWithdrawal(leaveStatus)) ||
    (leaveApprovalsFilter.pending_hr_approval && isLeaveApprovalPendingByHrOrPendingWithdrawal(leaveStatus)) ||
    (leaveApprovalsFilter.rejected && isLeaveRejected(leaveStatus))
  )
}

export const appendWithdrawalStatuses = (statuses) => {
  statuses.includes('pending_lead_approval') && statuses.push('pending_lead_withdrawal_approval')
  statuses.includes('pending_pm_approval') && statuses.push('pending_pm_withdrawal_approval')
  statuses.includes('pending_hr_approval') && statuses.push('pending_hr_withdrawal_approval')
  return statuses
}
export const leaveMinDate = () => moment(new Date(getCurrentYear(), 0, 1 - 14))

export const getCurrentYear = () => new Date().getFullYear()

export const getCurrentMonth = () => new Date().getMonth()

export const getYear = (year) => new Date(year).getFullYear()

export const checkLeaveType = (leaveTypes, leaveType) => leaveTypes?.find((type) => type.leave_type_id === leaveType)

export const limitLeaveReasonString = (leave, endLimit) =>
  leave.reason?.length >= endLimit ? `${leave.reason.substring(0, endLimit)}...` : leave.reason

export const yearsOnwards = (limit) => getCurrentYear() + limit

export const convertLeavesStatusToArray = (leaveStatuses) => {
  let statuses = []
  Object.keys(leaveStatuses).map((status) => (leaveStatuses[status] ? statuses.push(status) : ''))
  appendWithdrawalStatuses(statuses)
  return statuses
}

export const checkLeavesData = (leaves) => checkArrayPresence(leaves) && leaves instanceof Array

export const LEAVE_QUOTA_STRING = <center>Calculating Leave Quota</center>

export const renderLeaveStatusChips = (status, row) => {
  switch (status) {
    case 'pending_lead_approval':
      return (
        <Badge
          label={<b>Pending(Lead)</b>}
          size="md"
          sx={{ ...leaveStyle.chip, ...leaveStyle.pendingApprovalByLeadChip }}
        />
      )
    case 'pending_pm_approval':
      return (
        <Badge
          size="md"
          label={<b>Pending(PM)</b>}
          sx={{ ...leaveStyle.chip, ...leaveStyle.pendingApprovalByPmChip }}
        />
      )
    case 'pending_hr_approval':
      return (
        <Badge
          size="md"
          label={<b>Pending(HR)</b>}
          sx={{ ...leaveStyle.chip, ...leaveStyle.pendingApprovalByHrChip }}
        />
      )
    case 'rejected':
      return <Badge size="md" label={<b>Rejected</b>} sx={{ ...leaveStyle.chip, ...leaveStyle.rejectedChip }} />
    case 'cancelled':
      return <Badge label={<b>Cancelled</b>} size="md" sx={{ ...leaveStyle.chip, ...leaveStyle.cancelledChip }} />
    case 'pending_lead_withdrawal_approval':
      return (
        <Badge
          label={<b>Pending Withdrawal(Lead)</b>}
          size="md"
          sx={{ ...leaveStyle.chip, ...leaveStyle.pendingWithdrawalApprovalByLeadChip }}
        />
      )
    case 'pending_pm_withdrawal_approval':
      return (
        <Badge
          label={<b>Pending Withdrawal(PM)</b>}
          size="md"
          sx={{ ...leaveStyle.chip, ...leaveStyle.pendingWithdrawalApprovalByPmChip }}
        />
      )
    case 'pending_hr_withdrawal_approval':
      return (
        <Badge
          label={<b>Pending Withdrawal(HR)</b>}
          size="md"
          sx={{ ...leaveStyle.chip, ...leaveStyle.pendingWithdrawalApprovalByHrChip }}
        />
      )
    case 'approved':
      return <Badge label={<b>Approved</b>} size="md" sx={{ ...leaveStyle.chip, ...leaveStyle.approvedChip }} />
    default:
      return (
        <Badge
          label={<b>{status}</b>}
          size="md"
          sx={{ ...leaveStyle.chip, ...(row % 2 === 0 ? leaveStyle.whiteChip : leaveStyle.lightGrayChip) }}
        />
      )
  }
}

export let leaveDurationDetail = (leave) => {
  if (leave.days === 1) {
    return `On ${formatDate(leave.start_date)}`
  } else {
    return `From ${formatDate(leave.start_date)} to ${formatDate(leave.end_date)} for ${leave.business_days} days`
  }
}

export const isLeavesTabs = (path) =>
  ['/leaves/approvals', '/leaves/summary', '/leaves/policy', '/leaves/manual-adjustment', '/leaves/holidays'].includes(
    path
  ) || false

export const renderRequestedLeaveDates = (leaveApproval) => {
  const startDate = formatDate(leaveApproval.start_date)
  const endDate = formatDate(leaveApproval.end_date)

  if (
    leaveApproval.days === 1 &&
    !(
      leaveApproval.leave_type.applicable_on === 'weekday_full_week' &&
      leaveApproval.leave_type.compensation_type === 'overtime'
    )
  ) {
    return (
      <Box component="div" className={leaveApproval.leave_type.compensation_type === 'swappable' ? 'date' : ''}>
        {startDate}
        {leaveApproval.leave_type.compensation_type === 'swappable' && <span>&#40;Swap To {endDate}&#41;</span>}
      </Box>
    )
  } else {
    return leaveApproval.start_date === leaveApproval.end_date ? (
      <>
        {dayPluralize(leaveApproval.business_days)} ({startDate})
      </>
    ) : (
      <>
        {dayPluralize(leaveApproval.business_days)} ({startDate} <b>-</b> {endDate})
      </>
    )
  }
}

export let leaveDeletionConfirmationMessage = (leave) => {
  if (leave.leave_type === 'ewd') {
    return 'Are you sure you want to delete this Extra Working Day request?'
  } else {
    return 'Are you sure you want to delete this leave application?'
  }
}

export const leaveMaxRange = (manual_form, selectedYear, currentYear) => {
  if (manual_form) {
    return moment(new Date(selectedYear || currentYear, 11, 31))
  } else {
    return moment(new Date(currentYear() + 1, 11, 31))
  }
}

export const getLeavePolicyValues = (values, isEdit = false) => {
  const year = values.year
  const grace_period = values.grace_period_enabled ? values.grace_period : 0
  const policy_type = values.policy_type
  const enable_auto_leaves = values.enable_auto_leaves
  const round_off_leaves_quota = values.round_off_leaves_quota
  const use_probation_quota = values.use_probation_quota

  delete values.year
  delete values.grace_period
  delete values.grace_period_enabled
  delete values.policy_type
  delete values.enable_auto_leaves
  delete values.round_off_leaves_quota
  delete values.use_probation_quota

  let newValues = []
  Object.values(values).forEach((leaveRule) => {
    leaveRule.year = year
    leaveRule.policy_type = policy_type
    leaveRule.grace_period = grace_period
    leaveRule.enable_auto_leaves = enable_auto_leaves
    leaveRule.round_off_leaves_quota = round_off_leaves_quota
    leaveRule.use_probation_quota = use_probation_quota

    leaveRule.half_day_hours_to_track = leaveRule.trackable
      ? leaveRule.half_day_hours_to_track
        ? getExtendedHoursFromDateTime(leaveRule.half_day_hours_to_track)
        : null
      : null
    leaveRule.full_day_hours_to_track = leaveRule.trackable
      ? leaveRule.full_day_hours_to_track
        ? getExtendedHoursFromDateTime(leaveRule.full_day_hours_to_track)
        : null
      : null
    leaveRule.maximum_days_to_track = leaveRule.trackable ? leaveRule.maximum_days_to_track ?? null : null

    if (isEdit) {
      newValues.push(leaveRule)
    } else if (leaveRule.enabled) {
      newValues.push(leaveRule)
    }
  })
  return newValues
}

export const circleProgress = (leaveType) =>
  leaveType.total_quota > 0 && leaveType.availed_quota >= 0
    ? (parseFloat(leaveType.availed_quota).toFixed(1) / leaveType.total_quota) * 100
    : undefined

export const leavePolicyFormPreference = [
  { label: 'Roll Over', value: 'rollover' },
  { label: 'Encashment', value: 'encashment' }
]

export const encashmentRolloverMessage = (
  remainingLeaves,
  maxRolloverableLeaves,
  maxEncashableLeaves,
  encashableThreshold
) => {
  return (
    <>
      <span>
        By the end of the year, you have {remainingLeaves} unused leaves. You can either encash or roll them over.
      </span>
      <br />
      <b>Rollover Option:</b>
      <ul>
        <li>You can rollover upto maximum {maxRolloverableLeaves} leaves.</li>
        <li>Leaves beyond this limit will be encashed based on {encashableThreshold} Enacasement Criteria.</li>
        <li>
          After meeting the encashment criteria, leaves upto {maxEncashableLeaves} will be encashed. Any remaining
          leaves will be discarded.
        </li>
      </ul>
      <b>Encashment Option:</b>
      <ul>
        <li>
          {' '}
          If your unused leaves exceed {encashableThreshold} Encashment criteria, leaves beyond this criteria will be
          encashed upto {maxEncashableLeaves}.
        </li>
        <li>Any remaining leaves will be rolled over upto {maxRolloverableLeaves} Maximum Rollover Limit.</li>
      </ul>
    </>
  )
}

export const minDaysApplicableShowField = (leaveTypes, indexType) =>
  leaveTypes[indexType].compensation_type === 'paid' ||
  leaveTypes[indexType].compensation_type === 'unpaid' ||
  (leaveTypes[indexType].compensation_type === 'overtime' &&
    leaveTypes[indexType].applicable_on !== 'weekday_full_week')

export const pendingLeaveStatuses = [
  'pending_lead_approval',
  'pending_pm_approval',
  'pending_hr_approval',
  'pending_lead_withdrawal_approval',
  'pending_pm_withdrawal_approval',
  'pending_hr_withdrawal_approval'
]

export const cancelledLeaveStatuses = ['rejected', 'cancelled']

export const leaveSettingsTabs = [
  {
    value: '/application-settings/leaves',
    label: 'Custom Leave Type Settings',
    href: '/application-settings/leaves'
  },
  {
    value: '/application-settings/leaves/sandwich_policy',
    label: 'Sandwich Policy',
    href: '/application-settings/leaves/sandwich_policy'
  },
  {
    value: '/application-settings/leaves/rules/auto_apply_policies',
    label: 'Auto Leave Apply Rule',
    href: '/application-settings/leaves/rules/auto_apply_policies'
  }
]

export const isLeavesSettingsTabs = (path) =>
  [
    '/application-settings/leaves',
    '/application-settings/leaves/sandwich_policy',
    '/application-settings/leaves/rules/auto_apply_policies'
  ].includes(path) || false

export const getTableLeaves = (tab, totalLeaves) => {
  if (tab === 'approved') return totalLeaves.userApprovedLeaves
  if (tab === 'rejected') return totalLeaves.userRejectedLeaves
  if (tab === 'cancelled') return totalLeaves.userCancelledLeaves
  return totalLeaves.userNonApprovedLeaves
}

export const renderLeavesActiveTab = (permissions) => {
  const tabsPermissions = [
    checkPermission(permissions, 'leaves', 'general', 'view_leave_approvals').isFull(),
    !checkPermission(permissions, 'leaves', 'general', 'admin_view_all_leaves').isNone(),
    checkPermission(permissions, 'leaves', 'leave_policies', 'view_leave_policies').isFull(),
    !checkPermission(permissions, 'leaves', 'general', 'hr_manual_adjustments').isNone(),
    checkPermission(permissions, 'leaves', 'holidays', 'view_holidays').isFull()
  ]
  const activeTab = tabsPermissions.indexOf(true)
  return leavesTabs[activeTab].href
}

export const leaveStatusFormTitle = (leaveApproval) => {
  let message = 'Approve'
  if (leaveApproval.leave_type === 'ewd') message = message + ' Extra Working Day'
  else if (leaveApproval.leave_type === 'ewh') message = message + ' Extra Working Hours'
  else if (leaveApproval.leave_type === 'wfh') message = message + ' Work From Home'
  else message = message + ' Leave'

  if (leaveApproval.status === 'pending_lead_approval' || leaveApproval.status === 'pending_hr_approval')
    message = message + ` for ${leaveApproval.user.name}`
  else if (
    leaveApproval.status === 'pending_lead_withdrawal_approval' ||
    leaveApproval.status === 'pending_hr_withdrawal_approval'
  )
    message = message + ` Withdraw Request for ${leaveApproval.user.name}`

  return message
}

export const leaveStatusTypes = [
  { label: 'Approved', value: 'approved' },
  { label: 'Pending HR', value: 'pending_hr_approval' },
  { label: 'Pending Lead', value: 'pending_lead_approval' },
  { label: 'Pending PM', value: 'pending_pm_approval' },
  { label: 'Rejected', value: 'rejected' }
]

export const leavesAdminFilters = [
  {
    label: 'Locations',
    value: 'location_ids',
    component: 'selectWrapper',
    placeholder: 'Select Location(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Leave Type',
    value: 'leave_type_ids',
    component: 'selectWrapper',
    placeholder: 'Select Leave Type(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Leave Status',
    value: 'leave_statuses',
    component: 'selectWrapper',
    placeholder: 'Select Leave Status(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Date Range',
    value: 'custom_date_range',
    fromDateValue: 'start_date',
    toDateValue: 'end_date',
    isNotClearable: true,
    component: 'dateRangePickerWrapper'
  }
]

import { styled } from 'maple-storybook'

import { StyledTypography } from 'variables/styles/typographyStyle.jsx'

const Primary = styled(({ children, ...rest }) => <div {...rest}>{children}</div>)(() => ({
  ...StyledTypography.primaryText,
  ...StyledTypography.defaultFontStyle
}))

export default Primary

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const educationDetail = (state = initialState.educationDetail, action) => {
  let options = []
  switch (action.type) {
    case types.FETCH_EDUCATION_MAJOR_SUCCESS: {
      // const newOption = action.payload.flag ? { id: 'add_new', name: 'Add New' } : { id: 'others', name: 'Others' }
      // const newOption = { id: 'Other', name: 'Others' }

      options = [...action.payload.majors]

      return { ...state, educationMajor: options }
    }
    case types.CREATE_EDUCATION_MAJOR_SUCCESS: {
      const newState = { ...state }
      const indexToInsert = newState.educationMajor.length - 1
      newState.educationMajor.splice(indexToInsert, 0, action.payload)

      return newState
    }

    case types.FETCH_EDUCATION_DEGREE_SUCCESS: {
      // const newOption = { id: 'Other', name: 'Others' }

      options = [...action.payload.degrees]

      return { ...state, educationDegree: options }
    }

    case types.CREATE_EDUCATION_DEGREE_SUCCESS: {
      const newState = { ...state }
      const indexToInsert = newState.educationDegree.length - 1
      newState.educationDegree.splice(indexToInsert, 0, action.payload)

      return newState
    }

    default:
      return state
  }
}

// ##############################
// // // StatsCard styles
// #############################
import {
  blueCardHeader,
  card,
  cardActions,
  cardHeader,
  dangerColor,
  defaultFont,
  dodgerblueCardHeader,
  grayCardHeader,
  grayColor,
  greenCardHeader,
  infoColor,
  orangeCardHeader,
  primaryColor,
  purpleCardHeader,
  redCardHeader,
  roseColor,
  royalblueCardHeader,
  successColor,
  warningColor
} from 'variables/styles'

const statsCardStyle = {
  card,
  cardHeader: {
    ...cardHeader,
    float: 'left',
    textAlign: 'center'
  },
  orangeCardHeader,
  greenCardHeader,
  dodgerblueCardHeader,
  royalblueCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  grayCardHeader,
  cardContent: {
    textAlign: 'right',
    padding: '15px 20px',
    minWidth: '150px',
    minHeight: '170px'
  },
  cardIcon: {
    width: '40px !important',
    height: '36px !important',
    fill: '#fff !important'
  },
  cardCategory: {
    marginBottom: '0',
    color: grayColor,
    margin: '65px 0 10px',
    ...defaultFont
  },
  cardTitle: {
    margin: '0',
    ...defaultFont,
    fontSize: '1.625em'
  },
  cardTitleSmall: {
    fontSize: '65%',
    fontWeight: '400',
    lineHeight: '1',
    color: '#777'
  },
  cardActions: {
    ...cardActions,
    padding: '0!important'
  },
  cardStats: {
    lineHeight: '22px',
    color: grayColor,
    fontSize: '12px',
    display: 'inline-flex',
    margin: '0!important'
  },
  cardStatsIcon: {
    position: 'relative !important',
    top: '4px !important',
    width: '16px !important',
    height: '16px !important'
  },
  warningCardStatsIcon: {
    color: warningColor
  },
  primaryCardStatsIcon: {
    color: primaryColor
  },
  dangerCardStatsIcon: {
    color: dangerColor
  },
  successCardStatsIcon: {
    color: successColor
  },
  infoCardStatsIcon: {
    color: infoColor
  },
  roseCardStatsIcon: {
    color: roseColor
  },
  grayCardStatsIcon: {
    color: grayColor
  },
  cardStatsLink: {
    color: primaryColor,
    textDecoration: 'none',
    ...defaultFont
  },
  stateCard: {
    overflow: 'unset'
  }
}

export default statsCardStyle

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const threadType = (state = initialState.threadType, action) => {
  switch (action.type) {
    case types.FETCH_THREAD_TYPES_SUCCESS:
      return { ...state, threadTypes: action.payload }

    default:
      return state
  }
}

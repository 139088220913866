import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const timeTracker = (state = initialState.timeTracker, action) => {
  switch (action.type) {
    case types.SET_TIME_TRACKER_WHITELISTED_USERS_SUCCESS:
      return {
        ...state,
        userIds: action.payload.user,
        titlesList: action.payload.title,
        departmentIds: action.payload.department
      }

    case types.SET_TIME_TRACKER_WHITELISTED_USERS_FAILURE:
      return state

    case types.FETCH_TIME_TRACKER_WHITELISTED_USERS_SUCCESS:
      return {
        ...state,
        userIds: action.payload.user,
        titlesList: action.payload.title,
        departmentIds: action.payload.department
      }

    case types.FETCH_TIME_TRACKER_WHITELISTED_USERS_FAILURE:
      return state

    default:
      return state
  }
}

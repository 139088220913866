import Quill from 'quill'

const Embed = Quill.import('blots/embed')

export default class MentionBlot extends Embed {
  static create(data) {
    const node = super.create()

    const denotationChar = document.createElement('span')

    denotationChar.className = 'ql-mention-denotation-char'

    denotationChar.innerHTML = data.denotationChar

    const dataContainer = document.createElement('span')
    dataContainer.innerHTML = data.value

    const AndroidBackspaceFix = document.createElement('span')
    AndroidBackspaceFix.innerHTML = '&nbsp;'
    AndroidBackspaceFix.setAttribute('style', 'display: inline-block; height: 1px; width: 1px; overflow: hidden; ')

    node.appendChild(denotationChar)
    node.appendChild(dataContainer)
    node.appendChild(AndroidBackspaceFix)

    return MentionBlot.setDataValues(node, data)
  }

  static setDataValues(element, data) {
    setTimeout(() => {
      element.getElementsByTagName('span')[0].setAttribute('contenteditable', 'inherit')
    }, 0)

    const domNode = element
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key]
    })
    return domNode
  }

  static value(domNode) {
    return domNode.dataset
  }

  update(mutations) {
    for (const mutation of mutations) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'contenteditable') continue
      setTimeout(() => this.remove(), 0)
      return
    }
  }
}

MentionBlot.blotName = 'mention'
MentionBlot.tagName = 'span'
MentionBlot.className = 'mention'

import { connect } from 'react-redux'
import { Dialog, DialogActions, DialogTitle, IconButton } from 'maple-storybook'
import { CheckCircleOutline, Close, InfoOutlined } from 'maple-storybook/assets'
import { useReduceTime } from 'maple-storybook/utils'

import { HIDE_MODAL } from 'actions/modal'
import { Button } from 'components'

import './modalStyles.scss'

const ConfirmationModal = ({
  id,
  handleClose,
  open,
  resourceType,
  title,
  cancelAction,
  cancelResource,
  waitCounterSeconds,
  closeModal,
  deleteResource,
  okBtnText,
  cancelBtnText,
  appBarStyle = '',
  closeBtn,
  alertModal,
  body,
  message,
  messageTextAlignment
}) => {
  const waitTime = useReduceTime(waitCounterSeconds)

  const closeModalAndCallback = () => {
    closeModal()
    handleClose && handleClose()
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeModalAndCallback}
        disableBackdropClick={true}
        disableEscapeKeyDown={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`confirmation-modal success-text ${appBarStyle}`}>
        <DialogTitle
          data-testid={id && `${id}-title`}
          id="alert-dialog-title"
          disableTypography
          className="align-items-center flex-column">
          {closeBtn && (
            <IconButton color="inherit" onClick={() => closeModal()} aria-label="Close" className="margin-left-auto">
              <Close />
            </IconButton>
          )}
          <div className="modal-mobile-view d-flex align-iems-center">
            <div className="text-center d-flex flex-column align-iems-center margin-top-description">
              <div className="alert-description">
                {!body && (
                  <div className="modal-icon d-flex">
                    {alertModal ? (
                      <InfoOutlined className="red-alert-icon" />
                    ) : (
                      <CheckCircleOutline className="alert-icon" />
                    )}
                  </div>
                )}
                {title ? title : !body && `Are you sure you want to delete this ${resourceType}?`}
              </div>
              {message && (
                <div className={`alert-message mt-1 ${messageTextAlignment ?? 'text-align-center'}`}>{message}</div>
              )}
              {body && <div className="mt-1">{body}</div>}
            </div>
          </div>
        </DialogTitle>
        <DialogActions data-testid={id && `${id}-actions`} className="mb-1 action-btns">
          <Button
            variant={closeBtn ? 'primary' : 'secondaryGray'}
            onClick={
              cancelAction
                ? () => {
                    cancelResource(cancelAction)
                  }
                : closeModalAndCallback
            }>
            {cancelBtnText ?? 'Cancel'}
          </Button>
          <Button
            onClick={deleteResource.bind(this)}
            autoFocus
            disabled={waitTime > 0}
            variant={`${alertModal ? 'dangerPrimary' : 'primary'}`}>
            {okBtnText ?? 'YES, CONFIRM'}
            {waitTime > 0 ? ` (${waitTime})` : ''}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteResource: () => {
      dispatch(ownProps.onConfirmation)
      dispatch(HIDE_MODAL)
    },
    cancelResource: (action) => {
      dispatch(action)
      dispatch(HIDE_MODAL)
    },
    closeModal: () => {
      dispatch(HIDE_MODAL)
    }
  }
}

export default connect(null, mapDispatchToProps)(ConfirmationModal)

import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const industry = (state = initialState.industries, action) => {
  switch (action.type) {
    case types.FETCH_INDUSTRIES_SUCCESS:
      return { ...state, industries: action.payload }

    case types.FETCH_INDUSTRIES_FAILURE:
      return state

    default:
      return state
  }
}

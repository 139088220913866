import { DialogDatePicker } from 'maple-storybook'
import moment from 'moment'

import { Danger } from 'components'
import { DATE_FORMAT } from 'helpers/constants'

import './DatepickerWrapper.scss'

const DatePickerWrapper = ({ input, meta, minDate, maxDate, dateContainerStyle, helpText, displayFormat, ...rest }) => {
  let valueToUse = input.value ? moment(input.value) : null

  return (
    <div className={`flex-column ${meta ? 'date-picker-container-style' : ''} ${dateContainerStyle}`}>
      <DialogDatePicker
        value={valueToUse}
        isValue={Boolean(valueToUse)}
        closeOnSelect={true}
        error={meta?.touched && meta.error}
        onChange={(event) => input.onChange(event ? event.format('YYYY-MM-DD') : null)}
        maxDate={maxDate ? moment(maxDate) : ''}
        format={displayFormat || DATE_FORMAT}
        minDate={minDate ? moment(minDate) : ''}
        {...rest}
      />
      {meta?.touched && meta?.error && <Danger>{meta.error}</Danger>}
      {helpText ? <small style={{ color: 'blue' }}>{helpText}</small> : null}
    </div>
  )
}

export default DatePickerWrapper

import { generateAuthActions } from 'redux-token-auth'

import { AUTH_URL } from 'config/apiConfig'

const config = {
  authUrl: AUTH_URL,
  userAttributes: {
    id: 'id',
    email: 'email',
    first_name: 'first_name',
    last_name: 'last_name',
    role_id: 'role_id',
    reporting_to: 'reporting_to',
    buddy_id: 'buddy_id',
    permanent_address: 'permanent_address',
    password: 'password',
    username: 'username',
    department_id: 'department_id',
    employment_history: 'employment_history',
    email_schedule: 'email_schedule',
    fields_fill: 'fields_fill',
    emergency_contact_person_number: 'emergency_contact_person_number',
    emergency_contact_person_relation: 'emergency_contact_person_relation',
    emergency_contact_person_name: 'emergency_contact_person_name',
    image: 'image',
    dob: 'dob',
    secondary_contact_number: 'secondary_contact_number'
  },
  userRegistrationAttributes: {
    email: 'email',
    first_name: 'first_name',
    last_name: 'last_name',
    role_id: 'role_id',
    reporting_to: 'reporting_to',
    buddy_id: 'buddy_id',
    permanent_address: 'permanent_address',
    emergency_contact_person_number: 'emergency_contact_person_number',
    password: 'password',
    username: 'username',
    department_id: 'department_id',
    employment_history: 'employment_history',
    email_schedule: 'email_schedule'
  }
}

export const { registerUser, signInUser, signOutUser, verifyCredentials } = generateAuthActions(config)

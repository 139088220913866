import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const dnar = (state = initialState.dnars, action) => {
  let policyIndex, policies

  switch (action.type) {
    case types.FETCH_USER_DNARS_BANK_SUCCESS:
      return { ...state, dnarsBank: action.payload, pointsAllocationRequested: false }

    case types.FETCH_USER_DNARS_BANK_FAILURE:
      return state

    case types.FETCH_DNAR_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload }

    case types.FETCH_DNAR_CATEGORIES_FAILURE:
      return state

    case types.FETCH_PM_SUBORDINATES_SUCCESS:
      return { ...state, subordinates: action.payload }

    case types.FETCH_PM_SUBORDINATES_FAILURE:
      return state

    case types.CREATE_POINTS_ALLOCATION_REQUEST_SUCCESS:
      return { ...state, pointsAllocationRequested: true }

    case types.CREATE_POINTS_ALLOCATION_REQUEST_FAILURE:
      return { ...state, pointsAllocationRequested: false }

    case types.FETCH_PM_FUND_HISTORY_SUCCESS:
      return { ...state, fundHistory: action.payload }

    case types.FETCH_PM_FUND_HISTORY_FAILURE:
      return state

    case types.FETCH_PM_RECEIVE_HISTORY_SUCCESS:
      return { ...state, receiveHistory: action.payload }

    case types.FETCH_PM_RECEIVE_HISTORY_FAILURE:
      return state

    case types.FETCH_PERSONAL_DNARS_HISTORY_SUCCESS:
      return { ...state, personalDnarsHistory: action.payload }

    case types.FETCH_PERSONAL_DNARS_HISTORY_FAILURE:
      return state

    case types.FETCH_PERSONAL_DNARS_STATS_SUCCESS:
      return { ...state, personalDnarsStats: action.payload }

    case types.FETCH_PERSONAL_DNARS_STATS_FAILURE:
      return state

    case types.FETCH_REDEEM_DNARS_POLICIES_SUCCESS:
      return { ...state, redeemPolicies: action.payload }

    case types.FETCH_REDEEM_DNARS_POLICIES_FAILURE:
      return state

    case types.DELETE_REDEEM_DNARS_POLICY_SUCCESS:
      if (action.payload.id) {
        policyIndex = state.redeemPolicies.findIndex((policy) => policy.id === action.payload.id)
      }

      if (policyIndex === null) return state

      policies = [...state.redeemPolicies]
      policies.splice(policyIndex, 1)

      return { ...state, redeemPolicies: policies }

    case types.DELETE_REDEEM_DNARS_POLICY_FAILURE:
      return state

    case types.UPDATE_REDEEM_DNARS_POLICY_SUCCESS:
      policyIndex = state.redeemPolicies.findIndex((policy) => policy.id === action.payload.id)

      policies = [...state.redeemPolicies]
      policies[policyIndex] = action.payload

      return { ...state, redeemPolicies: policies }

    case types.UPDATE_REDEEM_DNARS_POLICY_FAILURE:
      return state

    case types.CREATE_REDEEM_DNARS_POLICY_SUCCESS:
      return { ...state, redeemPolicies: [...state.redeemPolicies, action.payload] }

    case types.CREATE_REDEEM_DNARS_POLICY_FAILURE:
      return state

    default:
      return state
  }
}

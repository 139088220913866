import { checkPermission } from './permissionsHelper'

export const isAccountOwner = (permissions) =>
  checkPermission(permissions, 'people', 'users', 'account_owner', true).isFull()

export const invitePeople = (permissions) =>
  checkPermission(permissions, 'people', 'users', 'invite_people', true).isFull()

export const editOtherProfile = (permissions) =>
  checkPermission(permissions, 'people', 'users', 'edit_profile_of_others', true).isFull()

export const disableEndingDate = (initialValues) => initialValues.ending_date === null

export const disableExitReason = (initialValues) => initialValues.exit_reason === undefined

export const disableResignationDate = (initialValues) => initialValues.resignation_date === null

export const disableTentativeEndingDate = (initialValues) => initialValues.tentative_ending_date === null

export const disableResignationType = (initialValues) => initialValues.resignation_type === null

export const disableBankNumber = (initialValues) => initialValues.iban === null

export const disableBankTitle = (initialValues) => initialValues.account_title === null

export const userEditProfile = (variant) => variant === 'initial-details' || variant === 'edit-profile'

export const initialEditProfile = (variant) => variant === 'edit-user-profile' || variant === 'edit-profile'

export const editProfile = (variant) => variant === 'initial-details' || variant === 'edit-user-profile'

export const probation_status_text = (getProbationStatusText, confirmation_date, remaining_period, overdue_period) =>
  getProbationStatusText(confirmation_date, remaining_period, overdue_period)

export const bankAccountValidation = (editProfile, disableBankNumber, shouldDisableAccountNumber, initialEditProfile) =>
  ((!editProfile || !disableBankNumber) && !shouldDisableAccountNumber ? true : null) ??
  (!initialEditProfile && !shouldDisableAccountNumber)

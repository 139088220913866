import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const title = (state = initialState.titles, action) => {
  let titles = []
  let index = 0

  switch (action.type) {
    case types.FETCH_TITLES_SUCCESS:
      return { ...state, allTitles: action.payload }

    case types.FETCH_TITLES_FAILURE:
      return state

    case types.UPDATE_TITLE_SUCCESS:
      titles = [...state.allTitles]

      index = state.allTitles.findIndex((title) => title.id === action.payload.id)
      titles[index] = action.payload

      return { ...state, allTitles: [...titles] }

    case types.UPDATE_TITLE_FAILURE:
      return state

    case types.FETCH_TITLE_LOG_SUCCESS:
      return { ...state, titleLogs: action.payload }

    case types.FETCH_TITLE_LOG_FAILURE:
      return state

    default:
      return state
  }
}

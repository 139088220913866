import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'

import initialState from '../reducers/initialState'
import rootReducer from '../reducers/rootReducer'

export default function configureStore() {
  /* eslint-disable no-underscore-dangle */
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )
  )
  /* eslint-enable */
}

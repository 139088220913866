import update from 'immutability-helper'

import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const leave = (state = initialState.leaves, action) => {
  let leaveInd, leaveIndex, holidayIndex, leaveType, userLeaves, currentUserLeaves

  switch (action.type) {
    case types.SET_LEAVE_APPROVALS_PAGE_SIZE:
      return { ...state, pageSize: action.payload }

    case types.FETCH_LEAVES_SUCCESS:
      return { ...state, currentUserLeaves: action.payload }

    case types.FETCH_LEAVES_FAILURE:
      return state

    case types.FETCH_LEAVE_APPROVALS_SUCCESS:
      return { ...state, currentUserLeaveApprovals: action.payload, leaveLoader: false }

    case types.FETCH_LEAVE_APPROVALS_FAILURE:
      return state

    case types.CREATE_LEAVE_SUCCESS:
      return { ...state, currentUserLeaves: state.currentUserLeaves.concat(action.payload) }

    case types.CREATE_LEAVE_FAILURE:
      return state

    case types.CREATE_LEAVE_STATUS_SUCCESS:
      /* eslint-disable no-case-declarations */
      let i = 0
      for (i; i < state.currentUserLeaveApprovals.length; i++) {
        if (state.currentUserLeaveApprovals[i].id === action.payload.leave_id) {
          break
        }
      }
      return update(state, {
        currentUserLeaveApprovals: {
          [i]: {
            status: { $set: action.payload.status },
            comment: { $set: action.payload.comment }
          }
        }
      })
    /* eslint-enable no-case-declarations */

    case types.CREATE_LEAVE_STATUS_FAILURE:
      return state

    case types.CREATE_HR_LEAVE_STATUS_SUCCESS:
      leaveInd = state.allManuallyAdjustedLeaves.findIndex((leave) => leave.id === action.payload.leave_id)

      return update(state, {
        allManuallyAdjustedLeaves: {
          [leaveInd]: {
            status: { $set: action.payload.status },
            deleteable: { $set: false }
          }
        }
      })

    case types.CREATE_HR_LEAVE_STATUS_FAILURE:
      return state

    case types.CREATE_LEAVE_STATUS_FOR_APPLICANT_SUCCESS: {
      leaveIndex = state.currentUserLeaves.findIndex((leave) => leave.id === action.payload.leave_id)

      const cancellable = state.currentUserLeaves[leaveIndex].status === 'approved' ? true : false
      return update(state, {
        currentUserLeaves: {
          [leaveIndex]: {
            withdraw_cancellable: { $set: cancellable },
            status: { $set: action.payload.status },
            comment: { $set: action.payload.comment }
          }
        }
      })
    }

    case types.CREATE_LEAVE_STATUS_FOR_APPLICANT_FAILURE:
      return state

    case types.FETCH_USER_LEAVES_HISTORY_SUCCESS:
      if (action.payload.leave_type) {
        let userLeaves = [...state.allUserLeavesSummary[action.payload.user_id].leave_stats]
        let leaveCardIndex = userLeaves.findIndex(
          (leave) => leave.leave_type_id === action.payload.leave_type.leave_type_id
        )
        if (leaveCardIndex !== -1) {
          userLeaves[leaveCardIndex] = action.payload.leave_type
        }

        const leaveHistory = { ...state.allUserLeavesSummary[action.payload.user_id], leave_stats: userLeaves }
        return {
          ...state,
          allUserLeavesSummary: { ...state.allUserLeavesSummary, [action.payload.user_id]: leaveHistory },
          leaveLoader: false
        }
      } else {
        return {
          ...state,
          allUserLeavesSummary: { ...state.allUserLeavesSummary, [action.payload.user_id]: action.payload },
          leaveLoader: false
        }
      }
    case types.FETCH_USER_LEAVES_HISTORY_FAILURE:
      return state

    case types.FETCH_USER_LEAVE_HISTORY_SUCCESS:
      return { ...state, leaveTypeDetail: action.payload, leaveLoader: false }

    case types.FETCH_USER_LEAVE_HISTORY_FAILURE:
      return state

    case types.CLEAR_USER_LEAVES_HISTORY:
      return {
        ...state,
        allUserLeavesSummary: { ...state.allUserLeavesSummary, [action.payload]: null }
      }
    case types.FETCH_LEAVE_LIFECYCLE_SUCCESS:
      return {
        ...state,
        allLeavesLifecycle: action.payload
      }

    case types.FETCH_LEAVE_LIFECYCLE_FAILURE:
      return state

    case types.FETCH_ALL_LEAVES_SUMMARY_SUCCESS:
      return { ...state, allLeavesSummary: action.payload, leaveLoader: false }

    case types.FETCH_ALL_LEAVES_SUMMARY_FAILURE:
      return state

    case types.SET_LEAVES_TAB:
      return { ...state, tab: action.payload.tab }

    case types.FETCH_ALL_USERS_LEAVES_HISTORY_SUCCESS:
      return {
        ...state,
        allUsersLeavesHistory: { ...state.allUsersLeavesHistory, [action.payload[0]?.user.id]: action.payload }
      }

    case types.FETCH_ALL_USERS_LEAVES_HISTORY_FAILURE:
      return state

    case types.DELETE_LEAVE_SUCCESS:
      currentUserLeaves = [...state.currentUserLeaves]
      action.payload.id.forEach((leaveId) => {
        currentUserLeaves = currentUserLeaves.filter((leave) => leave.id !== leaveId)
      })

      return {
        ...state,
        currentUserLeaves: currentUserLeaves,
        allManuallyAdjustedLeaves: [
          ...state.allManuallyAdjustedLeaves.filter((leave) => leave.id !== action.payload.id)
        ]
      }

    case types.DELETE_LEAVE_FAILURE:
      return state

    case types.FETCH_MANUALLY_ADJUSTED_LEAVES_SUCCESS:
      return { ...state, allManuallyAdjustedLeaves: action.payload, leaveLoader: false }

    case types.FETCH_MANUALLY_ADJUSTED_LEAVES_FAILURE:
      return { ...state, leaveLoader: false }

    case types.SHOW_HOLIDAY_LOADER:
      return { ...state, Loader: true }

    case types.CREATE_HOLIDAY_SUCCESS:
      if (action.payload instanceof Array) return { ...state, allHolidays: [...state.allHolidays, ...action.payload] }
      else return { ...state, allHolidays: [...state.allHolidays, action.payload] }

    case types.CREATE_HOLIDAY_FAILURE:
      return state

    case types.FETCH_HOLIDAYS_SUCCESS:
      return { ...state, allHolidays: action.payload, leaveLoader: false }

    case types.FETCH_HOLIDAYS_FAILURE:
      return { ...state, leaveLoader: false }

    case types.DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        allHolidays: state.allHolidays.filter((holiday) => holiday.id !== action.payload.id)
      }

    case types.DELETE_HOLIDAY_FAILURE:
      return state

    case types.UPDATE_HOLIDAY_SUCCESS:
      holidayIndex = state.allHolidays.findIndex((holiday) => holiday.id === action.payload.id)

      return update(state, {
        allHolidays: {
          [holidayIndex]: { $set: action.payload }
        }
      })

    case types.UPDATE_HOLIDAY_FAILURE:
      return state

    case types.UPDATE_LEAVE_SUCCESS:
      leaveIndex = state.currentUserLeaveApprovals.findIndex((leave) => leave.id === action.payload.id)

      return update(state, {
        currentUserLeaveApprovals: {
          [leaveIndex]: { $set: action.payload }
        }
      })

    case types.UPDATE_LEAVE_FAILURE:
      return state

    case types.FETCH_UPCOMING_HOLIDAYS_SUCCESS:
      return { ...state, upcomingHolidays: action.payload }

    case types.FETCH_UPCOMING_HOLIDAYS_FAILURE:
      return state

    case types.FETCH_RANGE_LEAVES_SUCCESS:
      return { ...state, rangeLeaves: action.payload }

    case types.FETCH_RANGE_LEAVES_FAILURE:
      return state

    case types.CLEAR_RANGE_LEAVES:
      return { ...state, rangeLeaves: [] }

    case types.SHOW_LEAVE_LOADER:
      return { ...state, leaveLoader: true }

    case types.HIDE_LEAVE_LOADER:
      return { ...state, leaveLoader: false }

    case types.CREATE_MANUAL_LEAVE_SUCCESS:
      return { ...state, allManuallyAdjustedLeaves: state.allManuallyAdjustedLeaves.concat(action.payload) }

    case types.CREATE_MANUAL_LEAVE_FAILURE:
      return state

    case types.FETCH_LEAVE_TYPES_SUCCESS:
      return { ...state, leaveTypes: action.payload, leaveLoader: false }

    case types.FETCH_LEAVE_TYPES_FAILURE:
      return state

    case types.FETCH_LEAVE_TYPE_SUCCESS:
      return { ...state, leaveType: action.payload, leaveLoader: false }

    case types.FETCH_LEAVE_TYPE_FAILURE:
      return state

    case types.CREATE_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        leaveTypes: [...state.leaveTypes, action.payload]
      }
    case types.CREATE_LEAVE_TYPE_FAILURE:
      return state

    case types.UPDATE_LEAVE_TYPE_SUCCESS:
      leaveIndex = state.leaveTypes.findIndex((leave) => leave.id === action.payload.id)

      return update(state, {
        leaveTypes: {
          [leaveIndex]: { $set: action.payload }
        }
      })
    case types.UPDATE_LEAVE_TYPE_FAILURE:
      return state
    case types.DELETE_LEAVE_TYPE_SUCCESS:
      leaveType = [...state.leaveTypes]

      if (action.payload.id) {
        leaveIndex = state.leaveTypes.findIndex((leave) => leave.id === action.payload.id)
      }

      leaveType.splice(leaveIndex, 1)

      return { ...state, leaveTypes: [...leaveType] }
    case types.DELETE_LEAVE_TYPE_FAILURE:
      return state

    case types.APPLY_LEAVE_ENCASHMENT_ROLLOVER_SUCCESS:
      leaveInd = action.payload.encashed ? action.payload.leave_type.id : action.payload.leave_type_id
      userLeaves = [
        ...state.allUserLeavesSummary[action.payload.encashed ? action.payload.user.id : action.payload.user_id]
          .leave_stats
      ]
      leaveIndex = userLeaves.findIndex((leave) => leave.leave_type_id === leaveInd)

      if (leaveIndex !== -1) {
        userLeaves[leaveIndex].leaves_encashable_or_rolloverable = false
      }

      currentUserLeaves = [...state.currentUserLeaves]

      if (action.payload.encashed) {
        currentUserLeaves = [...state.currentUserLeaves, action.payload]
      }

      return {
        ...state,
        allUserLeavesSummary: {
          ...state.allUserLeavesSummary,
          [action.payload.user_id]: {
            ...state.allUserLeavesSummary[action.payload.user_id],
            leave_stats: userLeaves
          }
        },
        currentUserLeaves: currentUserLeaves,
        leaveLoader: false
      }

    case types.APPLY_LEAVE_ENCASHMENT_ROLLOVER_FAILURE:
      return state

    default:
      return state
  }
}

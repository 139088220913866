import {
  approvedChipColor,
  cancelledChipColor,
  lightGrayChipColor,
  pendingApprovalByHrChipColor,
  pendingApprovalByLeadChipColor,
  pendingApprovalByPmChipColor,
  pendingWithdrawalApprovalByHrChipColor,
  pendingWithdrawalApprovalByLeadChipColor,
  pendingWithdrawalApprovalByPmChipColor,
  rejectedChipColor,
  whiteChipColor
} from 'variables/styles'

export const leaveStyle = {
  bold: {
    fontWeight: 'bold'
  },

  center: {
    textAlign: 'center'
  },

  whiteChip: {
    backgroundColor: whiteChipColor
  },

  lightGrayChip: {
    backgroundColor: lightGrayChipColor
  },

  right: {
    float: 'right'
  },

  rightIcon: {
    marginLeft: '10px'
  },

  background: {
    backgroundColor: '#ffffff'
  },

  imageContainer: {
    marginRight: 200
  },

  image: {
    width: 100,
    marginRight: '1rem',
    objectFit: 'contain',
    height: 60
  },

  border: {
    borderBottom: '2px solid #eee',
    backgroundColor: '#ffffff'
  },

  avatar: {
    margin: 2
  },
  leaveStatusBtns: {
    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'column',
      '& .show-stat-btn': {
        width: '100%'
      }
    }
  },
  tooltip: {
    fontSize: '15px'
  },

  grid: {
    textAlign: 'justify',
    overflowWrap: 'break-word',
    wordWrap: 'break-word'
  },

  pendingWithdrawalApprovalByLeadChip: {
    backgroundColor: pendingWithdrawalApprovalByLeadChipColor,
    color: '#8c811e'
  },

  rightMargin: {
    marginRight: '45px'
  },

  pendingApprovalByLeadChip: {
    backgroundColor: pendingApprovalByLeadChipColor,
    color: '#B54708'
  },

  pendingApprovalByPmChip: {
    backgroundColor: pendingApprovalByPmChipColor,
    color: '#0a7649'
  },

  pendingApprovalByHrChip: {
    backgroundColor: pendingApprovalByHrChipColor,
    color: '#17a940'
  },

  pendingWithdrawalApprovalByLeadChipColor: {
    backgroundColor: pendingWithdrawalApprovalByLeadChipColor
  },

  pendingWithdrawalApprovalByHrChip: {
    backgroundColor: pendingWithdrawalApprovalByHrChipColor,
    color: '#3b8b3a'
  },

  pendingWithdrawalApprovalByPmChip: {
    backgroundColor: pendingWithdrawalApprovalByPmChipColor,
    color: '#8b5400'
  },

  rejectedChip: {
    backgroundColor: rejectedChipColor,
    color: '#902424'
  },

  cancelledChip: {
    backgroundColor: cancelledChipColor,
    color: '#9A0B0B'
  },

  approvedChip: {
    backgroundColor: approvedChipColor,
    color: '#02651E'
  },

  tCell: {
    lineHeight: '1.42857143',
    padding: '4px 5px',
    verticalAlign: 'middle'
  },

  cardBackground: {
    backgroundColor: 'whitesmoke'
  },

  boldHeading: {
    color: 'black'
  }
}

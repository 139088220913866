import { toastr } from 'react-redux-toastr'

import { errorsAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'

export const createQuestionSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_QUESTION_SUCCESS,
    payload
  })

  toastr.success('Question Created Successfully!')
}

export const createQuestionFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_QUESTION_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Create Question!')
}

export const fetchQuestionsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_QUESTION_SUCCESS,
    payload
  })
  dispatch(hideQuestionLoader)
}

export const fetchQuestionsFailure = (payload) => ({
  type: types.FETCH_QUESTION_FAILURE,
  payload
})

export const fetchQuestionsByIdSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_QUESTION_BY_ID_SUCCESS,
    payload
  })
}

export const fetchQuestionsByIdFailure = (payload) => ({
  type: types.FETCH_QUESTION_BY_ID_FAILURE,
  payload
})

export const deleteQuestionSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_QUESTION_SUCCESS,
    payload
  })

  toastr.success('Question Deleted Successfully!')
}

export const deleteQuestionFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_QUESTION_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Delete Question!')
}

export const updateQuestionSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_QUESTION_SUCCESS,
    payload
  })

  toastr.success('Question Updated Successfully!')
}

export const updateQuestionFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_QUESTION_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to Update Question!')
}

export const showQuestionLoader = {
  type: types.SHOW_QUESTION_LOADER
}

export const hideQuestionLoader = {
  type: types.HIDE_QUESTION_LOADER
}

import { styled, Typography } from 'maple-storybook'

import { StyledTypography } from 'variables/styles/typographyStyle.jsx'

const Muted = styled(({ children, classStyle, ...rest }) => (
  <Typography type="semibold" variant="body1" {...rest} className={classStyle}>
    {children}
  </Typography>
))(() => ({
  ...StyledTypography.mutedText,
  ...StyledTypography.defaultFontStyle
}))

export default Muted

import * as types from 'actions/actionTypes'
import { checkArrayPresence } from 'helpers/applicationHelper'

import initialState from './initialState'

export const teamMember = (state = initialState.teamMembers, action) => {
  let updatedTeamMembers
  switch (action.type) {
    case types.FETCH_TEAM_MEMBERS_SUCCESS:
      return { ...state, teamMembers: action.payload }

    case types.FETCH_TEAM_MEMBERS_FAILURE:
      return state

    case types.UPDATE_TEAM_MEMBERS_SUCCESS:
      updatedTeamMembers = action.payload

      return {
        ...state,
        teamMembers: state.teamMembers.map((member) => {
          const updatedMember = updatedTeamMembers.find((updatedMember) => updatedMember.id === member.id)
          return updatedMember ? { ...member, ...updatedMember } : member
        })
      }

    case types.UPDATE_TEAM_MEMBERS_FAILURE: {
      const { errors } = action.payload
      const updatedTeamMembersWithError = state.teamMembers.map((member) => {
        if (checkArrayPresence(errors[member.id])) {
          return {
            ...member,
            error: errors[member.id]
          }
        }
        return member
      })
      return { ...state, teamMembers: updatedTeamMembersWithError }
    }

    default:
      return state
  }
}

import { styled } from 'maple-storybook'

import { StyledTypography } from 'variables/styles/typographyStyle.jsx'

const Small = styled(({ children, ...rest }) => <div {...rest}>{children}</div>)(() => ({
  ...StyledTypography.smallText,
  ...StyledTypography.defaultFontStyle
}))

export default Small

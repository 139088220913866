import { forwardRef } from 'react'
import { connect } from 'react-redux'
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from 'maple-storybook'
import { Close } from 'maple-storybook/assets'

import { HIDE_MODAL } from 'actions/actionTypes'

import './modalStyles.scss'

const ContentModal = ({
  content,
  fullscreen,
  handleClose,
  open,
  stickyAppBar,
  title,
  width,
  appBarStyle,
  custom = false,
  hideTitleBar = false,
  customTitle
}) => {
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={width || 'md'}
      disableEscapeKeyDown={false}
      fullScreen={fullscreen ? fullscreen : false}
      TransitionComponent={Transition}
      className="modal-dialog">
      {!hideTitleBar && (
        <AppBar className={`${stickyAppBar ? 'modal-app-bar-sticky' : 'modal-app-bar'} ${appBarStyle}`}>
          <Toolbar>
            {title && (
              <Typography variant="h6" color="inherit" className={`modal-flex ${width ? 'ml-1-half' : ''}`}>
                {title}
              </Typography>
            )}
            {customTitle && (
              <Typography variant="text-lg" color="gray-900" className="modal-flex">
                {customTitle}
              </Typography>
            )}
            {!custom && (
              <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                <Close />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      )}
      {content}
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: (_event, reason) => {
    if (ownProps.preventBackDropClose && reason && reason === 'backdropClick') return

    dispatch({ type: HIDE_MODAL })
  }
})

export default connect(null, mapDispatchToProps)(ContentModal)

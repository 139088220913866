import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const adminPanel = (state = initialState.adminPanel, action) => {
  let activeTransactions

  switch (action.type) {
    case types.FETCH_CENTRAL_DNARS_BANK_SUCCESS:
      return { ...state, centralDnarsBank: action.payload, transferRequestCreated: false }

    case types.FETCH_CENTRAL_DNARS_BANK_FAILURE:
      return state

    case types.FETCH_ALL_USERS_DNARS_SUCCESS:
      return { ...state, allUserDnars: action.payload }

    case types.FETCH_ALL_USERS_DNARS_FAILURE:
      return state

    case types.PRESERVE_MINTING_FORM_VALUES:
      return { ...state, formValues: action.payload }

    case types.GENERATE_MINTING_AUTH_SUCCESS:
    case types.UPDATE_AUTH_TOKEN_SUCCESS:
      return { ...state, authToken: action.payload.token, authTokenId: action.payload.token_id }

    case types.GENERATE_MINTING_AUTH_FAILURE:
    case types.UPDATE_AUTH_TOKEN_FAILURE:
      return { ...state, authToken: action.payload.token, authTokenId: action.payload.token_id }

    case types.VALIDATE_AUTH_TOKEN_SUCCESS:
      return { ...state, tokenValidated: true, authToken: action.payload }

    case types.VALIDATE_AUTH_TOKEN_FAILURE:
      return state

    case types.CREATE_MINTING_REQUEST_SUCCESS:
      return {
        ...state,
        tokenValidated: action.payload.tokenValidated,
        mintingRequestCreated: true,
        authToken: undefined,
        authTokenId: undefined,
        formValues: {}
      }

    case types.CREATE_MINTING_REQUEST_FAILURE:
      return {
        ...state,
        tokenValidated: action.payload.tokenValidated,
        mintingRequestCreated: false,
        authToken: action.payload.token,
        authTokenId: action.payload.token_id,
        formValues: {}
      }

    case types.FETCH_MINTING_HISTORY_SUCCESS:
      return { ...state, mintingHistory: action.payload, mintingRequestCreated: false }

    case types.FETCH_MINTING_HISTORY_FAILURE:
      return state

    case types.FETCH_PENDING_TRANSACTIONS_SUCCESS:
      return { ...state, activeTransactions: action.payload }

    case types.FETCH_PENDING_TRANSACTIONS_FAILURE:
      return state

    case types.FETCH_ROLLBACK_HISTORY_SUCCESS:
      return { ...state, rollbackHistory: action.payload }

    case types.FETCH_ROLLBACK_HISTORY_FAILURE:
      return state

    case types.FETCH_USER_TRANSACTIONS_SUCCESS:
      return { ...state, userTransactions: action.payload }

    case types.FETCH_USER_TRANSACTIONS_FAILURE:
      return state

    case types.UPDATE_TRANSACTION_SUCCESS:
      activeTransactions = state.activeTransactions
      activeTransactions = activeTransactions.filter((transaction) => transaction.id !== action.payload.id)

      return {
        ...state,
        activeTransactions: activeTransactions,
        processedTransactions: [action.payload, ...state.processedTransactions]
      }

    case types.UPDATE_TRANSACTION_FAILURE:
      return state

    case types.FETCH_PROCESSED_TRANSACTIONS_SUCCESS:
      return { ...state, processedTransactions: action.payload }

    case types.FETCH_PROCESSED_TRANSACTIONS_FAILURE:
      return state

    case types.CREATE_DNARS_TRANSFER_REQUEST_SUCCESS:
      return { ...state, transferRequestCreated: true }

    case types.CREATE_DNARS_TRANSFER_REQUEST_FAILURE:
      return state

    default:
      return state
  }
}

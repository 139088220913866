import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const claimType = (state = initialState.claimTypes, action) => {
  switch (action.type) {
    case types.FETCH_CLAIM_TYPES_DETAILS_SUCCESS:
      return { ...state, claimTypesDetail: action.payload }
    case types.FETCH_CLAIM_TYPES_SUCCESS:
      return { ...state, claimTypes: action.payload }
    case types.FETCH_CLAIM_TYPES_FAILURE:
      return state
    case types.FETCH_CLAIM_TYPE_SUCCESS:
      return { ...state, claimType: action.payload }
    case types.FETCH_CLAIM_TYPE_FAILURE:
      return state
    case types.UPDATE_CLAIM_TYPE_SUCCESS:
      return { ...state, updatedClaimType: action.payload }
    case types.SHOW_CLAIM_TYPE_LOADER:
      return { ...state, claimTypeLoader: true }

    case types.HIDE_CLAIM_TYPE_LOADER:
      return { ...state, claimTypeLoader: false }

    case types.SHOW_CLAIM_TYPE_FORM:
      return { ...state, displayClaimTypeForm: true }
    case types.HIDE_CLAIM_TYPE_FORM:
      return { ...state, displayClaimTypeForm: false }
    default:
      return state
  }
}

import { toastr } from 'react-redux-toastr'

import { errorsAlert } from 'helpers/applicationHelper'

import {
  CREATE_BUSINESS_UNITS_SUCCESS,
  CREATE_EMPLOYMENT_COMPANIES_FAILURE,
  CREATE_EMPLOYMENT_COMPANIES_SUCCESS,
  FETCH_BUSINESS_UNITS_SUCCESS,
  FETCH_CURRENT_COMPANY_FAILURE,
  FETCH_CURRENT_COMPANY_SUCCESS,
  FETCH_EMPLOYMENT_COMPANIES_FAILURE,
  FETCH_EMPLOYMENT_COMPANIES_SUCCESS,
  FETCH_TEAM_MEMBERS_SUCCESS,
  UPDATE_COMPANY_LOGO_SUCCESS
} from './actionTypes'

export const fetchCompaniesSuccess = (payload) => ({
  type: FETCH_EMPLOYMENT_COMPANIES_SUCCESS,
  payload
})

export const fetchCompaniesFailure = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_EMPLOYMENT_COMPANIES_FAILURE,
    payload
  })
  toastr.error('Failed to fetch companies!')
}

export const createCompanySuccess = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_EMPLOYMENT_COMPANIES_SUCCESS,
    payload
  })

  toastr.success('Company Added Successfully!')
}

export const createCompanyFailure = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_EMPLOYMENT_COMPANIES_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to create company')
}

export const fetchCurrentCompanySuccess = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CURRENT_COMPANY_SUCCESS,
    payload
  })
}

export const fetchCurrentCompanyFailure = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CURRENT_COMPANY_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to fetch company')
}

export const updateCompanyLogoSuccess = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_LOGO_SUCCESS,
    payload
  })
  if (payload.people_excellence_department_changed) {
    dispatch({
      type: FETCH_TEAM_MEMBERS_SUCCESS,
      payload: []
    })
  }
  toastr.success('Company settings updated Successfully!')
}

export const updateCompanyLogoFailure = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CURRENT_COMPANY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update company logo')
}

export const createEmployeeGroupsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_BUSINESS_UNITS_SUCCESS,
    payload
  })
  toastr.success('Business Unit Created Successfully!')
}

export const createEmployeeGroupsFailure = (payload) => {
  errorsAlert(payload)
}

export const fetchEmployeeGroupsSuccess = (payload) => ({
  type: FETCH_BUSINESS_UNITS_SUCCESS,
  payload
})

export const fetchEmployeeGroupsFailure = () => {
  toastr.error('Failed to fetch Business Units!')
}

import { Component } from 'react'
import { connect } from 'react-redux'
import reduxFormTouchOnsubmit from 'hocs/reduxFormTouchOnsubmit'
import { isEqual } from 'lodash'
import { ButtonStack, Grid, Tooltip, Typography } from 'maple-storybook'
import { Info, InfoRounded } from 'maple-storybook/assets'
import { RCDropzone } from 'maple-storybook/dropzone'
import moment from 'moment'
import { change, clearFields, Field, getFormValues, initialize, reduxForm } from 'redux-form'

import { SHOW_MODAL } from 'actions/actionTypes'
import { HIDE_MODAL } from 'actions/modal'
import { fetchUserProjectsForClaimsFailure, fetchUserProjectsForClaimsSuccess } from 'actions/project'
import { fetchUserProjectsForClaims } from 'api/project'
import { Button, CustomInputWrapper, DatePickerWrapper, ItemGrid, Loader, SelectWrapper } from 'components'
import { checkArrayEmpty } from 'helpers/applicationHelper'
import { remainingAmountWithAttendees } from 'helpers/claimsHelper'
import { DATE_FORMAT_ISO } from 'helpers/constants'
import { getCurrentMonth, getCurrentYear } from 'helpers/leavesHelper'
import { blockedFilter } from 'helpers/userHelper'

import { required, validateClaimForm as validate } from './validate'

import './styles/claimFormStyles.scss'

class ClaimForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCategory: '',
      selectedClaimTypes: this.props.initialValues?.claim_type || '',
      selectedProject: '',
      selectedThread: '',
      currentProjectThreads: [],
      selectedUsers: '',
      selectedClaimType: {},
      users: [],
      start_date: this.props.initialValues?.start_date,
      image: (props.initialValues && props.initialValues.image) || []
    }
  }

  componentDidMount() {
    const { claim_type } = this.props.initialValues
    this.props.fetchUserProjectsForClaims()

    if (this.props.initialValues?.project && checkArrayEmpty(this.state.currentProjectThreads)) {
      this.handleProjectChange(this.props.initialValues.project)
    }

    if (claim_type) {
      let params = {
        id: claim_type,
        date: this.props.claim.start_date,
        thread_id: this.props.initialValues?.thread
      }

      this.props.handleCtChange(params)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps?.loader, this.props.loader)) {
      if (nextProps.claimTypesDetail.amount_restriction_type === 'fix_limit') {
        this.props.change('amount', nextProps.claimTypesDetail.amount_limit / 100)
        this.props.resetField('attendees')
      } else {
        this.props.resetField('amount')
      }
      this.props.resetField('start_date')
      this.props.resetField('description')
      this.props.resetField('attendees')
      this.forceUpdate()
    }
    nextProps.formValues['is_of_recurring_type'] = this.props.claimTypesDetail.monthly_recurring
    nextProps.formValues['start_date'] = this.state.start_date

    if (!isEqual(nextProps.claim, this.props.claim)) {
      this.setState({ image: nextProps.claim.attachments })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { formValues, submission, initialValues, userProjects } = this.props

    const { image, selectedCategory, users, currentProjectThreads } = this.state

    if (!isEqual(nextProps?.claimTypesDetail, this.props?.claimTypesDetail) || !this.props?.claimTypesDetail)
      return true
    if (!isEqual(formValues, nextProps.formValues)) return true
    if (!isEqual(submission, nextProps.submission)) return true
    if (!isEqual(initialValues, nextProps.initialValues)) return true

    if (!isEqual(users, nextState.users)) return true
    if (!isEqual(image, nextState.image)) return true
    if (!isEqual(selectedCategory, nextState.selectedCategory)) return true
    if (userProjects.length === 0 && nextProps.userProjects.length > 0) return true
    if (!isEqual(currentProjectThreads, nextState.currentProjectThreads)) return true

    return false
  }

  componentWillUnmount() {
    this.props.enableFormSubmission()
  }

  buildAndFetchClaimType = (e) => {
    if (
      e &&
      this.state.selectedClaimTypes &&
      this.props.claimTypesDetail?.amount_restriction_type !== 'fixed_percentage'
    ) {
      let params = {
        id: this.state.selectedClaimTypes,
        date: moment(this.state.start_date).format(DATE_FORMAT_ISO),
        thread_id: e
      }

      this.props.handleCtChange(params)
    }
  }

  handleProjectChange = (e) => {
    if (this.state.selectedProject === e) return

    this.props.fetchUserProjectsForClaims((payload) => {
      if (payload) {
        let selectedFieldProject = payload.find((project) => project.id === e)
        const projectThreads =
          selectedFieldProject?.project_pseudo_profiles.map((profile) => ({
            value: profile.id,
            label: profile.pseudo_profile.name
          })) || []

        if (this.props.claimTypesDetail?.project_binding_type === 'project_binded') {
          this.buildAndFetchClaimType(e)
        }
        this.props.resetField('thread')
        this.setState({ currentProjectThreads: projectThreads, selectedProject: e })
      }
      return fetchUserProjectsForClaimsSuccess(payload)
    })
  }

  handleThreadChange = (e) => {
    this.setState({ selectedThread: e })

    this.buildAndFetchClaimType(e)
  }

  handleClaimTypeChange = (e) => {
    this.setState({ selectedClaimTypes: e, start_date: '', selectedProject: null, selectedThread: null })
    this.props.setFieldValue('start_date', null)
    this.props.setFieldValue('project', null)
    this.props.setFieldValue('thread', null)
    if (e && this.state.start_date) {
      let params = {
        id: e,
        date: moment(this.state.start_date).format(DATE_FORMAT_ISO)
      }

      this.props.handleCtChange(params)
    }
  }

  handleStartDateChange = (event, value) => {
    this.setState({ start_date: value, selectedProject: '' })

    this.props.resetField('project')
    this.props.resetField('thread')

    if (value && this.state.selectedClaimTypes) {
      let params = {
        id: this.state.selectedClaimTypes,
        date: moment(value).format(DATE_FORMAT_ISO),
        thread_id: this.state.selectedThread
      }

      this.props.handleCtChange(params)
    }
  }

  handleAmountChange = (event, value) => {
    this.props.change('amount', value)
  }

  handleCtChange = (selectedValue) => {
    this.props.fetchClaimTypesDetails(selectedValue.id, selectedValue.date, selectedValue?.thread_id)
  }

  render() {
    const {
      closeModal,
      submission,
      pristine,
      userId,
      claimTypes,
      loader,
      claim,
      userProjects,
      formValues,
      isEdit,
      claimTypesDetail
    } = this.props
    const { currentProjectThreads } = this.state
    const isSubscription = (claimTypeId) => {
      return claimTypes.find(({ value }) => value === claimTypeId)?.subscription
    }

    const minDate = () => moment(new Date(getCurrentYear(), 0, 1)).subtract(1, 'years')

    const maxDate = () => {
      if (isSubscription(this.state.selectedClaimTypes)) {
        return moment(new Date(getCurrentYear(), getCurrentMonth() + 1, 1))
      } else return moment(new Date())
    }

    const currencyUnit = claimTypesDetail?.currency === 'usd' ? '$. ' : 'Rs. '
    const multiplyAmountWithAttendees =
      formValues?.attendees?.length && claimTypesDetail?.multiply_amount_limit
        ? formValues?.attendees?.filter((v) => v !== userId)?.length + 1
        : 1

    const showClaimTypes = claimTypes
      .map((claim_type) => {
        if (!claim_type.invoice_based && !claim_type.thread_allowance && !claim_type.third_party_bills) {
          return {
            value: claim_type.value,
            label: claim_type.avoid_pm_approval ? claim.label + ' *' : claim_type.label
          }
        }
        return null
      })
      .filter(Boolean)

    return (
      <Grid>
        <form onSubmit={this.props.submitFormAndTouchAll} className="claim-form claim-modal">
          <ItemGrid xs={12} sm={12} md={12}>
            <Field
              heading="Category*"
              name="claim_type"
              placeholder="Select Claim Type ..."
              options={showClaimTypes}
              value={this.state.selectedClaimTypes}
              onChange={this.handleClaimTypeChange}
              disabled={submission}
              component={SelectWrapper}
              className="field"
            />
          </ItemGrid>
          {loader && <Loader />}
          {!loader && (
            <>
              <div>
                {claimTypesDetail?.auto_deduction && this.state.start_date && (
                  <p className="d-flex align-items-center auto-deduction">
                    <Info className="info-icon" /> Please enter full bill amount, it will be auto adjusted as per your
                    applicable settings.
                  </p>
                )}

                {claimTypesDetail &&
                  claimTypesDetail.project_binding_type !== 'no_binding' &&
                  claimTypesDetail.amount_restriction_type !== 'fixed_percentage' &&
                  this.state.start_date && (
                    <p className="d-flex align-items-center auto-deduction">
                      <Info className="info-icon" /> This amount is for full month, and will be automatically prorated
                      for partial months.
                    </p>
                  )}

                <ItemGrid xs={12} sm={12} md={3}>
                  <Field
                    heading="Date*"
                    name="start_date"
                    component={DatePickerWrapper}
                    minDate={minDate()}
                    disabled={submission}
                    isEdit={this.props.isEdit}
                    maxDate={maxDate()}
                    onChange={this.handleStartDateChange}
                    placeholder="Select Date"
                  />
                </ItemGrid>
                {!(this.state.selectedClaimTypes && this.state.start_date) && (
                  <div className="claimtype-extra-padding"></div>
                )}
                {this.state.selectedClaimTypes && this.state.start_date && (
                  <>
                    <Grid container className="flex-column">
                      {claimTypesDetail?.project_binding_type !== 'no_binding' && (
                        <ItemGrid xs={12} sm={12} md={6}>
                          <Field
                            heading="Select Project"
                            name="project"
                            placeholder="Projects"
                            options={userProjects}
                            onChange={this.handleProjectChange}
                            component={SelectWrapper}
                            className="field"
                            validate={[required]}
                          />
                        </ItemGrid>
                      )}

                      {['thread_restricted', 'thread_non_restricted'].includes(
                        claimTypesDetail?.project_binding_type
                      ) && (
                        <ItemGrid xs={12} sm={12} md={6}>
                          <Field
                            heading="Select Thread"
                            name="thread"
                            placeholder="Profiles"
                            options={currentProjectThreads}
                            component={SelectWrapper}
                            className="field"
                            onChange={this.handleThreadChange}
                            threadView
                          />
                        </ItemGrid>
                      )}
                    </Grid>
                    <Grid container className="mt-1">
                      <ItemGrid xs={6} sm={6} md={4}>
                        <Typography variant="text-sm" color="gray-700" className="text-heading">
                          {claimTypesDetail?.amount_restriction_type === 'fixed_percentage'
                            ? 'Amount Percentage*'
                            : 'Total Amount*'}
                        </Typography>
                        <span className="d-flex">
                          <div>
                            {['fix_limit', 'fixed_percentage'].includes(claimTypesDetail?.amount_restriction_type) ? (
                              <span>{claimTypesDetail?.amount_limit / 100}</span>
                            ) : (
                              <Field
                                name="amount"
                                validate={[required]}
                                type="number"
                                placeholder="Total Amount"
                                component={CustomInputWrapper}
                                onChange={this.handleAmountChange}
                                disabled={claimTypesDetail?.amount_restriction_type === 'fix_limit' || submission}
                                minLength={1}
                              />
                            )}
                          </div>
                          {claimTypesDetail?.amount_restriction_type === 'fixed_percentage' ? (
                            <small className="currency-type">%</small>
                          ) : (
                            <small className="currency-type"> *{claimTypesDetail?.currency?.toUpperCase()} </small>
                          )}
                        </span>
                      </ItemGrid>

                      <ItemGrid xs={6} sm={6} md={4}>
                        {claimTypesDetail?.project_binding_type !== 'no_binding' &&
                          claimTypesDetail?.amount_restriction_type !== 'fixed_percentage' &&
                          claimTypesDetail?.amount_restriction_type !== 'fix_limit' && (
                            <div className="amount-data">
                              <label htmlFor="">Your Remaining Limit: </label>
                              <span>
                                {currencyUnit}
                                {claimTypesDetail?.project_claimed_amount}
                                <Tooltip
                                  enterTouchDelay={0}
                                  title="This amount is for full month, and will be automatically prorated for partial months"
                                  placement="top-start">
                                  <InfoRounded fontSize="10" className="ml-half" />
                                </Tooltip>
                              </span>
                            </div>
                          )}

                        {claimTypesDetail?.amount_restriction_type === 'max_limit' &&
                          claimTypesDetail?.project_binding_type === 'no_binding' && (
                            <div className="amount-data">
                              <label htmlFor="">Applied Amount: </label>
                              <span>
                                {currencyUnit} {claimTypesDetail?.claimed_amount / 100}
                              </span>
                              <label htmlFor="">Remaining Amount: </label>
                              {claimTypesDetail?.rollover ? (
                                <span>
                                  {currencyUnit}
                                  {claimTypesDetail?.amount_limit / 100}
                                </span>
                              ) : claimTypesDetail?.allow_participants ? (
                                <span>
                                  {currencyUnit}
                                  {remainingAmountWithAttendees(claimTypesDetail, claim) > 0
                                    ? isEdit
                                      ? (claimTypesDetail?.amount_limit / 100) * multiplyAmountWithAttendees -
                                        claimTypesDetail?.claimed_amount / 100
                                      : remainingAmountWithAttendees(claimTypesDetail, claim) *
                                        multiplyAmountWithAttendees
                                    : 0}
                                </span>
                              ) : (
                                <span>
                                  {currencyUnit}
                                  {(claimTypesDetail?.amount_limit - claimTypesDetail?.claimed_amount) / 100}
                                </span>
                              )}
                            </div>
                          )}
                      </ItemGrid>
                      <Grid container>
                        <ItemGrid xs={12} sm={12} md={6}>
                          <Grid>
                            {claimTypesDetail?.allow_participants && (
                              <Field
                                heading="Add Participants"
                                name="attendees"
                                placeholder="Attendees"
                                isMulti
                                className="participants"
                                component={SelectWrapper}
                                disabled={!claimTypesDetail?.allow_participants || submission}
                                userSearchable
                                filters={blockedFilter}
                                helpText="Users who share this claim, other than yourself"
                              />
                            )}
                            <div className="mt-1">
                              <Field
                                name="description"
                                validate={[required]}
                                component={CustomInputWrapper}
                                inputProps={{ multiline: true, rows: '4' }}
                                placeholder="Please mention claim detail here..."
                                disabled={submission}
                              />
                            </div>
                          </Grid>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={6} itemGridClasses="mt-1">
                          {claimTypesDetail?.allow_attachments ? (
                            <Field
                              name="image"
                              type="input"
                              isEdit={this.props.isEdit}
                              component={RCDropzone}
                              files={this.state?.image}
                              filesLimit={12}
                              disabled={submission}
                              className="claim-form-file-uploader"
                            />
                          ) : null}
                        </ItemGrid>
                      </Grid>
                    </Grid>
                    <ButtonStack>
                      <Button type="button" variant="secondaryGray" onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={submission || pristine}>
                        Save
                      </Button>
                    </ButtonStack>
                  </>
                )}
              </div>
            </>
          )}
        </form>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  let userId = state.reduxTokenAuth.currentUser.attributes.id

  return {
    categories: state.claims.categories,
    formValues: getFormValues('claim_form')(state),
    userId: userId,
    claimTypes: state.claimTypes.claimTypes,
    loader: state.claimTypes.claimTypeLoader,
    claimTypesDetail: state.claimTypes.claimTypesDetail,
    userProjects: state.projects.userProjectsForClaims.map((project) => ({
      ...project,
      value: project.id,
      label: project.title
    }))
  }
}

const mapDispatchToProps = (dispatch) => ({
  change: (name, value) => dispatch(change(name, value)),
  closeModal: (_) => {
    dispatch(HIDE_MODAL)
  },
  openModal: (modalType, modalProps = null) => {
    dispatch({ type: SHOW_MODAL, modalType: modalType, modalProps: modalProps })
  },
  changeInitialValue: (initialValues) => {
    dispatch(initialize('claim_form', initialValues))
  },
  resetField: (field) => {
    dispatch(clearFields('claim_form', false, false, field))
  },
  fetchUserProjectsForClaims: (callback = fetchUserProjectsForClaimsSuccess) => {
    dispatch(fetchUserProjectsForClaims(true, callback, fetchUserProjectsForClaimsFailure))
  },
  setFieldValue: (field, value) => {
    dispatch(change('claim_form', field, value))
  }
})

ClaimForm = reduxForm({
  form: 'claim_form',
  touchOnChange: true,
  validate
})(reduxFormTouchOnsubmit(ClaimForm))

export default connect(mapStateToProps, mapDispatchToProps)(ClaimForm)

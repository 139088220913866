import { useState } from 'react'
import { FormControl, InputLabel, TextField, Tooltip } from 'maple-storybook'
import PropTypes from 'prop-types'

import { invalidTextLength, validTextLength } from 'helpers/applicationHelper'

function CustomInput({
  formControlProps,
  labelText,
  id,
  labelProps,
  inputProps,
  type,
  step,
  min,
  max,
  value,
  containerId,
  skipDisplayText,
  maxLength,
  ...rest
}) {
  const [displayHint, setDisplayHint] = useState(false)

  const handleChange = (event) => {
    if (invalidTextLength(event.target.value)) setDisplayHint(true)
    else if (validTextLength(event.target.value, displayHint)) setDisplayHint(false)

    if (type === 'checkbox') {
      return inputProps.onChange(event.target.checked)
    } else {
      return inputProps.onChange(event.target.value)
    }
  }

  return (
    <FormControl {...formControlProps} id={containerId}>
      {labelText !== undefined ? (
        <InputLabel htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Tooltip
        placement="top-end"
        title={`${id === 'search' && !skipDisplayText && displayHint ? 'Enter at least 3 characters' : ''}`}
        arrow>
        <TextField
          {...inputProps}
          onChange={(e) => handleChange(e)}
          inputProps={{
            step: step,
            min: min,
            max: max,
            maxLength: maxLength,
            datacy: inputProps.name
          }}
          type={type}
          defaultValue={value}
          maxLength={maxLength}
          {...rest}
        />
      </Tooltip>
    </FormControl>
  )
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.any
}

export default CustomInput

import { styled } from 'maple-storybook'

import { StyledTypography } from 'variables/styles/typographyStyle.jsx'

const P = styled(({ children, ...rest }) => <p {...rest}>{children}</p>)(() => ({
  ...StyledTypography.pStyle,
  ...StyledTypography.defaultFontStyle
}))

export default P

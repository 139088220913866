import { lazy } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonStack, Typography } from 'maple-storybook'
import { Field, Form, reduxForm } from 'redux-form'

import { Button, CustomInputWrapper, ItemGrid, SelectWrapper } from 'components'
import { yupSchemaValidator } from 'helpers/formsHelper'

import { schema } from './FormValidations'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './styles/questionForm.scss'

const CustomAdvancedInputWrapper = lazy(
  () => import('../../components/CustomAdvancedInputWrapper/CustomAdvancedInputWrapper')
)

const QuestionForm = (props) => {
  const navigate = useNavigate()

  const cancelButton = () => {
    navigate('/faqs')
  }

  return (
    <Form onSubmit={props.handleSubmit}>
      <ItemGrid xs={12} sm={12} md={12} itemGridClasses="question-form">
        <Field
          heading="Question"
          type="text"
          name="title"
          placeholder="Add question here"
          component={CustomInputWrapper}
        />
        <Typography variant="text-sm" color="gray-700" className="flex-column mb-8">
          Answer
        </Typography>
        {(props.isNew || (!props.isNew && props.initialValues.answer !== undefined)) && (
          <Field
            type="text"
            name="answer"
            placeholder="Add your answer here"
            component={CustomAdvancedInputWrapper}
            wrapperClassName="message-border"
            editorStyling="answer-editor-styling"
            initialValue={props.initialValues?.answer}
            isEdit={!props.isNew}
          />
        )}
        <Field
          name="department_ids"
          heading="Select Department"
          isMulti
          allCheck
          options={props.departments}
          component={SelectWrapper}
        />
      </ItemGrid>
      <ButtonStack>
        <Button variant="secondaryGray" disabled={props.submission} onClick={cancelButton}>
          Cancel
        </Button>
        <Button type="submit" disabled={props.submission}>
          {props.isNew ? 'Submit' : 'Update'}
        </Button>
      </ButtonStack>
    </Form>
  )
}

let QuestionFormWithRedux = reduxForm({
  form: 'add_new_QuestionForm',
  validate: yupSchemaValidator(schema),
  enableReinitialize: true
})(QuestionForm)

export default QuestionFormWithRedux

import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { toggleSidebar } from 'actions/sidebar'
import { addClass, addClassOnTag, removeClass, removeClassOnTag, webView } from 'helpers/applicationHelper'

class Backdrop extends PureComponent {
  handleDrawerToggle = (_) => {
    this.props.toggleSidebar(!this.props.sidebarOpen)
  }

  componentDidUpdate() {
    const { sidebarOpen } = this.props
    const windowWidth = window.outerWidth

    if (sidebarOpen && windowWidth < 960) {
      addClassOnTag('html', 'fixed-scrolling')
      addClassOnTag('body', 'fixed-scrolling')
      addClass('app-main-backdrop', 'app-main-backdrop-mobile')
    } else if ((!sidebarOpen && windowWidth > 960) || (!sidebarOpen && windowWidth < 960)) {
      removeClassOnTag('html', 'fixed-scrolling')
      removeClassOnTag('body', 'fixed-scrolling')
      removeClass('app-main-backdrop', 'app-main-backdrop-mobile')
    }
  }

  render() {
    return (
      <div
        className={`app-main-backdrop${!webView() && this.props.sidebarOpen && '-mobile'}`}
        onClick={this.handleDrawerToggle}></div>
    )
  }
}

const mapStateToProps = (state) => ({
  sidebarOpen: state.sidebar.open
})

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: (_) => {
    dispatch(toggleSidebar())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Backdrop)

import { toastr } from 'react-redux-toastr'

import { errorsAlert, warningAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'
import { HIDE_MODAL } from './modal'

export const setClaimsPageSize = (payload) => ({
  type: types.SET_CLAIMS_PAGE_SIZE,
  payload
})

export const createClaimSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_CLAIM_SUCCESS,
    payload
  })

  dispatch(HIDE_MODAL)

  if (payload.processing_data) {
    toastr.success('Please wait untill rollover job is processing')
  } else if (payload.claim) {
    toastr.success('Claim Created Successfully!')
  }

  warningAlert(payload.warning)
}

export const createClaimFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CREATE_CLAIM_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to create Claim')
}

export const fetchClaimsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIMS_SUCCESS,
    payload: payload
  })
  dispatch(hideClaimListLoader)
}

export const setUpdatedAssignedClaims = (payload) => ({
  type: types.SET_UPDATED_ASSIGNED_CLAIM,
  payload
})

export const fetchClaimsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIMS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch Claims')
}

export const showClaimLoader = {
  type: types.SHOW_CLAIM_LOADER
}

export const hideClaimLoader = {
  type: types.HIDE_CLAIM_LOADER
}

export const showClaimListLoader = {
  type: types.SHOW_CLAIM_LIST_LOADER
}

export const hideClaimListLoader = {
  type: types.HIDE_CLAIM_LIST_LOADER
}

export const fetchClaimApprovalsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_APPROVALS_SUCCESS,
    payload: payload
  })
  dispatch(hideClaimListLoader)
}

export const fetchClaimApprovalsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_APPROVALS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch Claim Approvals')
}

export const fetchAllClaimsSuccess = (payload, hideLoaderOnly) => (dispatch) => {
  if (!hideLoaderOnly) {
    dispatch({
      type: types.FETCH_ALL_CLAIMS_SUCCESS,
      payload: payload
    })
  }

  dispatch(hideClaimListLoader)
}

export const fetchAllClaimsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ALL_CLAIMS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch All Claims')
}
export const fetchDetailedClaimsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_DETAILED_CLAIMS_SUCCESS,
    payload: payload
  })
}

export const fetchDetailedClaimsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_DETAILED_CLAIMS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch Bulk Print Claims')
}

export const fetchClaimsStatsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIMS_STATS_SUCCESS,
    payload: payload
  })
}

export const fetchClaimsStatsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIMS_STATS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch Claims Stats')
}

export const updateClaimSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CLAIM_SUCCESS,
    payload: payload
  })

  if (!payload.adjusted_amount) dispatch(HIDE_MODAL)

  if (payload.processing_data) {
    toastr.success('Please wait until rollover job is processing')
  } else {
    toastr.success('Claim Updated Successfully!')
  }
  warningAlert(payload.warning)
}

export const updateClaimFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CLAIM_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update Claim')
}

export const closeClaimSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CLOSE_CLAIM_SUCCESS,
    payload: payload
  })

  dispatch(HIDE_MODAL)

  toastr.success('Claim Moved to Closure Status Successfully!')
  warningAlert(payload.warning)
}

export const closeClaimFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CLOSE_CLAIM_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to close Claim')
}

export const cancelClaimSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.CANCEL_CLAIM_SUCCESS,
    payload: payload
  })

  toastr.success('Claim Moved to Cancel Status Successfully!')
}

export const cancelClaimFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.CANCEL_CLAIM_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to cancel Claim')
}

export const fetchClaimCategoriesSuccess = (payload) => ({
  type: types.FETCH_CLAIM_CATEGORIES_SUCCESS,
  payload
})

export const fetchClaimCategoriesFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_CATEGORIES_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch Claim Categories')
}

export const fetchClaimSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_SUCCESS,
    payload: payload
  })
  dispatch(hideClaimLoader)
}

export const fetchClaimFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_FAILURE,
    payload
  })
  toastr.error('Failed to Fetch Claim')
}
export const fetchChildClaimsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CHILD_CLAIMS_SUCCESS,
    payload: payload
  })
}
export const fetchChildClaimsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CHILD_CLAIMS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch Child Claims')
}
export const deleteClaimSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_CLAIM_SUCCESS,
    payload
  })

  dispatch(HIDE_MODAL)

  toastr.success('Claim Deleted Successfully!')
}

export const deleteClaimFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.DELETE_CLAIM_FAILURE,
    payload
  })

  toastr.error('Failed to delete Claim!')
}

export const handleSearchedClaims = (payload) => ({
  type: types.HANDLE_SEARCHED_CLAIMS,
  payload
})

export const fetchClaimCsvAttributeSuccess = (payload) => ({
  type: types.FETCH_CLAIM_CSV_ATTRIBUTES_SUCCESS,
  payload
})

export const fetchClaimCsvAttributeFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_CLAIM_CSV_ATTRIBUTES_FAILURE,
    payload
  })
  toastr.error('Failed to fetch csv attributes!')
}

export const updateClaimStatusSuccess = (payload, extraParams) => (dispatch) => {
  dispatch({
    type: extraParams?.flag ? types.UPDATE_CLAIMS_CLEARANCE_DATA_SUCCESS : types.UPDATE_CLAIM_SUCCESS,
    userId: extraParams?.userId,
    payload: payload
  })
  dispatch(hideClaimLoader)
  dispatch(HIDE_MODAL)

  toastr.success('Claim Status updated Successfully')
}

export const updateClaimStatusFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CLAIM_STATUS_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update Claim Status')
}

export const updateClaimStatusesSuccess = (_payload) => (dispatch) => {
  dispatch(hideClaimLoader)
  dispatch(HIDE_MODAL)

  toastr.success('Claims Statuses updated Successfully')
}

export const updateClaimCategorySuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CLAIM_CATEGORY_SUCCESS,
    payload
  })

  toastr.success('Policy Updated Successfully!')
}

export const updateClaimCategoryFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CLAIM_CATEGORY_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to update Claim Category Filters')
}

export const fetchTimeSlotsSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TIME_SLOTS_SUCCESS,
    payload: payload
  })
}
export const fetchTimeSlotsFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_TIME_SLOTS_FAILURE,
    payload
  })
  errorsAlert(payload, 'Failed to Fetch Time Slots')
}

export const verifyRedeemableSlotSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.VERIFY_REDEEMABLE_SLOT_SUCCESS,
    payload: payload
  })
}
export const verifyRedeemableSlotFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.VERIFY_REDEEMABLE_SLOT_FAILURE,
    payload
  })
  errorsAlert(payload)
}

import { useDispatch } from 'react-redux'
import { Typography } from 'maple-storybook'

import { INFO_MODAL, SHOW_MODAL } from 'actions/actionTypes'

const CustomToastr = ({ trace_id }) => {
  const dispatch = useDispatch()
  const openModal = (modalType, modalProps = null) =>
    dispatch({ type: SHOW_MODAL, modalType: modalType, modalProps: modalProps })

  const customErrorText = (trace_id) => {
    return (
      <div>
        <Typography variant="text-lg" color="gray-900" noTransform>
          Something went wrong!
        </Typography>
        <Typography variant="text-sm" display="block" color="gray-500" className="mb-half" noTransform>
          Please try again later.
        </Typography>
        <Typography variant="text-sm" display="block" color="gray-500" noTransform>
          Sorry for the inconvenience, Our teams are alerted and are working on the resolution.
        </Typography>
        <Typography type="semibold  " variant="text-sm" display="block" color="gray-900" noTransform>
          Trace ID: {trace_id}
        </Typography>
        <Typography variant="text-sm" display="block" color="gray-500" className="mb-half" noTransform>
          If the problem persists or you need further assistance, you can contact our support team with the above
          mentioned Trace ID
        </Typography>
        <Typography variant="text-sm" display="block" color="gray-500" noTransform>
          Thank you for your cooperation.
        </Typography>
      </div>
    )
  }

  return (
    <span
      className="text-underline cursor-pointer"
      style={{ color: '#551A8B' }}
      onClick={() =>
        openModal(INFO_MODAL, {
          message: customErrorText(trace_id),
          width: 'xs',
          fullscreen: false,
          fullWidthBtn: true,
          buttonText: 'OK',
          btnVariant: 'secondaryGray'
        })
      }>
      Tell me more
    </span>
  )
}
export default CustomToastr

import { docSizeValidator, hasCharacters } from 'helpers/validationsHelper'

export const required = (value) => (value ? undefined : 'Required')

export const validateTicketForm = (values) => {
  const errors = {}

  if (!values.title || !hasCharacters(values.title)) {
    errors.title = 'Subject is required'
  }

  if (values.title && values.title.length > 100) {
    errors.title = 'Title should not be exceeding 100 characters'
  }

  if (values.title && values.title.length < 3) {
    errors.title = 'Title should not be less than 3 characters'
  }

  if (!values.description) {
    errors.description = 'Description is required'
  }

  if (values.description && values.description.length < 10) {
    errors.description = 'Description should not be less than 3 characters'
  }

  if (!values.ticket_category_id) {
    errors.ticket_category_id = 'Category is required'
  }

  if (!values.priority) {
    errors.priority = 'Please specify priority'
  }
  if (!values.assignee_id) {
    errors.assignee_id = 'Assignee is required'
  }

  if (values.image) {
    if (values.image.length > 3) {
      errors.image = 'Please Select 3 files only.'
    }
    values.image.forEach((file) => {
      if (docSizeValidator(file)) errors.image = 'Doc size exceeding'
    })
  }

  return errors
}

export default validateTicketForm

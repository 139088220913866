import * as types from 'actions/actionTypes'
import { collectionIntoSelectOptions } from 'helpers/settingsHelper'

import initialState from './initialState'

export const nationalityIdentifier = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_NATIONALITIES_SUCCESS: {
      return {
        ...state,
        nationalities: action.payload,
        selectNationalities: collectionIntoSelectOptions(action.payload, 'id', 'nationality')
      }
    }

    default:
      return state
  }
}

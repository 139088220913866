import { Component } from 'react'
import { connect } from 'react-redux'
import withRouter from 'hocs/withRouter'

import { BASE_MODAL, CONFIRMATION_MODAL, CONTENT_MODAL, FORM_MODAL, INFO_MODAL, QR_MODAL } from 'actions/actionTypes'
import { HIDE_MODAL } from 'actions/modal'

import BaseModal from './BaseModal'
import ConfirmationModal from './ConfirmationModal'
import ContentModal from './ContentModal'
import FormModal from './FormModal'
import InfoModal from './InfoModal'
import QrModal from './QrModal'

const MODAL_COMPONENTS = {
  [BASE_MODAL]: BaseModal,
  [CONFIRMATION_MODAL]: ConfirmationModal,
  [FORM_MODAL]: FormModal,
  [CONTENT_MODAL]: ContentModal,
  [INFO_MODAL]: InfoModal,
  [QR_MODAL]: QrModal
}

class ModalRoot extends Component {
  render() {
    const { modals } = this.props

    return modals.length
      ? modals.map((modal) => {
          if (!modal.modalType) return null

          const Modal = MODAL_COMPONENTS[modal.modalType]
          return <Modal key={modal.index} {...modal.modalProps} />
        })
      : null
  }
}

const mapStateToProps = (state) => ({
  modals: state.modals.modals
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(HIDE_MODAL)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalRoot))

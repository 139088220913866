import { PureComponent } from 'react'
import { connect } from 'react-redux'

import './styles/unauthorized.css'

class Unauthorized extends PureComponent {
  render() {
    const { permissions } = this.props

    if (!permissions || Object.keys(permissions).length === 0) return null

    return (
      <div className="unauthorized-main-div">
        <div>
          <span>Unauthorized</span>
          <span>Sorry, you are not authorized to access this page</span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  permissions: state.permissions.userPermissions
})

export default connect(mapStateToProps)(Unauthorized)

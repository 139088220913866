import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const location = (state = initialState.locations, action) => {
  let location
  let updatedLocation
  let index

  switch (action.type) {
    case types.FETCH_LOCATIONS_SUCCESS:
      return { ...state, locations: action.payload }

    case types.FETCH_LOCATIONS_FAILURE:
      return state

    case types.CREATE_LOCATIONS_SUCCESS:
      return { ...state, locations: [...state.locations, action.payload] }

    case types.CREATE_LOCATIONS_FAILURE:
      return state

    case types.UPDATE_LOCATIONS_SUCCESS:
      location = [...state.locations]
      updatedLocation = action.payload ? action.payload : null
      index = state.locations.findIndex((location) => location.id === updatedLocation.id)
      if (index !== -1) {
        location[index] = updatedLocation
      }
      return {
        ...state,
        locations: location
      }

    case types.UPDATE_LOCATIONS_FAILURE:
      return state

    case types.DELETE_LOCATIONS_SUCCESS:
      location = [...state.locations]
      if (action.payload) {
        index = state.locations.findIndex((location) => location.id === action.payload)
      }

      location = [...state.locations]
      location.splice(index, 1)

      return { ...state, locations: [...location] }

    case types.DELETE_LOCATIONS_FAILURE:
      return state

    default:
      return state
  }
}

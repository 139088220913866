import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const integrationsReducer = (state = initialState.integrations, action) => {
  switch (action.type) {
    case types.FETCH_INTEGRATIONS_FAILURE:
      return { ...state, appIntegrations: [] }
    case types.FETCH_INTEGRATIONS_SUCCESS:
      return { ...state, appIntegrations: action.payload }
    case types.DISCONNECT_SLACK_SUCCESS:
      return { ...state, appIntegrations: action.payload }
    case types.DISCONNECT_SLACK_FAILURE:
      return { ...state }
    default:
      return state
  }
}

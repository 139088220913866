import { Box, Checkbox, Typography } from 'maple-storybook'

import { Danger } from 'components'

const CustomCheckboxWrapper = ({
  id,
  input,
  defaultValue,
  onChange,
  variant,
  heading,
  disabled,
  meta,
  size,
  labelText,
  checkboxContainer,
  props
}) => {
  const handleChange = (event, checked) => {
    input.onChange && input.onChange(checked)
    onChange && onChange(checked)
  }

  return (
    <Box component="div" className={checkboxContainer}>
      {heading && (
        <Typography variant="text-sm" color="gray-700" sx={{ marginBottom: '10px' }}>
          {heading}
        </Typography>
      )}
      <Checkbox
        variant={'primary' || variant}
        size={size || 'sm'}
        checked={input.value || !!defaultValue}
        id={id}
        onChange={handleChange}
        label={labelText}
        disabled={disabled}
        {...props}
      />

      {meta?.touched && meta?.error && <Danger>{meta.error}</Danger>}
    </Box>
  )
}

export default CustomCheckboxWrapper

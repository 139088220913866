import { lazy } from 'react'

import { Delay } from 'components'

export const Loading = (props) =>
  (props.pastDelay && <Delay />) ||
  (props.error && (
    <div>
      Error! <button onClick={props.retry}>Retry</button>
    </div>
  )) ||
  (props.timeout && (
    <div>
      Connection slow or Network Error. <button onClick={props.retry}>Retry</button>
    </div>
  )) ||
  null

export const DashboardPage = lazy(() => import('../views/Dashboard'))

export const Profile = lazy(() => import('../views/Profile/Profile'))

export const HRDashboardPage = lazy(() => import('../views/HRDashboard/HRDashboard'))

export const MyReviews = lazy(() => import('../views/PMS/MyReviews/Reviews'))

export const TeamEvaluation = lazy(() => import('../views/PMS/TeamEvaluation/TeamEvaluations'))

export const UserReviewDetails = lazy(() => import('../views/PMS/TeamEvaluation/UserReviewDetails'))

export const PMSConfiguration = lazy(() => import('../views/PMS/Configuration/Configuration'))

export const UserTask = lazy(() => import('../views/UserTask/UserTask'))

export const LandingPage = lazy(() => import('../views/Landing/Landing'))

export const DepartmentsPage = lazy(() => import('../views/Departments/Departments'))

export const Users = lazy(() => import('../views/Users/Users'))

export const RolesPage = lazy(() => import('../views/Roles/Roles'))

export const NewRolesPage = lazy(() => import('../views/Roles/New'))

export const NewProjectPage = lazy(() => import('../views/Project/NewProject'))

export const ShowProjectPage = lazy(() => import('../views/Project/ShowProject/ShowProject'))

export const EditProjectPage = lazy(() => import('../views/Project/EditProject'))

export const EditRolesPage = lazy(() => import('../views/Roles/Edit.jsx'))

export const TicketSettings = lazy(() => import('../views/ApplicationSettings/TicketSettings.jsx'))

export const IdentityDocumentSettings = lazy(() => import('../views/ApplicationSettings/IdentityDocumentSettings.jsx'))

export const IdentityDocuments = lazy(
  () => import('../views/ApplicationSettings/IdentityDocuments/IdentityDocuments.jsx')
)

export const UpdateTicketEmail = lazy(() => import('../views/ApplicationSettings/HROpsSettings/UpdateTicketEmail.jsx'))

export const TicketSetting = lazy(() => import('../views/ApplicationSettings/TicketSetting.jsx'))

export const Tickets = lazy(() => import('../views/Tickets/Tickets'))

export const ShowTicket = lazy(() => import('../views/Tickets/ShowTicket/ShowTicket'))

export const ResetPasswordPage = lazy(() => import('../views/Landing/ResetPasswordPage'))

export const Leaves = lazy(() => import('../views/Leaves/Leaves'))

export const Calendar = lazy(() => import('../views/Calendar/Calendar'))

export const ForgotPasswordPage = lazy(() => import('../views/Landing/ForgotPasswordPage'))

export const UploadInvoice = lazy(() => import('../views/Project/ProjectInvoice/UploadInvoice'))

export const Settings = lazy(() => import('../views/Settings/Settings'))

export const Organization = lazy(() => import('../views/Organization/Organization'))

export const LeavePolicies = lazy(() => import('../views/Leaves/LeavePolicies/LeavePolicyTab'))

export const Items = lazy(() => import('../views/Inventory/Items'))

export const SubCategoryPage = lazy(() => import('../views/Inventory/ManageSubCategory'))

export const NewCapitalizeItem = lazy(() => import('../views/Inventory/NewCapitalizeItem'))

export const NewTrackedItemPage = lazy(() => import('../views/Inventory/Item/NewTrackedItem'))

export const ArchivedInventoryPage = lazy(() => import('../views/Inventory/NewArchivedInventory'))

export const ViewItemDetailPage = lazy(() => import('../views/Inventory/ItemDetailView'))

export const CreateBulkItems = lazy(() => import('../views/Inventory/Item/CreateBulkItems'))

export const FeedbackPage = lazy(() => import('../views/Feedback/Feedbacks'))

export const Projects = lazy(() => import('../views/Project/Projects'))

export const ProjectHistoryPage = lazy(() => import('../views/Project/History/ManageProjectHistory'))

export const UserProjectHistoryPage = lazy(() => import('../views/Project/History/ManageUserProjectHistory'))

export const Reports = lazy(() => import('../views/Reports/Reports'))

export const HROperationsSettings = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/HROperationsSettings')
)
export const EditHROperationSettings = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/EditHROperationSettings')
)

export const ReviewSettingsAdmin = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/ReviewSettings/ReviewSettings')
)

export const AnnouncementSettings = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/AnnouncementSettings/AnnouncementSettings')
)

export const NewSlackAnnouncementSetting = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/AnnouncementSettings/NewAnnouncementSetting')
)

export const EditSlackAnnouncementSetting = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/AnnouncementSettings/EditAnnouncementSetting')
)

export const EmailListSettings = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/UserSettings/EmailListSettings')
)

export const ActiveSubscriptions = lazy(
  () => import('../views/ApplicationSettings/HROpsSettings/ActiveSubscriptions/ActiveSubscriptions')
)

export const ProjectSettingsAdmin = lazy(() => import('../views/ApplicationSettings/ProjectSettings/ProjectSettings'))

export const InventorySettingsAdmin = lazy(
  () => import('../views/ApplicationSettings/InventorySettings/InventorySettings')
)

export const DnarsSettingsAdmin = lazy(() => import('../views/ApplicationSettings/DnarsSettings/DnarsSettings'))

export const CompanySettingsAdmin = lazy(() => import('../views/ApplicationSettings/CompanySettings/CompanySettings'))

export const TimeTrackerSettingsAdmin = lazy(
  () => import('../views/ApplicationSettings/TimeTrackerSettings/TimeTrackerSettings')
)

export const LeavesSettingsAdmin = lazy(() => import('../views/ApplicationSettings/LeaveSettings/LeaveSettings'))

export const OfficeSettingsAdmin = lazy(() => import('../views/ApplicationSettings/OfficeSettings/OfficeSettings'))

export const BusinessUnitSettingsAdmin = lazy(
  () => import('../views/ApplicationSettings/BusinessUnitSettings/BusinessUnitSettings')
)

export const WorkFlowCategoryListings = lazy(() => import('../views/WorkFlows/WorkFlowCategoryListings'))

export const WorkflowCategoryDetail = lazy(() => import('../views/WorkFlows/CategoryDetail'))

export const EditWorkflowTasksform = lazy(() => import('../views/WorkFlows/EditWorkflowTask'))

export const ShowWorkflowTasksform = lazy(() => import('../views/WorkFlows/ShowWorkflowTask'))

export const NewWorkflow = lazy(() => import('../views/WorkFlows/NewWorkflow'))

export const EditWorkflow = lazy(() => import('../views/WorkFlows/EditWorkflow'))

export const NewWorkflowCategory = lazy(() => import('../views/WorkFlows/NewWorkflowCategory'))

export const EditWorkflowCategory = lazy(() => import('../views/WorkFlows/EditWorkflowCategory'))

export const NewWorkflowTasksform = lazy(() => import('../views/WorkFlows/NewWorkflowTasks'))

export const WorkflowAllTasks = lazy(() => import('../views/WorkFlows/WorkflowAllTasks'))

export const TaskDetailPage = lazy(() => import('../views/WorkFlows/TaskDetail'))

export const TaskTriggers = lazy(() => import('../views/WorkFlows/TaskTriggers'))

export const ShowTaskAttachment = lazy(() => import('../views/WorkFlows/ShowAttachment'))

export const ClaimsPage = lazy(() => import('../views/Claims/Claims'))

export const SubscriptionsTab = lazy(() => import('../views/Claims/MySubscriptions'))

export const IntegrationsPage = lazy(() => import('../views/Integrations/Integrations'))

export const UserForm = lazy(() => import('../views/Users/UserForm'))

export const NewUser = lazy(() => import('../views/Users/NewUser'))

export const CreatePreOnboardingUser = lazy(() => import('../views/Users/CreatePreOnboardingUser'))

export const EditPreOnBoardingUser = lazy(() => import('../views/Users/EditPreOnBoardingUser'))

export const EditUser = lazy(() => import('../views/Users/EditUser'))

export const UserInitialDetails = lazy(() => import('../views/Users/UserInitialDetails'))

export const CreateBulkUsers = lazy(() => import('../views/Users/CreateBulkUserTab'))

export const ClaimsSettingsHome = lazy(() => import('../views/ApplicationSettings/Claims/ClaimsSettingsHome'))

export const AdminPanelPage = lazy(() => import('../views/AdminPanel/AdminPanel'))

export const DnarsPage = lazy(() => import('../views/Dnar/Dnars'))

export const DynamicFormSettings = lazy(
  () => import('../views/ApplicationSettings/DynamicFormSettings/DynamicFormSettings')
)

export const NewDynamicForm = lazy(
  () => import('../views/ApplicationSettings/DynamicFormSettings/DynamicForm/NewDynamicForm')
)

export const EditDynamicForm = lazy(
  () => import('../views/ApplicationSettings/DynamicFormSettings/DynamicForm/EditDynamicForm')
)

export const ImportDynamicForm = lazy(
  () => import('../views/ApplicationSettings/DynamicFormSettings/DynamicForm/ImportDynamicForm')
)

export const ViewUserProbationDetail = lazy(
  () => import('../views/HRDashboard/Probation/ShowUserProbationDetail/components/ViewUserProbationDetail')
)

export const NewClaimSettingForm = lazy(() => import('../views/ApplicationSettings/Claims/NewClaimSettingForm'))

export const NewAutoLeaveApplyRuleForm = lazy(
  () => import('../views/ApplicationSettings/LeaveSettings/AutoLeaveApplyRule/NewAutoLeaveApplyRuleForm')
)

export const EditAutoLeaveApplyRule = lazy(
  () => import('../views/ApplicationSettings/LeaveSettings/AutoLeaveApplyRule/EditAutoLeaveApplyRule')
)

export const Question = lazy(() => import('../views/Questions/Question'))

export const CreateQuestion = lazy(() => import('../views/Questions/CreateQuestion'))

export const EditQuestion = lazy(() => import('../views/Questions/EditQuestion'))

export const PreOnboardingSettings = lazy(
  () => import('../views/ApplicationSettings/PreOnboardingSettings/PreOnboardingSettings')
)

export const SubscriptionPanel = lazy(() => import('../views/SubscriptionPanel/SubscriptionPanel'))

export const NewSupportStaffUser = lazy(() => import('../views/Users/NewSupportStaffUser'))

export const EditSupportStaffUser = lazy(() => import('../views/Users/EditSupportStaffUser'))

export const SubscriptionReports = lazy(() => import('../views/SubscriptionPanel/Reports/Reports'))

export const ProjectTimeline = lazy(() => import('../views/Project/ProjectTimeline/ProjectTimeline'))

export const AmsOverview = lazy(() => import('../views/AMS/Overview'))

export const AmsShiftsTab = lazy(() => import('../views/AMS/ShiftManagement'))

export const AmsUserShiftsTab = lazy(() => import('../views/AMS/UserShiftsTab'))

export const AmsMyCalendar = lazy(() => import('../views/AMS/MyCalendar'))

export const AmsReports = lazy(() => import('../views/AMS/Reports'))

export const AmsDownloadHistoryTab = lazy(() => import('../views/AMS/DownloadHistoryTab'))

export const AmsMacBinding = lazy(() => import('../views/AMS/MacBinding'))

export const Library = lazy(() => import('../views/PMS/Library/Library'))

export const CreationLogs = lazy(() => import('../views/Users/components/CreationLogs'))

import { connect } from 'react-redux'
import { Dialog, DialogActions, DialogTitle } from 'maple-storybook'

import { HIDE_MODAL } from 'actions/actionTypes'
import { Button } from 'components'

const InfoModal = (props) => {
  const {
    handleClose,
    open,
    message,
    buttonText,
    fullWidthBtn = false,
    btnVariant = 'primary',
    width = 'md',
    fullWidth = false
  } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={width}
      fullWidth={fullWidth}
      disableBackdropClick
      disableEscapeKeyDown={false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button fullWidth={fullWidthBtn} variant={btnVariant} onClick={handleClose}>
          {buttonText ? buttonText : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleClose: (_) => {
    dispatch({ type: HIDE_MODAL })
  }
})

export default connect(null, mapDispatchToProps)(InfoModal)

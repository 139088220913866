import { styled } from 'maple-storybook'

import { StyledTypography } from 'variables/styles/typographyStyle.jsx'

const StyledQuote = styled('blockquote')(() => ({
  '&.quote-container': {
    ...StyledTypography.defaultFontStyle,
    ...StyledTypography.quote
  },
  '& .text': {
    ...StyledTypography.quoteText
  },
  '& .author': {
    ...StyledTypography.quoteAuthor
  }
}))
function Quote({ text, author }) {
  return (
    <StyledQuote className="quote-container">
      <p className="text">{text}</p>
      <small className="author">{author}</small>
    </StyledQuote>
  )
}

export default Quote

import { stringify } from 'qs'

import { showTicketLoader } from 'actions/ticket'
import { ticketCatrgoryUrl } from 'helpers/ticketsHelper'

import { serialize } from './helpers'
import request from './request'

export const fetchTickets = (params, successAction, failureAction, flag) => (dispatch) => {
  dispatch(
    request(
      `tickets/sent?${stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true,
      flag
    )
  )

  dispatch(showTicketLoader)
}

export const fetchAssignedTickets = (params, successAction, failureAction, flag) => (dispatch) => {
  dispatch(
    request(
      `admin/users/assigned?${stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true,
      flag
    )
  )

  dispatch(showTicketLoader)
}

export const createTicket = (params, successAction, failureAction) => {
  let ticketParams = { ticket: params }

  const formData = serialize(ticketParams)

  return request(
    'tickets',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: formData
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchTicket = (id, successAction, failureAction) => (dispatch) => {
  dispatch(request(`tickets/${id}`, {}, successAction, failureAction, true))

  dispatch(showTicketLoader)
}

export const updateTicket = (params, successAction, failureAction) => {
  return request(
    `tickets/${params.ticket_id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ticket: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const updateTicketStatus = (params, successAction, failureAction, flag = false, userId) => {
  let ticketParams = { ticket: params }
  const formData = serialize(ticketParams)
  return request(
    `tickets/${params.ticket_id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: formData,
      submission: true
    },
    successAction,
    failureAction,
    true,
    {
      userId,
      flag
    }
  )
}

export const ticketApprovalTransition = (params, successAction, failureAction, flag = false, userId) => {
  const formData = serialize(params)
  return request(
    `tickets/${params.ticket_id}/ticket_approvals/${params.id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: formData
    },
    successAction,
    failureAction,
    true,
    {
      userId,
      flag
    }
  )
}

export const fetchTicketComments = (params, successAction, failureAction) =>
  request(`tickets/${params.ticketId}/comments`, {}, successAction, failureAction, true)

export const createTicketComment = (params, successAction, failureAction) => {
  let commentParams = { comment: params }
  const formData = serialize(commentParams)
  return request(
    `tickets/${params.ticket_id}/comments`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: formData,
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchSearchedTickets = (params, successAction, failureAction, flag) => (dispatch) => {
  let searchParams = { ticket_search: params.search_params }
  dispatch(
    request(
      `tickets/search?page=${params.page}&page_size=${params.page_size}&${stringify(searchParams, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true,
      flag
    )
  )
  dispatch(showTicketLoader)
}

export const fetchAssignableUsers = (categoryId, ticketId, successAction, failureAction) => {
  return request(
    `ticket_categories/${categoryId}/assignable_users?ticket_id=${ticketId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const changeTicketParticipants = (params, successAction, failureAction) =>
  request(
    `tickets/${params.ticket_id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ticket: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )

export const fetchTicketCategories =
  (
    getDashboardCategories = false,
    successAction,
    failureAction,
    search_params = {},
    detailed_data = false,
    csv_data = false,
    base_url = null
  ) =>
  (dispatch, getState) => {
    !csv_data && (getState().tickets.categories = [])
    dispatch(
      request(
        ticketCatrgoryUrl(getDashboardCategories, search_params, detailed_data, csv_data, base_url),
        {},
        successAction,
        failureAction,
        true
      )
    )
  }

export const fetchTicketCsvAttributes = (successAction, failureAction) =>
  request('tickets/allowed_attributes', {}, successAction, failureAction, true)

export const fetchTicketStats = (searchParams, successAction, failureAction) =>
  request(
    `tickets/stats?${stringify({ ticket_search: searchParams }, { arrayFormat: 'brackets' })}`,
    {},
    successAction,
    failureAction,
    true
  )

export const createTicketCategory = (params, successAction, failureAction) =>
  request(
    `ticket_categories/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ categories: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )

export const fetchTicketsCsvData = (searchParams, attributes, successAction, failureAction) => (dispatch) => {
  let queryParams = {
    csv_data: true,
    attributes: attributes,
    ticket_search: searchParams,
    base_url: '/tickets/dashboard'
  }
  dispatch(
    request(
      `tickets/search?${stringify(queryParams, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
  dispatch(showTicketLoader)
}

export const fetchTicketCategory = (id, successAction, failureAction, showChangeLogs = false) => {
  return request(
    `ticket_categories/${id}?show_change_logs=${showChangeLogs}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const deleteTicketCategory = (id, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `ticket_categories/${id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const updateTicketCategory = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `ticket_categories/${params.id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ticket_category: params })
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const fetchTicketAction = (params, successAction, failureAction, flag) => (dispatch, getState) => {
  getState().tickets.ticketLoader = true
  dispatch(
    request(
      `ticket_actions?${stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true,
      false,
      flag
    )
  )

  dispatch(showTicketLoader)
}

export const createTicketAction = (params, successAction, failureAction) => {
  return request(
    'ticket_actions',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ticket_action: params })
    },
    successAction,
    failureAction,
    true
  )
}

export const deleteTicketAction = (id, successAction, failureAction) => {
  return request(
    `ticket_actions/${id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const createScheduleEmail = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `email_schedules`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email_schedule: params })
      },
      successAction,
      failureAction,
      true,
      false
    )
  )
}

export const deleteScheduleEmail = (id, successAction, failureAction) => {
  return request(
    `email_schedules/${id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchScheduleEmails = (successAction, failureAction) => {
  return request('email_schedules', {}, successAction, failureAction, true)
}

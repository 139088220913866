import * as types from 'actions/actionTypes'

import initialState from './initialState'

const Ticket = 'Ticket'
const Project = 'Project'
const HROpsPolicies = 'HROperations'
const Dnar = 'Dnar'
const Claim = 'Claim'
const Inventory = 'Inventory'

export const setting = (state = initialState.settings, action) => {
  let companyFeaturedPageIndex,
    companyFeaturedPages,
    reviewSettingIndex,
    reviewSettings,
    announcementIndex,
    slackAnnouncements,
    inviteSettings,
    inviteSettingIndex

  switch (action.type) {
    case types.SET_SETTINGS_TAB:
      return { ...state, tab: action.payload.tab }

    case types.SET_EDIT_POLICY_ID:
      return { ...state, editPolicyId: action.payload }

    case types.FETCH_POLICIES_SUCCESS:
      if (action.payload[0].model === Ticket) {
        return { ...state, ticketPolicies: action.payload }
      } else if (action.payload[0].model === Project) {
        return { ...state, projectPolicies: action.payload }
      } else if (action.payload[0].model === HROpsPolicies) {
        return { ...state, HROpsPolicies: action.payload }
      } else if (action.payload[0].model === Dnar) {
        return { ...state, dnarsPolicies: action.payload }
      } else if (action.payload[0].model === Claim) {
        return { ...state, claimsPolicies: action.payload }
      } else if (action.payload[0].model === Inventory) {
        return { ...state, inventoryPolicies: action.payload }
      }
      return

    case types.FETCH_POLICIES_FAILURE:
      return state

    case types.UPDATE_POLICY_SUCCESS:
      if (action.payload[0].model === 'Ticket') {
        return { ...state, ticketPolicies: action.payload }
      } else if (action.payload[0].model === 'Project') {
        return { ...state, projectPolicies: action.payload }
      } else if (action.payload[0].model === 'HROperations') {
        return { ...state, HROpsPolicies: action.payload }
      } else if (action.payload[0].model === 'Claim') {
        return { ...state, claimsPolicies: action.payload }
      } else if (action.payload[0].model === 'Dnar') {
        return { ...state, dnarsPolicies: action.payload }
      } else if (action.payload[0].model === 'Inventory') {
        return { ...state, inventoryPolicies: action.payload }
      }
      return state

    case types.UPDATE_POLICY_FAILURE:
      return state

    case types.REMOVE_POLICIES:
      return { ...state, policies: [] }

    case types.FETCH_REVIEW_SETTINGS_SUCCESS:
      return { ...state, reviewSettings: action.payload, loader: false }

    case types.FETCH_REVIEW_SETTINGS_FAILURE:
      return state

    case types.SHOW_REVIEW_SETTING_LOADER:
      return { ...state, loader: true }

    case types.FETCH_REVIEW_SETTING_SUCCESS:
      return { ...state, reviewSetting: action.payload, loader: false }

    case types.FETCH_REVIEW_SETTING_FAILURE:
      return state

    case types.CREATE_REVIEW_SETTING_SUCCESS:
      return { ...state, reviewSettings: [...state.reviewSettings, action.payload] }

    case types.CREATE_REVIEW_SETTING_FAILURE:
      return state

    case types.DELETE_REVIEW_SETTING_SUCCESS:
      if (action.payload) {
        reviewSettingIndex = state.reviewSettings.findIndex((setting) => setting.id === action.payload.id)
      }

      reviewSettings = [...state.reviewSettings]
      reviewSettings.splice(reviewSettingIndex, 1)

      return { ...state, reviewSettings: [...reviewSettings] }

    case types.DELETE_REVIEW_SETTING_FAILURE:
      return state

    case types.UPDATE_REVIEW_SETTING_SUCCESS:
      reviewSettingIndex = state.reviewSettings.findIndex((setting) => setting.id === action.payload.id)

      reviewSettings = [...state.reviewSettings]
      reviewSettings[reviewSettingIndex] = action.payload

      return { ...state, reviewSettings: [...reviewSettings] }

    case types.UPDATE_REVIEW_SETTING_FAILURE:
      return state

    case types.FETCH_ANNOUNCEMENT_SETTINGS_SUCCESS:
      return { ...state, announcemetSettings: action.payload }

    case types.FETCH_ANNOUNCEMENT_SETTINGS_FAILURE:
      return state

    case types.FETCH_ANNOUNCEMENT_SETTING_SUCCESS:
      return { ...state, announcemetSetting: action.payload }

    case types.FETCH_ANNOUNCEMENT_FAILURE_FAILURE:
      return state

    case types.DELETE_ANNOUNCEMENT_SETTING_SUCCESS:
      announcementIndex = state.announcemetSettings.findIndex((announcement) => announcement.id === action.payload.id)
      slackAnnouncements = [...state.announcemetSettings]
      slackAnnouncements.splice(announcementIndex, 1)

      return { ...state, announcemetSettings: [...slackAnnouncements] }

    case types.DELETE_ANNOUNCEMENT_SETTING_FAILURE:
      return state

    case types.UPDATE_ANNOUNCEMENT_SETTING_SUCCESS:
      announcementIndex = state.announcemetSettings.findIndex((announcement) => announcement.id === action.payload.id)

      slackAnnouncements = [...state.announcemetSettings]
      slackAnnouncements[announcementIndex] = action.payload

      return { ...state, announcemetSettings: [...slackAnnouncements] }

    case types.UPDATE_ANNOUNCEMENT_SETTING_FAILURE:
      return state

    case types.FETCH_ANNOUNCEMENT_CHANNELS_SUCCESS:
      return { ...state, slackChannels: action.payload }

    case types.FETCH_ANNOUNCEMENT_CHANNELS_FAILURE:
      return state

    case types.FETCH_INVITE_SETTINGS_SUCCESS:
      return { ...state, inviteSettings: action.payload }

    case types.FETCH_INVITE_SETTINGS_FAILURE:
      return state

    case types.CREATE_INVITE_SETTING_SUCCESS:
      return { ...state, inviteSettings: [...state.inviteSettings, action.payload] }

    case types.CREATE_INVITE_SETTING_FAILURE:
      return state

    case types.UPDATE_INVITE_SETTING_SUCCESS:
      inviteSettingIndex = state.inviteSettings.findIndex((setting) => setting.id === action.payload.id)

      inviteSettings = [...state.inviteSettings]
      inviteSettings[inviteSettingIndex] = action.payload

      return { ...state, inviteSettings: [...inviteSettings] }

    case types.UPDATE_INVITE_SETTING_FAILURE:
      return state

    case types.FETCH_COMPANY_FEATURED_PAGES_SUCCESS:
      return { ...state, companyFeaturedPages: action.payload, loader: false }

    case types.FETCH_COMPANY_FEATURED_PAGE_SUCCESS:
      return { ...state, companyFeaturedPage: action.payload }

    case types.CREATE_COMPANY_FEATURED_PAGE_SUCCESS:
      return { ...state, companyFeaturedPages: [...state.companyFeaturedPages, action.payload] }

    case types.UPDATE_COMPANY_FEATURED_PAGE_SUCCESS: {
      companyFeaturedPageIndex = state.companyFeaturedPages.findIndex((page) => page.id === action.payload.id)
      companyFeaturedPages = [...state.companyFeaturedPages]
      companyFeaturedPages[companyFeaturedPageIndex] = action.payload
      if (action.payload.flag === 'sort') {
        companyFeaturedPages.sort((a, b) => a.position - b.position)
      }
      return { ...state, companyFeaturedPages: [...companyFeaturedPages] }
    }

    case types.DELETE_COMPANY_FEATURED_PAGE_SUCCESS:
      return {
        ...state,
        companyFeaturedPages: state.companyFeaturedPages.filter((page) => page.id !== action.payload.id)
      }

    default:
      return state
  }
}

import moment from 'moment'

import * as types from 'actions/actionTypes'
import { errorsAlert } from 'helpers/applicationHelper'
import { DATE_FORMAT_ISO } from 'helpers/constants'

import initialState from './initialState'

export const events = (state = initialState.events, action) => {
  let totalevents = []
  let dateDifferences = 0
  switch (action.type) {
    case types.FETCH_EVENTS_SUCCESS:
      action.payload.events.filter((currentEvent) => {
        if (currentEvent.start === currentEvent.end) {
          totalevents.push(currentEvent)
        } else {
          dateDifferences = moment(currentEvent.end, DATE_FORMAT_ISO).diff(
            moment(currentEvent.start, DATE_FORMAT_ISO),
            'days'
          )
          Array(dateDifferences + 1)
            .fill()
            .forEach((_, index) =>
              totalevents.push({
                title: {
                  avatar: currentEvent.title.avatar,
                  id: currentEvent.title.id,
                  name: currentEvent.title.name
                },
                description: currentEvent.description,
                start: `${moment(moment(currentEvent.start, DATE_FORMAT_ISO).add(index, 'days')).format(
                  DATE_FORMAT_ISO
                )}`,
                end: `${moment(moment(currentEvent.start, DATE_FORMAT_ISO).add(index, 'days')).format(
                  DATE_FORMAT_ISO
                )}`,
                eventable_type: currentEvent.eventable_type
              })
            )
        }
      })
      action.payload.events = totalevents
      return { ...state, userEvents: action.payload }

    case types.FETCH_EVENTS_FAILURE:
      errorsAlert(action.errors, 'Failed to fetch events')
      return state

    default:
      return state
  }
}

import { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { ButtonStack, Grid, Tooltip, Typography } from 'maple-storybook'
import { InfoRounded } from 'maple-storybook/assets'
import { RCDropzone } from 'maple-storybook/dropzone'
import { change, Field, getFormValues, reduxForm } from 'redux-form'

import {
  fetchDepartmentsListFailure,
  fetchDepartmentsListSuccess,
  fetchSelectDepartmentsFailure,
  fetchSelectDepartmentsSuccess
} from 'actions/department'
import { disableFormSubmission } from 'actions/formSubmission'
import { HIDE_MODAL } from 'actions/modal'
import { createTicketFailure, createTicketSuccess } from 'actions/ticket'
import { fetchDepartmentsList, fetchSelectDepartments } from 'api/department'
import { createTicket } from 'api/ticket'
import { Button, CustomInputWrapper, CustomMentionEditor, ItemGrid, SelectWrapper } from 'components'
import { checkArrayEmpty, formatLongText, webView } from 'helpers/applicationHelper'
import { collectionIntoSelectOptions, formatSelectValue } from 'helpers/settingsHelper'
import { ticketPriorities } from 'helpers/ticketsHelper'

import { required, validateDescription } from './validateTicketAssignedForm'
import { validateTicketForm as validate } from './validateTicketForm'

import './styles/ticketForm.scss'

class TicketForm extends Component {
  state = {
    description: '',
    department_id: '',
    assignee_user: false,
    department_moderator_id: '',
    categoryDesc: ''
  }

  componentDidMount() {
    if (checkArrayEmpty(this.props.selectDepartments)) this.props.fetchSelectDepartments()
    if (checkArrayEmpty(this.props.departments)) this.props.fetchDepartmentsList()
  }

  handleSelectCategory = (category_id) => {
    const { categories, departments } = this.props
    categories.forEach((category) => {
      if (category.id === category_id) {
        this.props.setFieldValue('department', category.department_id)
        this.setState({ categoryDesc: category.description })
        const department = departments.find((dept) => dept.id === category.department_id)
        const userLocationModerators = category.is_moderator_location
        if (department) {
          this.setState({
            department_id: category.department_id,
            department_moderator_id: department?.moderator_user_id,
            assignee_user: false
          })
        } else if (userLocationModerators) {
          this.setState({
            assignee_user: false
          })
        } else {
          this.setState({
            assignee_user: true,
            department_id: '',
            department_moderator_id: ''
          })
        }
      }
    })
  }

  handleCreateTicketSubmit = (values) => {
    this.props.disableFormSubmission()
    let newValues = cloneDeep(values)

    if (values.department_id && values.department_id.length > 0)
      newValues.department_id = values.department_id.map((department) => department.value)
    if (values.title_id && values.title_id.length > 0) newValues.title_id = values.title_id.map((title) => title.value)
    if (values.user_ids && values.user_ids.length > 0) newValues.user_ids = values.user_ids
    else delete newValues.user_ids

    if (newValues.image) {
      let files = []
      newValues.image.map((file) => files.push({ file: file }))

      newValues.attachments_attributes = files
      delete newValues.image
    }

    this.props.createTicket(newValues)
  }

  render() {
    const { closeModal, handleSubmit, selectDepartments, submission, formValues } = this.props
    const { department_id, department_moderator_id, assignee_user, categoryDesc } = this.state
    const WEB_VIEW_TEXT_SIZE = 55
    const MOBILE_VIEW_TEXT_SIZE = 38

    const renderTitle = (categoryDesc) =>
      webView() && categoryDesc.length > WEB_VIEW_TEXT_SIZE
        ? categoryDesc
        : !webView() && categoryDesc.length > MOBILE_VIEW_TEXT_SIZE
          ? categoryDesc
          : ''

    const renderTextSize = webView() ? WEB_VIEW_TEXT_SIZE : MOBILE_VIEW_TEXT_SIZE

    const ticketCategory = this.props.formValues?.ticket_category_id
    const categoryNotPresent = ticketCategory === null || ticketCategory === 'no_value'
    return (
      <form
        onSubmit={handleSubmit((values) => this.handleCreateTicketSubmit(values))}
        className="ticket-form"
        id="tickets-ticketForm">
        <Grid container>
          <ItemGrid xs={12} sm={12} md={6} id={'tickets-ticketForm-category'}>
            <Grid item xs={12} sm={12} md={12} className="d-flex flex-column select-category">
              <Field
                heading="Category*"
                name="ticket_category_id"
                id="ticket-category"
                validate={[required]}
                autoComplete="category"
                labelText="Category"
                options={collectionIntoSelectOptions(this.props.categories, 'id', 'name')}
                component={SelectWrapper}
                placeholder="Select Category"
                onChange={this.handleSelectCategory}
                helpText={
                  formValues?.ticket_category_id &&
                  categoryDesc && (
                    <span>
                      <InfoRounded fontSize="10" className="ml-half dark-gray" />
                      <Tooltip placement="bottom" title={renderTitle(categoryDesc)}>
                        <span className="ml-half grey-color">{formatLongText(categoryDesc, renderTextSize)}</span>
                      </Tooltip>
                    </span>
                  )
                }
                className="w-100"
              />
            </Grid>
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={6} itemGridClasses="priority-grid" id={'tickets-ticketForm-priority'}>
            <Field
              heading="Priority*"
              name="priority"
              id="ticket-priority"
              validate={[required]}
              autoComplete="priority"
              labelText="Priority"
              options={formatSelectValue(ticketPriorities)}
              component={SelectWrapper}
              placeholder="Select Priority"
            />
          </ItemGrid>
        </Grid>
        <Grid container>
          {department_id && (
            <ItemGrid xs={12} sm={12} md={6} id={'tickets-ticketForm-department_id'}>
              <Field
                heading="Department Name"
                name="department_id"
                id="ticket-department-id"
                autoComplete="department_id"
                labelText="Department"
                disabled="true"
                selectedValue={department_id}
                options={selectDepartments}
                component={SelectWrapper}
              />
            </ItemGrid>
          )}
          {department_moderator_id && (
            <ItemGrid xs={12} sm={12} md={6} id="tickets-ticketForm-department-head">
              <Field
                heading="Department Moderator"
                labelText="Department Moderator"
                name="department_moderator_id"
                placeholder="Department Moderator"
                disabled="true"
                selectedValue={department_moderator_id}
                userSearchable
                component={SelectWrapper}
              />
            </ItemGrid>
          )}
        </Grid>
        {assignee_user && (
          <ItemGrid xs={12} sm={12} md={6} id="tickets-ticketForm-ticketAssignee">
            <Field heading="To*" name="assignee_id" placeholder="To" component={SelectWrapper} userSearchable />
          </ItemGrid>
        )}
        <ItemGrid xs={12} sm={12} md={12} id={'tickets.ticketForm.ticketCc'}>
          <Field
            heading="CC (Optional)"
            name="user_ids"
            id="ticket-participants"
            placeholder="CC (Optional)"
            userSearchable
            isMulti={true}
            component={SelectWrapper}
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={12} md={12}>
          <Field
            heading="Title*"
            name="title"
            id={'tickets-ticketForm-title'}
            validate={[required]}
            autoComplete="title"
            type="text"
            component={CustomInputWrapper}
          />
        </ItemGrid>

        <ItemGrid xs={12} sm={12} md={12}>
          <Typography variant="text-sm" color="gray-700">
            Description*
          </Typography>
          <Field
            name="description"
            validate={[validateDescription]}
            id="ticket-description"
            placeholder="Description*"
            component={CustomMentionEditor}
          />
        </ItemGrid>

        <ItemGrid itemGridClasses="file-uploader-div">
          <Field id="ticket-form-attachments" name="image" type="input" component={RCDropzone} filesLimit={3} />
        </ItemGrid>

        <ButtonStack>
          <Button variant="secondaryGray" type="button" onClick={closeModal}>
            Cancel
          </Button>
          <Button data-cy="send-ticket-btn" type="submit" disabled={categoryNotPresent || submission}>
            Send Ticket
          </Button>
        </ButtonStack>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.tickets.categories.filter((category) => !category.disabled),
    formValues: getFormValues('ticket_form')(state),
    selectDepartments: state.departments.selectDepartments,
    submission: state.formSubmission.submission,
    departments: state.departments.departmentsList,
    locations: state.locations.locations
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change: (name, value) => dispatch(change(name, value)),
    closeModal: (_) => {
      dispatch(HIDE_MODAL)
    },
    disableFormSubmission: (_) => {
      dispatch(disableFormSubmission)
    },
    createTicket: (params) => {
      dispatch(createTicket(params, createTicketSuccess, createTicketFailure))
    },
    fetchSelectDepartments: (_) => {
      dispatch(fetchSelectDepartments(fetchSelectDepartmentsSuccess, fetchSelectDepartmentsFailure))
    },
    fetchDepartmentsList: (_) => {
      dispatch(fetchDepartmentsList(fetchDepartmentsListSuccess, fetchDepartmentsListFailure))
    },
    setFieldValue: (field, value) => {
      dispatch(change('ticket_form', field, value))
    }
  }
}

TicketForm = reduxForm({
  form: 'ticket_form',
  validate
})(TicketForm)

export default connect(mapStateToProps, mapDispatchToProps)(TicketForm)

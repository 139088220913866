import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import App from 'containers/App/App'

import { verifyCredentials } from './actions/auth/authConfig'
import configureStore from './store/configureStore'

import 'assets/css/material-dashboard-react.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

import './libs'

const store = configureStore()

verifyCredentials(store)
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <RouterProvider router={createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />))} />
  </Provider>
)

import { totalItems } from 'helpers/applicationHelper'

import {
  BULK_UPDATE_REVIEW_STATUS_SUCCESS,
  CREATE_REVIEW_FEEDBACK_FAILURE,
  CREATE_REVIEW_FEEDBACK_SUCCESS,
  CREATE_USER_PROBATION_STATUS_FAILURE,
  CREATE_USER_PROBATION_STATUS_SUCCESS,
  FETCH_ALL_ANNOUNCEMENTS_FAILURE,
  FETCH_ALL_ANNOUNCEMENTS_SUCCESS,
  FETCH_DELEGATED_USER_FAILURE,
  FETCH_DELEGATED_USER_SUCCESS,
  FETCH_PROBATION_CSV_ATTRIBUTES_FAILURE,
  FETCH_PROBATION_CSV_ATTRIBUTES_SUCCESS,
  FETCH_PROBATION_CSV_DATA_FAILURE,
  FETCH_PROBATION_CSV_DATA_SUCCESS,
  FETCH_PROBATION_USER_FAILURE,
  FETCH_PROBATION_USER_SUCCESS,
  FETCH_PROBATION_USER_TASK_UPDATION_SUCCESS,
  FETCH_PROBATION_USERS_FAILURE,
  FETCH_PROBATION_USERS_SUCCESS,
  FETCH_REVIEW_CSV_ATTRIBUTES_FAILURE,
  FETCH_REVIEW_CSV_ATTRIBUTES_SUCCESS,
  FETCH_REVIEW_CSV_DATA_FAILURE,
  FETCH_REVIEW_CSV_DATA_SUCCESS,
  FETCH_REVIEW_EVALUATION_FAILURE,
  FETCH_REVIEW_EVALUATION_SUCCESS,
  FETCH_REVIEW_USER_FAILURE,
  FETCH_REVIEW_USER_SUCCESS,
  FETCH_REVIEW_USER_TASK_UPDATION_SUCCESS,
  FETCH_REVIEW_USERS_FAILURE,
  FETCH_REVIEW_USERS_SUCCESS,
  FETCH_USER_REVIEW_TIMELINE_SUCCESS,
  SHOW_PROBATION_USER_LOADER,
  UPDATE_ANNOUNCEMENT_STATUS_FAILURE,
  UPDATE_ANNOUNCEMENT_STATUS_SUCCESS,
  UPDATE_ANNOUNCEMENTS_STATUS_FAILURE,
  UPDATE_ANNOUNCEMENTS_STATUS_SUCCESS,
  UPDATE_DELEGATED_USER_FAILURE,
  UPDATE_DELEGATED_USER_SUCCESS,
  UPDATE_REVIEW_FEEDBACK_FAILURE,
  UPDATE_REVIEW_FEEDBACK_SUCCESS,
  UPDATE_REVIEW_STATUS_FAILURE,
  UPDATE_REVIEW_STATUS_SUCCESS,
  UPDATE_USER_PROBATION_STATUS_FAILURE,
  UPDATE_USER_PROBATION_STATUS_SUCCESS
} from '../actions/actionTypes'

import initialState from './initialState'

export const haDashboard = (state = initialState.haDashboard, action) => {
  let users, index, userDetail, reviewEvaluationDetail, promotionAnnouncements
  switch (action.type) {
    case FETCH_PROBATION_USERS_SUCCESS:
      return { ...state, probationUsers: action.payload, loader: false }

    case FETCH_PROBATION_USER_TASK_UPDATION_SUCCESS: {
      const updatedUsers = state.probationUsers.users?.map((user) =>
        user.id === action.payload.id ? action.payload : user
      )

      return { ...state, probationUsers: { ...state.probationUsers, users: updatedUsers }, loader: false }
    }

    case FETCH_PROBATION_USERS_FAILURE:
      return state

    case SHOW_PROBATION_USER_LOADER:
      return { ...state, loader: true }

    case FETCH_PROBATION_USER_SUCCESS:
      return { ...state, probationUserDetail: action.payload, loader: false }

    case FETCH_PROBATION_CSV_DATA_SUCCESS:
      return { ...state, csvData: action.payload }

    case FETCH_PROBATION_CSV_DATA_FAILURE:
      return state

    case FETCH_PROBATION_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributes: action.payload }

    case FETCH_PROBATION_CSV_ATTRIBUTES_FAILURE:
      return state
    case FETCH_PROBATION_USER_FAILURE:
      return state

    case FETCH_REVIEW_CSV_DATA_SUCCESS:
      return { ...state, csvData: action.payload }

    case FETCH_REVIEW_CSV_DATA_FAILURE:
      return state

    case FETCH_REVIEW_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributes: action.payload }

    case FETCH_REVIEW_CSV_ATTRIBUTES_FAILURE:
      return state

    case FETCH_REVIEW_USER_SUCCESS:
      return { ...state, reviewUserDetail: action.payload, loader: false }

    case FETCH_REVIEW_USER_FAILURE:
      return state

    case CREATE_USER_PROBATION_STATUS_SUCCESS: {
      users = [...state.probationUsers.users]
      userDetail = { ...state.probationUserDetail }
      index = action.payload.users
        ? state.probationUsers.users.findIndex(
            (probationUser) => probationUser.user.id === action.payload.users.user.id
          )
        : -1
      const statusIndex =
        JSON.stringify(userDetail) !== JSON.stringify({})
          ? userDetail.evaluation_details.findIndex(
              (evaluation_details) => evaluation_details.id === action.payload.prevStatus
            )
          : -1
      if (userDetail && userDetail.evaluation_details && action.payload.probation_status) {
        userDetail.probation_status = action.payload.probation_status.status
        userDetail.evaluation_details[0].status = action.payload.probation_status.status
      }
      if (
        index !== -1 &&
        action.payload.prevStatus !== action.payload.users.status &&
        !(
          (action.payload.prevStatus === 'extended' && action.payload.users.status === 'auto_clear') ||
          (action.payload.prevStatus === 'auto_clear' && action.payload.users.status === 'extended')
        )
      ) {
        users.splice(index, 1)
        sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
      }

      if (index !== -1 && action.payload.prevStatus === action.payload.users.status) {
        users[index].evaluation_date = action.payload.users.evaluation_date
      }
      if (
        index !== -1 &&
        (action.payload.prevStatus === 'auto_clear' || action.payload.prevStatus === 'extended') &&
        ['auto_clear', 'extended'].includes(action.payload.users.status)
      ) {
        users[index].status = action.payload.users.status
      }
      if (statusIndex !== -1) {
        userDetail.evaluation_details[statusIndex] = action.payload.probation_status
      }
      return {
        ...state,
        probationUsers: { users: [...users], probation_stats: action.payload.probation_stats },
        probationUserDetail: userDetail
      }
    }
    case CREATE_USER_PROBATION_STATUS_FAILURE:
      return state

    case UPDATE_USER_PROBATION_STATUS_SUCCESS:
      users = { ...state.probationUsers }
      userDetail = { ...state.probationUserDetail }
      index = state.probationUsers.users.findIndex((probationUser) => probationUser.id === action.payload.id)

      if (userDetail && action.payload.reviewer) {
        userDetail.reviewer = action.payload.reviewer
        userDetail.reviewer_email = action.payload.reviewer_email
      }
      if (index !== -1) {
        users.users[index].reviewer = action.payload.reviewer
        users.users[index].effective_date = action.payload.effective_date
      }
      index = state.probationUserDetail.evaluation_details?.findIndex(
        (probationUserStatus) => probationUserStatus.id === action.payload.id
      )

      if (index !== undefined && index !== -1) {
        userDetail.evaluation_details[index] = action.payload
        userDetail.effective_date = action.payload.effective_date
        userDetail.extended_days = action.payload.extended_days
        userDetail.next_evaluation_date = action.payload.next_evaluation_date
        userDetail.probation_due_date = action.payload.probation_due_date
      }

      return {
        ...state,
        probationUsers: { users: [...users.users], probation_stats: users.probation_stats },
        probationUserDetail: userDetail
      }

    case UPDATE_USER_PROBATION_STATUS_FAILURE:
      return state

    case FETCH_DELEGATED_USER_SUCCESS:
      return { ...state, selectedDelegatedUser: action.payload }

    case FETCH_DELEGATED_USER_FAILURE:
      return state

    case UPDATE_DELEGATED_USER_SUCCESS:
      return { ...state, selectedDelegatedUser: action.payload }

    case UPDATE_DELEGATED_USER_FAILURE:
      return state

    case FETCH_REVIEW_USERS_SUCCESS:
      return { ...state, reviewUsers: action.payload, loader: false }

    case FETCH_REVIEW_USER_TASK_UPDATION_SUCCESS: {
      const updatedReviews = state.reviewUsers.reviews?.map((user) =>
        user.id === action.payload.id ? action.payload : user
      )
      return { ...state, reviewUsers: { ...state.reviewUsers, reviews: updatedReviews }, loader: false }
    }

    case FETCH_REVIEW_USERS_FAILURE:
      return state

    case UPDATE_REVIEW_STATUS_SUCCESS:
      users = [...state.reviewUsers.reviews]
      userDetail = { ...state.reviewUserDetail }
      if (action.payload.review) {
        userDetail = action.payload.review
      } else if (!action.payload.review) {
        userDetail.reviewer = action.payload.reviewer
      }

      index = state.reviewUsers.reviews.findIndex((reviewUser) => reviewUser.id === action.payload.review?.id)
      if (index !== -1) {
        users.splice(index, 1)
        sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
      }
      index = state.reviewUsers.reviews.findIndex((reviewUser) => reviewUser.id === action.payload.id)
      if (index !== -1) users[index] = action.payload

      return {
        ...state,
        reviewUsers: {
          reviews: [...users],
          review_stats: action.payload.review_stats ? action.payload.review_stats : state.reviewUsers.review_stats
        },
        reviewUserDetail: userDetail
      }

    case UPDATE_REVIEW_STATUS_FAILURE:
      return state

    case CREATE_REVIEW_FEEDBACK_SUCCESS:
      users = [...state.reviewUsers.reviews]
      index = state.reviewUsers.reviews.findIndex(
        (reviewUser) => reviewUser.id === action.payload.review_feedback.review_id
      )
      if (index !== -1 && action.payload.review_feedback.status === 'completed') {
        users.splice(index, 1)
      }
      if (index !== -1 && action.payload.review_feedback.status === 'in_progress') {
        users[index].reviewer = action.payload.review_feedback.active_reviewer
      }
      return {
        ...state,
        reviewUsers: {
          reviews: [...users],
          review_stats: action.payload.review_stats ? action.payload.review_stats : state.reviewUsers.review_stats
        }
      }

    case CREATE_REVIEW_FEEDBACK_FAILURE:
      return state

    case UPDATE_REVIEW_FEEDBACK_SUCCESS: {
      reviewEvaluationDetail = [...state.reviewUserDetail.evaluation_details]
      const statusIndex = reviewEvaluationDetail.findIndex(
        (evaluation_details) => evaluation_details.id === action.payload.prevId
      )

      reviewEvaluationDetail[statusIndex] = action.payload.review_feedback

      return { ...state, reviewUserDetail: { ...state.reviewUserDetail, evaluation_details: reviewEvaluationDetail } }
    }
    case UPDATE_REVIEW_FEEDBACK_FAILURE:
      return state

    case FETCH_REVIEW_EVALUATION_SUCCESS:
      return { ...state, reviewUserDetail: { ...state.reviewUserDetail, evaluation_details: action.payload } }

    case FETCH_REVIEW_EVALUATION_FAILURE:
      return state

    case FETCH_ALL_ANNOUNCEMENTS_SUCCESS:
      promotionAnnouncements = action.payload
      return { ...state, announcements: promotionAnnouncements.reverse(), loader: false }

    case FETCH_ALL_ANNOUNCEMENTS_FAILURE:
      return state

    case UPDATE_ANNOUNCEMENTS_STATUS_SUCCESS:
      return { ...state, announcements: [] }

    case UPDATE_ANNOUNCEMENTS_STATUS_FAILURE:
      return state

    case UPDATE_ANNOUNCEMENT_STATUS_SUCCESS:
      promotionAnnouncements = [action.payload]
      return { ...state, announcements: promotionAnnouncements.reverse() }

    case UPDATE_ANNOUNCEMENT_STATUS_FAILURE:
      return state

    case BULK_UPDATE_REVIEW_STATUS_SUCCESS:
      users = state.reviewUsers

      return {
        ...state,
        reviewUsers: {
          reviews: users.reviews.filter((review) => !action.payload.reviewIds.includes(review.id)),
          review_stats: users.review_stats
        }
      }
    case FETCH_USER_REVIEW_TIMELINE_SUCCESS:
      return { ...state, reviewsTimeline: action.payload }

    default:
      return state
  }
}

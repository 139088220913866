import { toastr } from 'react-redux-toastr'

import { errorsAlert } from 'helpers/applicationHelper'

import * as types from './actionTypes'

// Attendance

export const createAttendanceSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.MARK_ATTENDANCE_SUCCESS,
    payload
  })

  toastr.success('Attendance Marked!')
}

export const fetchAttendanceSuccess = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ATTENDANCE_SUCCESS,
    payload
  })
}

export const createAttendanceFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.MARK_ATTENDANCE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to mark attendance!')
}

export const fetchAttendanceFailure = (payload) => (dispatch) => {
  dispatch({
    type: types.FETCH_ATTENDANCE_FAILURE,
    payload
  })

  errorsAlert(payload, 'Failed to fetch attendance!')
}

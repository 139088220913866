import * as types from 'actions/actionTypes'
import initialState from 'reducers/initialState'

export const question = (state = initialState, action) => {
  let questionIndex, questions

  switch (action.type) {
    case types.CREATE_QUESTION_SUCCESS:
      return { ...state, questions: [...state.questions, action.payload] }

    case types.CREATE_QUESTION_FAILURE:
      return state

    case types.FETCH_QUESTION_SUCCESS:
      return { ...state, questions: action.payload }

    case types.FETCH_QUESTION_FAILURE:
      return state

    case types.UPDATE_QUESTION_SUCCESS:
      questionIndex = state.questions.findIndex((ques) => ques.id === action.payload.id)

      questions = state.questions
      questions[questionIndex] = action.payload
      questions.sort((a, b) => a.position - b.position)

      return { ...state, questions: [...questions] }

    case types.UPDATE_QUESTION_FAILURE:
      return state

    case types.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.filter((ques) => ques.id !== action.payload.id)
      }

    case types.DELETE_QUESTION_FAILURE:
      return state

    case types.FETCH_QUESTION_BY_ID_SUCCESS:
      return { ...state, questionById: action.payload }

    case types.FETCH_QUESTION_BY_ID_FAILURE:
      return state

    case types.SHOW_QUESTION_LOADER:
      return { ...state, questionsLoader: true }

    case types.HIDE_QUESTION_LOADER:
      return { ...state, questionsLoader: false }

    default:
      return state
  }
}

import { useState } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { Drawer, Grid, Menu, MenuItem } from 'maple-storybook'
import {
  Close,
  DraftsOutlined,
  EmailOutlined,
  ExpandLess,
  ExpandMore,
  InsertDriveFileOutlined,
  NotificationsOffOutlined,
  NotificationsOutlined,
  SettingsOutlined
} from 'maple-storybook/assets'

import {
  fetchNotificationsFailure,
  fetchNotificationswithFilesSuccess,
  fetchUnreadOnlySuccess,
  loadMoreNotificationsFailure,
  loadMoreNotificationsSuccess,
  markAllAsReadFailure,
  markAllAsReadSuccess,
  readNotificationFailure,
  readNotificationSuccess,
  setCallInProgress,
  setNotificationPageNumber
} from 'actions/notifications'
import { fetchNotifications, readNotification } from 'api/notifications'
import { Loader } from 'components'
import { checkArrayPresence } from 'helpers/applicationHelper'
import { NOTIFICATOINS_PAGE_SIZE } from 'helpers/constants'

import NotificationRow from './NotificationRow'

import './styles.scss'

const NotificationPanel = ({
  pageNumber,
  drawerOpen,
  preferences,
  notifications,
  setDrawerOpen,
  callInProgress,
  setCallInProgress,
  fetchNotifications,
  unreadNotifications,
  readAllNotifications,
  readSingleNotification,
  notificationsWithFiles,
  fetchUnreadOnlySuccess,
  setNotificationPageNumber
}) => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const [showUnread, setShowUnread] = useState(false)
  const [showFilesOnly, setShowFilesOnly] = useState(false)

  const displayedNotifications = showUnread
    ? unreadNotifications
    : showFilesOnly
      ? notificationsWithFiles
      : notifications

  const renderList = () => {
    return (
      <Grid container className="notificaitons-row-container flex-column">
        {checkArrayPresence(displayedNotifications.notifications)
          ? displayedNotifications.notifications.map((notification) => (
              <NotificationRow
                key={notification.id}
                readSingleNotification={readSingleNotification}
                notification={notification}
                handleDrawerClose={setDrawerOpen}
              />
            ))
          : callInProgress === '' && (
              <Grid item lg={12} className="no-notification-container flex-column align-items-center">
                <NotificationsOffOutlined className="no-notification-icon" />
                <span className="no-notification-text">No Notification Available</span>
              </Grid>
            )}
        {['loadMoreNotifications', 'showUnReadNotifications'].includes(callInProgress) && (
          <Grid item className="mt-1 mb-1">
            <Loader />
          </Grid>
        )}
      </Grid>
    )
  }

  const toggleDrawer = () => setDrawerOpen(!drawerOpen)

  const markAllRead = () =>
    notifications.unread_count > 0
      ? readAllNotifications((payload) => {
          if (payload) showUnreadFilterClick(false)
          return markAllAsReadSuccess(payload)
        })
      : toastr.info('No Unread Notifications Available')

  const haveMoreNotifications =
    Math.floor(notifications.notifications.length / 30) === notifications.notifications.length / 30

  const displayLoadMoreText =
    !showUnread &&
    !showFilesOnly &&
    haveMoreNotifications &&
    checkArrayPresence(notifications.notifications) &&
    !['loadMoreNotifications', 'showUnReadNotifications'].includes(callInProgress)

  const handleMenuClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleNotificationSettingsClick = () => {
    navigate('/settings/notifications', { initialValues: preferences })
    toggleDrawer()
  }

  const showUnreadFilterClick = (unRead) => {
    setShowFilesOnly(false)
    setShowUnread(unRead)
    if (unRead) {
      let newNotifications = cloneDeep(showFilesOnly ? notificationsWithFiles : notifications)
      newNotifications.notifications = newNotifications.notifications.filter((item) => !item.read)
      fetchUnreadOnlySuccess(newNotifications)
    }
  }

  const loadNotifications = () => {
    setCallInProgress()
    fetchNotifications(pageNumber + 1, false)
    setNotificationPageNumber(pageNumber + 1)
  }

  const showWithFilesOnly = () => {
    setShowUnread(false)
    setShowFilesOnly(!showFilesOnly)
    setCallInProgress()
    fetchNotifications(1, true)
  }

  return (
    <Drawer className="notificaitons-panel" anchor={'right'} open={drawerOpen} onClose={toggleDrawer}>
      <Grid container className="panel-topbar d-flex align-items-center justify-space-between">
        <Grid item lg={3} className="d-flex cursor-pointer gp-20" onClick={(e) => handleMenuClick(e)}>
          <strong className="main-title">{showUnread ? 'Unread' : 'Notifications'}</strong>
          {anchorEl ? <ExpandLess /> : <ExpandMore />}
          <Menu
            id="ha-dashboard-bulk-approval"
            anchorEl={anchorEl}
            open={anchorEl}
            className="mt-2"
            onClose={(e) => handleMenuClose(e)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <MenuItem className="gp-10" onClick={() => showUnreadFilterClick(false)}>
              <NotificationsOutlined className="notification-icon" />
              All Notifications
            </MenuItem>
            <MenuItem className="gp-10" onClick={() => showUnreadFilterClick(true)}>
              <EmailOutlined className="notification-icon" />
              Show Unread
            </MenuItem>
            <MenuItem className="gp-10" onClick={markAllRead}>
              <DraftsOutlined className="notification-icon" />
              Mark all as Read
            </MenuItem>
          </Menu>
        </Grid>

        <Grid item className="d-flex align-items-center gp-20">
          <div
            className={`notification-filters gp-10 d-flex align-items-center cursor-pointer ${showFilesOnly ? 'selected-filter' : ''}`}
            onClick={showWithFilesOnly}>
            <InsertDriveFileOutlined />
            <span className="filter-text">Show with files</span>
          </div>
          <SettingsOutlined
            className="preference-settings-icon cursor-pointer"
            onClick={handleNotificationSettingsClick}
          />
          <Close className="preference-settings-icon cursor-pointer" onClick={toggleDrawer} />
        </Grid>
      </Grid>
      {renderList()}
      {displayLoadMoreText && (
        <span className="load-more-notifications" onClick={loadNotifications}>
          Load More Notifications
        </span>
      )}
    </Drawer>
  )
}

const mapStateToProps = (state) => {
  return {
    pageNumber: state.notifications.pageNumber,
    preferences: state.userSettings.preferences,
    callInProgress: state.notifications.callInProgress,
    notifications: state.notifications.allNotifications,
    unreadNotifications: state.notifications.unreadNotifications,
    notificationsWithFiles: state.notifications.notificationsWithFiles
  }
}

const mapDispatchToPrpos = (dispatch) => {
  return {
    setNotificationPageNumber: (pageNumber) => {
      dispatch(setNotificationPageNumber(pageNumber))
    },
    setCallInProgress: () => {
      dispatch(setCallInProgress('loadMoreNotifications'))
    },
    fetchUnreadOnlySuccess: (filteredNotifications) => {
      dispatch(fetchUnreadOnlySuccess(filteredNotifications))
    },
    readAllNotifications: (successCallBack = markAllAsReadSuccess) => {
      dispatch(readNotification({ mark_all_read: true }, successCallBack, markAllAsReadFailure))
    },
    readSingleNotification: (queryParams) => {
      dispatch(readNotification(queryParams, readNotificationSuccess, readNotificationFailure))
    },
    fetchNotifications: (pageNumber, status) => {
      dispatch(
        fetchNotifications(
          pageNumber,
          NOTIFICATOINS_PAGE_SIZE,
          false,
          status,
          status ? fetchNotificationswithFilesSuccess : loadMoreNotificationsSuccess,
          status ? fetchNotificationsFailure : loadMoreNotificationsFailure
        )
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToPrpos)(NotificationPanel)

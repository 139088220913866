import { SET_BREADCRUMB_SUCCESS } from 'actions/actionTypes'

import initialState from './initialState'

export const breadCrumbReducer = (state = initialState.breadCrumbs, action) => {
  switch (action.type) {
    case SET_BREADCRUMB_SUCCESS:
      return { ...state, breadCrumbs: action.payload }

    default:
      return state
  }
}
